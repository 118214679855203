import classNames from 'classnames';
import { useContext, useEffect, useState } from 'react';
import { NavLink, Outlet, useOutletContext, useParams } from 'react-router-dom';

import Badge from '../components/Badge';
import Loader from '../components/loader';

import { EventEmitterInstance } from '../instances';
import GlobalContext from '../context/GlobalContext';
import APIRequestUtil from '../utils/APIRequestUtil';
import ProfileTemplateDto from './ProfileTemplateDto';
import ProfileTemplateUtil from './ProfileTemplateUtil';
import UserInformationDto from '../dtos/UserInformationDto';
import UserInformationUtil from '../utils/UserInformationUtil';
import ProfileTemplateCategory from './ProfileTemplateCategory';
import EventNamesConstants from '../constants/EventNamesConstants';

import {
	PortalLayoutContext,
	ProfilePickerLayoutContext
} from '../types/context';

const ProfileTemplatePickerLayout: React.FunctionComponent = () => {
	const { portal_id } = useParams() as Record<string, string>;

	const { currentUser } = useContext(GlobalContext);
	const context = useOutletContext<PortalLayoutContext>();

	const [isLoading, setIsLoading] = useState(true);

	const [userInformationDto, setUserInformationDto] =
		useState<UserInformationDto>(new UserInformationDto());
	const [currentProfileTemplateDto, setProfileTemplateDto] =
		useState<ProfileTemplateDto>(new ProfileTemplateDto());

	useEffect(() => {
		setIsLoading(true);

		UserInformationUtil.getUserInformationWithPortal({
			portal_id,
			user_id: currentUser.getId()
		})
			.then((userInformationDto) => {
				setUserInformationDto(userInformationDto);

				ProfileTemplateUtil.getProfileTemplateWithPortal({
					portal_id,
					profile_template_id: userInformationDto.getTemplate().getId()
				})
					.then((profileTemplateDto) => {
						console.log("Im heree")
						setProfileTemplateDto(profileTemplateDto);
						setIsLoading(false);
					})
					.catch((err) => APIRequestUtil.handleRequestFailure(err));
			})
			.catch((err) => APIRequestUtil.handleRequestFailure(err));

		const subscription = EventEmitterInstance.addListener(
			EventNamesConstants.ProfileTemplates.Data.Activate,
			setProfileTemplateDto
		);

		return () => {
			subscription.remove();
		};
	}, [currentUser, portal_id]);

	return (
		<>
			{isLoading ? (
				<div className='container--center'>
					<Loader />
				</div>
			) : (
				<div className='container__profile'>
					<div className='sidebar--secondary'>
						<NavLink
							to={`/portals/${portal_id}/profile/${ProfileTemplateCategory.Portfolio.getValue()}`}
							className={({ isActive }) =>
								classNames(
									'navlink navlink--secondary',
									isActive && 'navlink--secondary--active'
								)
							}>
							<span className='title__navlink--secondary mr-auto'>
								{ProfileTemplateCategory.Portfolio.getMappedValue()}
							</span>
							{currentProfileTemplateDto.getCategory() ===
								ProfileTemplateCategory.Portfolio && (
								<Badge variant='green' text='Active' />
							)}
						</NavLink>

						<NavLink
							to={`/portals/${portal_id}/profile/${ProfileTemplateCategory.LeadGen.getValue()}`}
							className={({ isActive }) =>
								classNames(
									'navlink navlink--secondary',
									isActive && 'navlink--secondary--active'
								)
							}>
							<span className='title__navlink--secondary mr-auto'>
								{ProfileTemplateCategory.LeadGen.getMappedValue()}
							</span>
							{currentProfileTemplateDto.getCategory() ===
								ProfileTemplateCategory.LeadGen && (
								<Badge variant='green' text='Active' />
							)}
						</NavLink>
					</div>
					<Outlet
						context={
							{
								...context,
								userInformationPojo: userInformationDto,
								currentProfileTemplatePojo: currentProfileTemplateDto
							} satisfies ProfilePickerLayoutContext
						}
					/>
				</div>
			)}
		</>
	);
};

export default ProfileTemplatePickerLayout;
