import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import Loader from '../../components/loader';
import ContainerCenter from '../../components/ContainerCenter';
import ProfileTemplateContainer from './ProfileTemplateContainer';
import ViewProfilePicker from '../../components/ViewProfilePicker';

import APIRequestUtil from '../../utils/APIRequestUtil';
import RequestMethodEnum from '../../enums/RequestMethodEnum';
import RequestHandler from '../../handlers/RequestHandler';
import ProfileTemplateDto from '../../profile-templates/ProfileTemplateDto';
import ProfileTemplateCategory from '../../profile-templates/ProfileTemplateCategory';
import JsonToProfileTemplateDtoMapper from '../../mappers/JsonToProfileTemplatePojoMapper';

const ProfilePortfolioPage: React.FunctionComponent = () => {
	const { portal_id } = useParams();

	const [isLoading, setIsLoading] = useState(true);
	const [profileTemplatePojo, setProfileTemplatePojo] = useState(
		new ProfileTemplateDto()
	);

	useEffect(() => {
		setIsLoading(true);
		const apiRequestHandler = new RequestHandler();

		apiRequestHandler.setMethod(RequestMethodEnum.Get);
		apiRequestHandler.setUrl(`/api/portals/${portal_id}/profile-templates`);
		apiRequestHandler.addParam(
			'category',
			ProfileTemplateCategory.Portfolio.getValue()
		);
		apiRequestHandler.addParam('page', 1);
		apiRequestHandler.addParam('perPage', 1);

		apiRequestHandler
			.execute()
			.then((response) => {
				setProfileTemplatePojo(
					JsonToProfileTemplateDtoMapper.map(
						(
							(response.getData() as Record<string, unknown>).data as Array<
								Record<string, unknown>
							>
						)[0]
					)
				);
				setIsLoading(false);
			})
			.catch(APIRequestUtil.handleRequestFailure);
	}, [portal_id]);

	return (
		<ViewProfilePicker>
			{isLoading ? (
				<ContainerCenter>
					<Loader />
				</ContainerCenter>
			) : (
				<ProfileTemplateContainer profileTemplateDto={profileTemplatePojo} />
			)}
		</ViewProfilePicker>
	);
};

export default ProfilePortfolioPage;
