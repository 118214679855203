import CircularLoaderIcon from '../icons/CircularLoaderIcon';

const LoaderModal: React.FC = () => {
	return (
		<div className='container--center'>
			<CircularLoaderIcon className='loader--modal' />
		</div>
	);
};

export default LoaderModal;
