import TouchpointStyles from './touchpoint.module.css';

import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useRef, useState } from 'react';

import Box from '../components/Box';
import Loader from '../components/loader';
import Typography from '../components/Typography';
import ArrowLeftIcon from '../icons/ArrowLeftIcon';
import TouchpointPreview from './TouchpointPreview';
import TouchpointDetails from './TouchpointDetails';
import ContainerCenter from '../components/ContainerCenter';

import TouchpointDto from './TouchpointDto';
import TouchpointUtil from './TouchpointUtil';
import { EventEmitterInstance } from '../instances';
import APIRequestUtil from '../utils/APIRequestUtil';
import TouchpointTemplateNode from './TouchpointTemplateNode';
import TouchpointTemplateNodes from './TouchpointTemplateNodes';
import EventNamesConstants from '../constants/EventNamesConstants';

export default function TouchpointView() {
	const navigate = useRef(useNavigate());

	const { portal_id, touchpoint_id } = useParams() as Record<string, string>;

	const [isLoading, setIsLoading] = useState(true);
	const [touchpointDto, setTouchpointDto] = useState<TouchpointDto>(
		new TouchpointDto()
	);

	const touchpointNodes = useRef<TouchpointTemplateNodes>(
		new TouchpointTemplateNodes()
	);

	useEffect(() => {
		const handleRequestFailure = (err: Error) => {
			APIRequestUtil.handleRequestFailure(err);
			navigate.current(`/portals/${portal_id}/touchpoints`);
		};

		const updateTouchpointNodesEventListener = (
			touchpointNode: TouchpointTemplateNode
		) => {
			touchpointNodes.current.setFront(
				TouchpointUtil.updateTouchpointNode(
					Array.from(touchpointNodes.current.getFront()),
					touchpointNode
				)
			);

			touchpointNodes.current.setBack(
				TouchpointUtil.updateTouchpointNode(
					Array.from(touchpointNodes.current.getBack()),
					touchpointNode
				)
			);
		};

		setIsLoading(true);

		TouchpointUtil.getTouchpointWithPortal({
			portal_id,
			touchpoint_id
		})
			.then((touchpointDto) => {
				setTouchpointDto(touchpointDto);

				TouchpointUtil.getTouchpointNodesWithPortal({
					touchpoint_id: touchpointDto.getId(),
					portal_id: touchpointDto.getPortal().getId()
				})
					.then((touchpointServerNodes) => {
						touchpointNodes.current = touchpointServerNodes;
						setIsLoading(false);
					})
					.catch((err) => handleRequestFailure(err));
			})
			.catch((err) => handleRequestFailure(err as any));

		const subscription = EventEmitterInstance.addListener(
			EventNamesConstants.TouchpointTemplateNode.Data.Update,
			updateTouchpointNodesEventListener
		);

		return () => {
			subscription.remove();
		};
	}, [portal_id, touchpoint_id]);

	const goToTouchpoints = useCallback(() => {
		navigate.current(`/portals/${portal_id}/touchpoints`);
	}, [portal_id]);

	const updateTouchpointDto = useCallback((touchpointDto: TouchpointDto) => {
		setTouchpointDto(touchpointDto);
	}, []);

	if (isLoading) {
		return (
			<ContainerCenter>
				<Loader />
			</ContainerCenter>
		);
	} else {
		return (
			<Box className={TouchpointStyles['view']}>
				<Box className={TouchpointStyles['view-header']}>
					<ArrowLeftIcon
						className={TouchpointStyles['view-header__back-btn']}
						onClick={goToTouchpoints}
					/>
					<Typography
						variant='h5'
						text={touchpointDto.getUser().getFirst_name() + "'s Touchpoint"}
						className={TouchpointStyles['view-header__title']}
					/>
				</Box>
				<Box className={TouchpointStyles['view-body']}>
					<TouchpointPreview touchpointNodes={touchpointNodes.current} />
					<TouchpointDetails
						touchpointDto={touchpointDto}
						updateTouchpointDto={updateTouchpointDto}
						touchpointNodes={touchpointNodes.current}
					/>
				</Box>
			</Box>
		);
	}
}
