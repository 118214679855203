import { useMemo } from 'react';
import TouchpointTemplateNode from './TouchpointTemplateNode';
import TouchpointTemplateQRNodeProperties from './TouchpointTemplateQRNodeProperties';

type TouchpointTemplateContainerNodeProps = {
	touchpointTemplateNode: TouchpointTemplateNode;
};

export default function TouchpointTemplateQRNode({
	touchpointTemplateNode
}: TouchpointTemplateContainerNodeProps) {
	const properties = useMemo(() => {
		const properties = new TouchpointTemplateQRNodeProperties();
		properties.loadFromJSON(
			touchpointTemplateNode.getProperties() as Record<string, string>
		);
		return properties;
	}, [touchpointTemplateNode]);
	return (
		<img
			alt='asset'
			draggable='false'
			style={touchpointTemplateNode.getStyles().toJSON()}
			src={`/server/v1/files/qr-code?value=${encodeURIComponent(
				properties.getValue()
			)}`}
		/>
	);
}
