import Dto from '../abstract-classes/Dto';
import FormTypeEnum from '../enums/FormTypeEnum';
import ForeignKey from '../datatypes/ForeignKey';
import LeadStorageTypeEnum from '../enums/LeadStorageTypeEnum';
import ExternalAppModule from '../external-app/ExternalAppModule';
import PartialPortalUserPojo from '../pojos/PartialPortalUserPojo';
import LeadStorageLocationEnum from '../enums/LeadStorageLocationEnum';

export default class FormDto extends Dto {
	private name: string;

	private type: FormTypeEnum;
	private storage_type: LeadStorageTypeEnum;
	private storage_location: LeadStorageLocationEnum;

	private portal: ForeignKey;
	private creator: PartialPortalUserPojo;
	private external_app_module: ExternalAppModule;

	constructor() {
		super();

		this.name = '';
		this.external_app_module = new ExternalAppModule();

		this.type = FormTypeEnum.Profile;
		this.storage_type = LeadStorageTypeEnum.Application;
		this.storage_location = LeadStorageLocationEnum.Application;

		this.portal = new ForeignKey();
		this.creator = new PartialPortalUserPojo();
	}

	public getName(): string {
		return this.name;
	}

	public setName(name: string): void {
		this.name = name;
	}

	public getType(): FormTypeEnum {
		return this.type;
	}

	public setType(type: FormTypeEnum): void {
		this.type = type;
	}

	public getStorage_type(): LeadStorageTypeEnum {
		return this.storage_type;
	}

	public setStorage_type(storage_type: LeadStorageTypeEnum): void {
		this.storage_type = storage_type;
	}

	public getStorage_location(): LeadStorageLocationEnum {
		return this.storage_location;
	}

	public setStorage_location(storage_location: LeadStorageLocationEnum): void {
		this.storage_location = storage_location;
	}

	public getPortal(): ForeignKey {
		return this.portal;
	}

	public setPortal(portal: ForeignKey): void {
		this.portal = portal;
	}

	public getCreator(): PartialPortalUserPojo {
		return this.creator;
	}

	public setCreator(creator: PartialPortalUserPojo): void {
		this.creator = creator;
	}

	public getExternal_app_module(): ExternalAppModule {
		return this.external_app_module;
	}

	public setExternal_app_module(
		external_app_module: ExternalAppModule
	): void {
		this.external_app_module = external_app_module;
	}

	public loadFromJSON(json: Record<string, unknown>): void {
		this.id = json.id as string;
		this.name = json.name as string;

		this.type = FormTypeEnum.getValue(json.type as string);
		this.storage_type = LeadStorageTypeEnum.getValue(
			json.storage_type as string
		);
		this.storage_location = LeadStorageLocationEnum.getValue(
			json.storage_location as string
		);

		this.created_time.setValue(json.created_time as string);
		this.portal.loadFromJSON(json.portal as Record<string, unknown>);
		this.creator.loadFromJson(json.creator as Record<string, unknown>);
		this.external_app_module.loadFromJson(
			json.external_app_module as Record<string, unknown>
		);
	}
}
