import Styles from './table.module.css';

import QRCodeIcon from '../icons/QRCodeIcon';

import { TableCellAction as TableCellActionProps } from '../types/components';

const TableCellActionQRCode: React.FunctionComponent<
	TableCellActionProps
> = ({ onClick }) => {
	return <QRCodeIcon onClick={onClick} className={Styles['table-cell-icon']} />;
};

export default TableCellActionQRCode;
