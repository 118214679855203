import Pojo from '../abstract-classes/Pojo';
import PartialUserPojo from './PartialUserPojo';
import PortalTypeEnum from '../enums/PortalTypeEnum';

export default class PortalPojo extends Pojo {
	private name: string;
	private is_default: boolean;

	private type: PortalTypeEnum;

	private creator: PartialUserPojo;

	constructor() {
		super();

		this.id = '';
		this.name = '';
		this.is_default = false;

		this.creator = new PartialUserPojo();

		this.type = PortalTypeEnum.Personal;
	}

	public getName(): string {
		return this.name;
	}

	public setName(name: string): void {
		this.name = name;
	}

	public isIs_default(): boolean {
		return this.is_default;
	}

	public setIs_default(is_default: boolean): void {
		this.is_default = is_default;
	}

	public getType(): PortalTypeEnum {
		return this.type;
	}

	public setType(type: PortalTypeEnum): void {
		this.type = type;
	}

	public getCreator(): PartialUserPojo {
		return this.creator;
	}

	public setCreator(creator: PartialUserPojo): void {
		this.creator = creator;
	}

	public loadFromJson(json: Record<string, unknown>): void {
		this.id = json.id as string;
		this.name = json.name as string;
		this.is_default = json.is_default as boolean;

		this.type = PortalTypeEnum.getValue(json.type as string);

		this.creator.loadFromJson(json.creator as any);
		this.created_time.setValue(json.created_time as string);
	}
}
