import classNames from 'classnames';

import { FormFieldGroup as FormFieldGroupProps } from '../types/components';

const FormFieldGroup: React.FunctionComponent<FormFieldGroupProps> = ({
	children,
	className,
	variant = 'single'
}) => {
	return (
		<div className={classNames('form__field-group--' + variant, className)}>
			{children}
		</div>
	);
};

export default FormFieldGroup;
