

export default class ResponsePojo {
	private headers: Record<string, string>;
	private data: unknown ;

	constructor() {
		this.data = {};
		this.headers = {};
	}

	public getData(): unknown{
		return this.data;
	}

	public setData(data: unknown): void {
		this.data = data;
	}

	public getHeaders(): Record<string, string> {
		return this.headers;
	}

	public setHeaders(headers: Record<string, string>): void {
		this.headers = headers;
	}
}
