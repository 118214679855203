import Ajv from 'ajv';
import AjvErrors from 'ajv-errors';
import AjvFormats from 'ajv-formats';
import AjvKeywords from 'ajv-keywords';

const instance = AjvFormats(
	AjvErrors(
		AjvKeywords(
			new Ajv({
				allErrors: true,
				coerceTypes: true
			})
		)
	)
);
export default class ValidatorUtil {
	public static validateEmail(email_id: string): boolean {
		const schema = instance.compile<string>({
			type: 'string',
			format: 'email'
		});
		return schema(email_id);
	}
}
