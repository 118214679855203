export default class TouchpointAuditStatus {
	public static readonly Pending = new TouchpointAuditStatus(
		'pending',
		'Pending'
	);
	public static readonly Evaluating = new TouchpointAuditStatus(
		'evaluating',
		'Evaluating'
	);
	public static readonly Approved = new TouchpointAuditStatus(
		'approved',
		'Approved'
	);

	private value: string;
	private mappedValue: string;

	constructor(value: string, mappedValue: string) {
		this.value = value;
		this.mappedValue = mappedValue;
	}

	public getValue(): string {
		return this.value;
	}

	public getMappedValue(): string {
		return this.mappedValue;
	}

	public toJSON(): string {
		return this.mappedValue;
	}

	public toString(): string {
		return this.mappedValue;
	}

	public static getValue(value: string): TouchpointAuditStatus {
		if (value === this.Approved.value || value === this.Approved.mappedValue) {
			return this.Approved;
		} else if (
			value === this.Evaluating.value ||
			value === this.Evaluating.mappedValue
		) {
			return this.Evaluating;
		} else {
			return this.Pending;
		}
	}

	public static getAllValues(): Array<TouchpointAuditStatus> {
		return [this.Pending, this.Evaluating, this.Approved];
	}
}
