import { useEffect, useRef, useState } from 'react';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';

import Loader from '../components/loader';
import ContainerCenter from '../components/ContainerCenter';

import APIRequestUtil from '../utils/APIRequestUtil';
import ProfileTemplateDto from './ProfileTemplateDto';
import TouchpointDto from '../touchpoint/TouchpointDto';
import ProfileTemplateUtil from './ProfileTemplateUtil';
import TouchpointUtil from '../touchpoint/TouchpointUtil';
import UserInformationDto from '../dtos/UserInformationDto';
import TouchpointStatus from '../touchpoint/TouchpointStatus';
import UserInformationUtil from '../utils/UserInformationUtil';

import logo from '../images/logo.png';
import { ProfileTemplateUserLayoutContext } from './types';

export default function ProfileTemplateUserLayout() {
	const { touchpoint_mapped_value } = useParams() as Record<string, string>;

	const navigate = useRef(useNavigate());

	const [isLoading, setIsLoading] = useState(true);
	const [touchpointDto, setTouchpointDto] = useState(new TouchpointDto());
	const [profileTemplateDto, setProfileTemplateDto] = useState(
		new ProfileTemplateDto()
	);
	const [userInformationDto, setUserInformationDto] =
		useState<UserInformationDto>(new UserInformationDto());

	useEffect(() => {
		setIsLoading(true);
		TouchpointUtil.getTouchpointByMappedValue(touchpoint_mapped_value)
			.then((touchpointDto) => {
				if (
					touchpointDto.getStatus().getValue() ===
					TouchpointStatus.Inactive.getValue()
				) {
					navigate.current(
						`/portals/${touchpointDto
							.getPortal()
							.getId()}/touchpoints/${touchpointDto.getId()}`
					);

					return;
				}

				setTouchpointDto(touchpointDto);

				const portal_id = touchpointDto.getPortal().getId();

				UserInformationUtil.getUserInformationWithPortal({
					portal_id,
					user_id: touchpointDto.getUser().getId()
				})
					.then((userInformationDto) => {
						setUserInformationDto(userInformationDto);

						ProfileTemplateUtil.getProfileTemplateWithPortal({
							portal_id: touchpointDto.getPortal().getId(),
							profile_template_id: userInformationDto.getTemplate().getId()
						})
							.then((profileTemplateDto) => {
								setProfileTemplateDto(profileTemplateDto);
								setIsLoading(false);
							})
							.catch((err) => APIRequestUtil.handleRequestFailure(err));
					})
					.catch((err) => APIRequestUtil.handleRequestFailure(err));
			})
			.catch((err) => APIRequestUtil.handleRequestFailure(err));
	}, [touchpoint_mapped_value]);

	return (
		<>
			<div className='header'>
				<div className='mr-auto'>
					<Link to='/portals' className='container__title'>
						<img src={logo} alt='zoho_cards' className='icon__app' />
						<h6 className='title__app'>Zoho Touchpoint</h6>
					</Link>
				</div>
			</div>
			<div className='container__main'>
				{isLoading ? (
					<ContainerCenter>
						<Loader />
					</ContainerCenter>
				) : (
					<Outlet
						context={
							{
								touchpointDto,
								userInformationDto,
								profileTemplateDto
							} satisfies ProfileTemplateUserLayoutContext
						}
					/>
				)}
			</div>
		</>
	);
}
