import Pojo from '../abstract-classes/Pojo';
import ForeignKey from '../datatypes/ForeignKey';
import PartialPortalUserPojo from './PartialPortalUserPojo';
import LeadStorageLocationEnum from '../enums/LeadStorageLocationEnum';


export default class ConnectedAppPojo extends Pojo {
	private portal: ForeignKey;
	private creator: PartialPortalUserPojo;

	private app: LeadStorageLocationEnum;

	constructor() {
		super();

		this.app = LeadStorageLocationEnum.Application;

		this.portal = new ForeignKey();
		this.creator = new PartialPortalUserPojo();
	}

	public getPortal(): ForeignKey {
		return this.portal;
	}

	public setPortal(portal: ForeignKey): void {
		this.portal = portal;
	}

	public getCreator(): PartialPortalUserPojo {
		return this.creator;
	}

	public setCreator(creator: PartialPortalUserPojo): void {
		this.creator = creator;
	}

	public getApp(): LeadStorageLocationEnum {
		return this.app;
	}

	public setApp(app: LeadStorageLocationEnum): void {
		this.app = app;
	}

	public loadFromJson = (json: Record<string, unknown>): void => {
		this.id = json.id as string;

		this.app = LeadStorageLocationEnum.getValue(json.app as string);

		this.portal.loadFromJSON(json.portal as any);
		this.creator.loadFromJson(json.creator as any);
		this.created_time.setValue(json.created_time as string);
	};
}
