import TouchpointTemplateNode from './TouchpointTemplateNode';
import TouchpointTemplateQRNode from './TouchpointTemplateQRNode';
import TouchpointTemplateTextNode from './TouchpointTemplateTextNode';
import TouchpointTemplateNodeType from './TouchpointTemplateNodeType';
import TouchpointTemplateImageNode from './TouchpointTemplateImageNode';
import TouchpointTemplateInputNode from './TouchpointTemplateInputNode';
import TouchpointTemplateContainerNode from './TouchpointTemplateContainerNode';

type TouchpointBuilderProps = {
	nodes: Array<TouchpointTemplateNode>;
};

export default function TouchpointBuilder({ nodes }: TouchpointBuilderProps) {
	return (
		<>
			{nodes.map((node) => {
				if (
					node.getType().getValue() ===
					TouchpointTemplateNodeType.CONTAINER.getValue()
				) {
					return (
						<TouchpointTemplateContainerNode
							touchpointTemplateNode={node}
							key={'preview-' + node.getId()}>
							{node.getNodes().length ? (
								<TouchpointBuilder nodes={node.getNodes()} />
							) : null}
						</TouchpointTemplateContainerNode>
					);
				} else if (
					node.getType().getValue() ===
					TouchpointTemplateNodeType.IMAGE.getValue()
				) {
					return (
						<TouchpointTemplateImageNode
							touchpointTemplateNode={node}
							key={'preview-' + node.getId()}
						/>
					);
				} else if (
					node.getType().getValue() ===
					TouchpointTemplateNodeType.INPUT.getValue()
				) {
					return (
						<TouchpointTemplateInputNode
							touchpointTemplateNode={node}
							key={'preview-' + node.getId()}
						/>
					);
				} else if (
					node.getType().getValue() ===
					TouchpointTemplateNodeType.TEXT.getValue()
				) {
					return (
						<TouchpointTemplateTextNode
							touchpointTemplateNode={node}
							key={'preview-' + node.getId()}
						/>
					);
				} else if (
					node.getType().getValue() === TouchpointTemplateNodeType.QR.getValue()
				) {
					return (
						<TouchpointTemplateQRNode
							touchpointTemplateNode={node}
							key={'preview-' + node.getId()}
						/>
					);
				} else {
					return null;
				}
			})}
		</>
	);
}
