import Styles from './table.module.css';
import CopyIcon from '../icons/CopyIcon';

import { TableCellAction as TableCellActionProps } from '../types/components';

const TableCellActionCopy: React.FunctionComponent<TableCellActionProps> = ({
	onClick
}) => {
	return <CopyIcon onClick={onClick} className={Styles['table-cell-icon']} />;
};

export default TableCellActionCopy;
