import Ajv from 'ajv';
import AjvErrors from 'ajv-errors';
import AjvFormats from 'ajv-formats';
import AjvKeywords from 'ajv-keywords';
import {
	AjvConstants,
	ErrorMessageConstants,
	ExternalAppConstants,
	FormConstants
} from '../constants';

const AjvInstance = AjvFormats(
	AjvErrors(
		AjvKeywords(
			new Ajv({
				allErrors: true,
				coerceTypes: true
			})
		)
	)
);

AjvInstance.addFormat('identifier', /^[A-Za-z0-9_ ]*$/);
AjvInstance.addSchema(
	{
		type: 'string',
		minLength: 1,
		format: 'identifier',
		errorMessage: {
			minLength: ErrorMessageConstants.Ajv.Common.NonEmpty,
			format: ErrorMessageConstants.Ajv.String.Format.Identifier
		}
	},
	AjvConstants.Schema.String.Common.Identifier
);
AjvInstance.addSchema(
	{
		type: 'string',
		enum: FormConstants.getAllValueOfStorageType(),
		errorMessage: {
			enum: ErrorMessageConstants.Ajv.String.Enum
		}
	},
	AjvConstants.Schema.String.Enum.StorageType
);
AjvInstance.addSchema(
	{
		type: 'string',
		enum: ExternalAppConstants.getAllApps().map((item) => item.getValue()),
		errorMessage: {
			enum: ErrorMessageConstants.Ajv.String.Enum
		}
	},
	AjvConstants.Schema.String.Enum.StorageLocation
);

AjvInstance.addSchema(
	{
		type: 'string',
		format: 'email'
	},
	AjvConstants.Schema.String.Format.Email
);

export default AjvInstance;
