import { useMemo } from 'react';

import MailIcon from '../icons/MailIcon';
import TwitterIcon from '../icons/TwitterIcon';
import LinkedInIcon from '../icons/LinkedInIcon';
import FacebookIcon from '../icons/FacebookIcon';
import WhatsappIcon from '../icons/WhatsappIcon';
import ProfileTemplateNode from './ProfileTemplateNode';
import UserInformationDto from '../dtos/UserInformationDto';
import ProfileTemplateIconNodeNameEnum from '../enums/ProfileTemplateIconNodeNameEnum';

export type ProfileTemplateIconNodeProps = {
	disabled?: boolean;
	userInformationPojo: UserInformationDto;
	profileTemplateNode: ProfileTemplateNode;
};

const ProfileTemplateIconNode: React.FC<ProfileTemplateIconNodeProps> = ({
	profileTemplateNode
}) => {
	const name = useMemo<string>(
		() => profileTemplateNode.getProperties().getName() as string,
		[profileTemplateNode]
	);

	const style = useMemo<React.CSSProperties>(() => {
		return {
			width: profileTemplateNode.getStyles().getWidth(),
			height: profileTemplateNode.getStyles().getHeight()
		};
	}, [profileTemplateNode]);

	if (name === ProfileTemplateIconNodeNameEnum.Mail) {
		return <MailIcon style={style} />;
	} else if (name === ProfileTemplateIconNodeNameEnum.LinkedIn) {
		return <LinkedInIcon style={style} />;
	} else if (name === ProfileTemplateIconNodeNameEnum.Facebook) {
		return <FacebookIcon style={style} />;
	} else if (name === ProfileTemplateIconNodeNameEnum.Twitter) {
		return <TwitterIcon style={style} />;
	} else if (name === ProfileTemplateIconNodeNameEnum.Whatsapp) {
		return <WhatsappIcon style={style} />;
	} else {
		return null;
	}
};

export default ProfileTemplateIconNode;
