import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import ProfileTemplateDto from '../../profile-templates/ProfileTemplateDto';
import ProfileTemplateBuilderContainer from './ProfileTemplateBuilderContainer';
import ProfileTemplateConfigurationContainer from './ProfileTemplateConfigurationContainer';

const PortalAdminProfileTemplateContainer: React.FC<{
	profileTemplateDto: ProfileTemplateDto | null;
}> = ({ profileTemplateDto }) => {
	if (profileTemplateDto) {
		return (
			<DndProvider backend={HTML5Backend}>
				<ProfileTemplateBuilderContainer
					profileTemplateDto={profileTemplateDto}
				/>
			</DndProvider>
		);
	} else {
		return <ProfileTemplateConfigurationContainer />;
	}
};

export default PortalAdminProfileTemplateContainer;
