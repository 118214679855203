import { JSONSchemaType } from 'ajv';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

import Form from '../../components/Form';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import ModalBody from '../../components/ModalBody';
import ModalTitle from '../../components/ModalTitle';
import ModalFooter from '../../components/ModalFooter';
import ModalHeader from '../../components/ModalHeader';
import FormFieldError from '../../components/FormFieldError';
import FormFieldGroup from '../../components/FormFieldGroup';
import FormFieldLabel from '../../components/FormFieldLabel';
import ModalCloseIcon from '../../components/ModalCloseIcon';
import FormFieldTextareaInput from '../../components/FormFieldTextareaInput';

import useForm from '../../hooks/useForm';
import useModal from '../../hooks/useModal';
import ToastUtil from '../../utils/ToastUtil';
import CommonUtil from '../../utils/CommonUtil';
import AjvInstance from '../../instances/AjvInstance';
import { EventEmitterInstance } from '../../instances';
import APIRequestUtil from '../../utils/APIRequestUtil';
import RequestMethodEnum from '../../enums/RequestMethodEnum';
import RequestHandler from '../../handlers/RequestHandler';
import EventNamesConstants from '../../constants/EventNamesConstants';
import ErrorMessageConstants from '../../constants/ErrorMessageConstants';

import { InvitePortalUserForm } from '../../types/forms';

const initialValues: InvitePortalUserForm.FormValues = {
	emails: ''
};

const MappedErrors: Record<string, { name: string; displayMessage: string }> = {
	[CommonUtil.format(
		ErrorMessageConstants.Ajv.Common.InvalidValue,
		'emails',
		'array of email address'
	)]: {
		name: 'emails',
		displayMessage: 'Invalid value for email addresses'
	},
	[CommonUtil.format(ErrorMessageConstants.Ajv.Common.NonEmpty, 'emails')]: {
		name: 'emails',
		displayMessage: 'Email addresses cannot be empty.'
	}
};

const FormValueSchema: JSONSchemaType<{ emails: Array<string> }> = {
	type: 'object',
	properties: {
		emails: {
			type: 'array',
			minItems: 1,
			maxItems: 100,
			items: {
				type: 'string',
				format: 'email',
				errorMessage: {
					format: CommonUtil.format(
						ErrorMessageConstants.Ajv.Common.InvalidValue,
						'emails',
						'array of email address'
					)
				}
			},
			errorMessage: {
				minItems: CommonUtil.format(
					ErrorMessageConstants.Ajv.Common.NonEmpty,
					'emails'
				),
				maxItems: CommonUtil.format(
					ErrorMessageConstants.Ajv.Array.MaxItems,
					'emails',
					100
				)
			}
		}
	},
	required: ['emails'],
	errorMessage: {
		required: {
			emails: CommonUtil.format(
				ErrorMessageConstants.Ajv.Common.NonEmpty,
				'emails'
			)
		}
	}
};

const InviteUserModal: React.FC = () => {
	const { portal_id } = useParams();

	const { open, openModal, closeModal } = useModal();

	const { values, errors, setValue, setError, clearErrors, setMultipleValues } =
		useForm<InvitePortalUserForm.FormValues>({
			initialValues
		});

	const [isProcessing, setIsProcessing] = useState(false);

	useEffect(() => {
		const openModalEventListener = () => {
			openModal();
			setMultipleValues(initialValues);
		};

		const subscription = EventEmitterInstance.addListener(
			EventNamesConstants.PortalUsers.Modal.Invite,
			openModalEventListener
		);

		return () => {
			subscription.remove();
		};
	}, [openModal, setMultipleValues]);

	const onTextInputChange = useCallback(
		(key: string, value: string) => {
			setValue(key, value);
			setError(key, '');
		},
		[setValue, setError]
	);

	const inviteUsers = useCallback(() => {
		clearErrors();

		const emails = values.emails
			? values.emails.split(',').map((item) => item.trim())
			: [];
		const compliedSchema = AjvInstance.compile(FormValueSchema);

		const isInvalidData = compliedSchema({
			emails
		});

		if (
			!isInvalidData &&
			compliedSchema.errors &&
			compliedSchema.errors.length
		) {
			const { message } = compliedSchema.errors[0];

			if (message) {
				const mappedError = MappedErrors[message];
				if (mappedError) {
					setError(mappedError.name, mappedError.displayMessage);
				}
				return;
			}
		}

		setIsProcessing(true);
		const apiRequestHandler = new RequestHandler();
		apiRequestHandler.setBody({
			emails
		});
		apiRequestHandler.setMethod(RequestMethodEnum.Post);
		apiRequestHandler.setUrl(`/api/portals/${portal_id}/invite`);
		apiRequestHandler
			.execute()
			.then(() => {
				ToastUtil.makeSuccessToast(
					'Invitations for users will be sent shortly.'
				);
				closeModal();
			})
			.catch((err) => {
				APIRequestUtil.getErrorMessage(err).then((message) => {
					const mappedMessage = MappedErrors[message];

					if (mappedMessage) {
						setError(mappedMessage.name, mappedMessage.displayMessage);
					} else {
						ToastUtil.makeFailureToast(message);
					}
				});
			})
			.finally(() => {
				setIsProcessing(false);
			});
	}, [values, setError, portal_id, closeModal, clearErrors]);

	return (
		<Modal position='top' show={open}>
			<ModalHeader>
				<ModalTitle title='Invite Users' />
				<ModalCloseIcon closeModal={closeModal} />
			</ModalHeader>
			<ModalBody>
				<Form>
					<FormFieldGroup>
						<FormFieldLabel label='Email Addresses' htmlFor='emails' />
						<FormFieldTextareaInput
							id='emails'
							name='emails'
							value={values.emails}
							error={errors.emails}
							onChange={onTextInputChange}
							placeholder='Enter the list of email addresses as comma seperated values.'
						/>
                        <FormFieldError error={errors.emails}/>
					</FormFieldGroup>
				</Form>
			</ModalBody>
			<ModalFooter>
				<Button variant='outlined' label='Cancel' onClick={closeModal} />
				<Button
					variant='primary'
					label='Invite'
					onClick={inviteUsers}
					isLoading={isProcessing}
				/>
			</ModalFooter>
		</Modal>
	);
};

export default InviteUserModal;
