import { nanoid } from 'nanoid';

import ZohoCrmIcon from '../../images/zoho-crm.png';

import LeadStorageLocationEnum from '../../enums/LeadStorageLocationEnum';

const ExternalApps = [
	{
		id: nanoid(),
		icon: ZohoCrmIcon,
		app: LeadStorageLocationEnum.ZohoCrm.getSelectOptionValue(),
	}
];

export default ExternalApps;
