import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

import Button from '../../components/Button';
import Loader from '../../components/loader';
import Typography from '../../components/Typography';
import ConfigureTemplateModal from './ConfigureTemplateModal';
import ContainerCenter from '../../components/ContainerCenter';
import CardProfileTemplateMessage from '../../components/CardProfileTemplateMessage';
import ContainerProfileCategoryBody from '../../components/ContainerProfileCategoryBody';
import ContainerProfileCategoryHeader from '../../components/ContainerProfileCategoryHeader';

import { EventEmitterInstance } from '../../instances';
import APIRequestUtil from '../../utils/APIRequestUtil';
import ExternalAppUtil from '../../utils/ExternalAppUtil';
import ConnectedAppUtil from '../../utils/ConnectedAppUtil';
import EventNamesConstants from '../../constants/EventNamesConstants';
import LeadStorageLocationEnum from '../../enums/LeadStorageLocationEnum';

const ConnectZohoCrmCard: React.FC = () => {
	const { portal_id } = useParams();

	const [isProcerssing, setIsProcessing] = useState(false);
	const connectZohoCrm = useCallback(() => {
		setIsProcessing(true);
		ExternalAppUtil.getInstance(
			portal_id as string,
			LeadStorageLocationEnum.ZohoCrm.getSelectOptionValue()
		)
			.connect(window.location.href)
			.then((url) => {
				window.location.href = url;
				setIsProcessing(false);
			})
			.catch(APIRequestUtil.handleRequestFailure);
	}, [portal_id]);

	return (
		<ContainerCenter>
			<CardProfileTemplateMessage>
				<Typography
					variant='h6'
					fontSize={15}
					fontWeight={500}
					textAlign='center'
					wordBreak='break-word'
					text='Zoho Crm is not connected to this portal yet. Connect Zoho Crm to configure this template.'
					marginBottom='10px'
				/>

				<Button
					size='sm'
					variant='primary'
					label='Connect Zoho Crm'
					onClick={connectZohoCrm}
					isLoading={isProcerssing}
					className='card--profile--lead-gen--template-creation__button'
				/>
			</CardProfileTemplateMessage>
		</ContainerCenter>
	);
};

const CreateTemplateContainer = () => {
	const openStartConfigurationModal = useCallback(() => {
		EventEmitterInstance.emit(
			EventNamesConstants.ProfileTemplates.Modal.Create
		);
	}, []);

	return (
		<>
			<ContainerCenter>
				<CardProfileTemplateMessage>
					<Typography
						variant='h6'
						fontSize={15}
						fontWeight={500}
						textAlign='center'
						wordBreak='break-word'
						text='Template is not configured yet. Start the configuration to enable this profile.'
						marginBottom='15px'
					/>

					<Button
						size='sm'
						variant='primary'
						label='Start Configuration'
						onClick={openStartConfigurationModal}
						className='card--profile--lead-gen--template-creation__button'
					/>
				</CardProfileTemplateMessage>
			</ContainerCenter>

			<ConfigureTemplateModal />
		</>
	);
};

const ProfileTemplateConfigurationContainer = () => {
	const { portal_id } = useParams() as Record<string, string>;

	const [isLoading, setIsLoading] = useState(true);
	const [isZohoCrmConnected, setIsZohoCrmConnected] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		setIsZohoCrmConnected(false);

		ConnectedAppUtil.getInstance(portal_id)
			.getPagedConnectedApps({
				page: 1,
				perPage: 1,
				name: LeadStorageLocationEnum.ZohoCrm.getSelectOptionValue()
			})
			.then((records) => {
				if (records.length) {
					setIsZohoCrmConnected(true);
				}
				setIsLoading(false);
			})
			.catch(APIRequestUtil.handleRequestFailure);
	}, [portal_id]);

	if (isLoading) {
		return (
			<ContainerCenter>
				<Loader />
			</ContainerCenter>
		);
	} else {
		return (
			<>
				<ContainerProfileCategoryHeader>
					<Typography
						variant='h6'
						fontSize={22}
						text='Lead Gen'
						fontWeight={600}
						marginRight='auto'
					/>
				</ContainerProfileCategoryHeader>
				<ContainerProfileCategoryBody>
					{isZohoCrmConnected ? (
						<CreateTemplateContainer />
					) : (
						<ConnectZohoCrmCard />
					)}
				</ContainerProfileCategoryBody>
			</>
		);
	}
};

export default ProfileTemplateConfigurationContainer;
