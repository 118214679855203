import classNames from 'classnames';

import Typography from './Typography';

import { ModalTitle as ModalTitleProps } from '../types/components';

const ModalTitle: React.FunctionComponent<ModalTitleProps> = ({
	title,
	children,
	className
}) => {
	return (
		<Typography
			variant='h6'
			className={classNames('modal__title', className)}
			text={title}/>
	);
};

export default ModalTitle;
