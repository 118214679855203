import TouchpointTemplateNode from './TouchpointTemplateNode';

type TouchpointTemplateContainerNodeProps = {
	children?: React.ReactNode;
	touchpointTemplateNode: TouchpointTemplateNode;
};

export default function TouchpointTemplateContainerNode({
	children,
	touchpointTemplateNode
}: TouchpointTemplateContainerNodeProps) {
	return (
		<div style={touchpointTemplateNode.getStyles().toJSON()}>{children}</div>
	);
}
