import Enum from '../base-classes/Enum';

export default class UserInformationStatusEnum extends Enum {
	public static readonly Active = new UserInformationStatusEnum(
		'active',
		'Active'
	);
	public static readonly Inactive = new UserInformationStatusEnum(
		'inactive',
		'In-active'
	);

	public static getValue(value: string): UserInformationStatusEnum {
		if (value === this.Active.value || value === this.Active.mappedValue) {
			return this.Active;
		} else {
			return this.Inactive;
		}
	}

	public static getAllValues(): Array<UserInformationStatusEnum> {
		return [this.Active, this.Inactive];
	}
}
