export default class PortalUserRole {
	public static readonly User = new PortalUserRole('user', 'User');
	public static readonly Admin = new PortalUserRole('admin', 'Admin');

	private value: string;
	private mappedValue: string;

	constructor(value: string, mappedValue: string) {
		this.value = value;
		this.mappedValue = mappedValue;
	}

	public getName(): string {
		return this.mappedValue;
	}

	public getValue(): string {
		return this.value;
	}

	public getMappedValue(): string {
		return this.mappedValue;
	}
	
	public static getValue(value: string): PortalUserRole {
		if (value === this.Admin.value || value === this.Admin.mappedValue) {
			return this.Admin;
		} else {
			return this.User;
		}
	}

	public static getAllValues(): Array<PortalUserRole> {
		return [this.User, this.Admin];
	}
}
