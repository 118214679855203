import classNames from 'classnames';

import { FormFieldLabel as FormFieldLabelProps } from '../types/components';

const FormFieldLabel: React.FunctionComponent<FormFieldLabelProps> = ({
	label,

	htmlFor,
	className
}) => {
	return (
		<label
			className={classNames('form__field-label', className)}
			htmlFor={htmlFor}>
			{label}
		</label>
	);
};

export default FormFieldLabel;
