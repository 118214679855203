import { useCallback, useState } from 'react';
import ReactSelect, { ActionMeta, SingleValue } from 'react-select';

import SelectOption from '../abstract-classes/SelectOption';

import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { FormFieldSelectInput as FormFieldSelectInputProps } from '../types/components';

const FormFieldSelectInput = <T extends SelectOption>({
	id,
	name,
	error,
	value,
	onBlur,
	options,
	onChange,
	isLoading,
	isClearable,
	placeholder,
	isSearchable
}: FormFieldSelectInputProps<T>) => {
	const [search, setSearch] = useState('');

	const __onChange = useCallback(
		(newValue: SingleValue<T>, actionMeta: ActionMeta<T>) => {
			if (newValue) {
				onChange(name, newValue);
			} else {
				onChange(name, null);
			}
		},
		[name, onChange]
	);

	const __filterOption = useCallback(
		(option: FilterOptionOption<T>, search: string): boolean => {
			return option.data
				.getSelectOptionValue()
				.toLowerCase()
				.includes(search.toLocaleLowerCase());
		},
		[]
	);

	const onInputChange = useCallback((search: string) => {
		setSearch(search);
	}, []);

	return (
		<ReactSelect<T>
			id={id}
			name={name}
			value={value}
			onBlur={onBlur}
			isMulti={false}
			options={options}
			inputValue={search}
			menuPosition='fixed'
			onChange={__onChange}
			isLoading={isLoading}
			placeholder={placeholder}
			isClearable={isClearable}
			isSearchable={isSearchable}
			onInputChange={onInputChange}
			filterOption={__filterOption}
			getOptionLabel={(option) => option.getSelectOptionLabel()}
			getOptionValue={(option) => option.getSelectOptionValue()}
		/>
	);
};

export default FormFieldSelectInput;
