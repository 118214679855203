import RequestHandler from '../handlers/RequestHandler';
import RequestMethodEnum from '../enums/RequestMethodEnum';

namespace QRCodeUtilParams {
	type CommonParams = {
		controller?: AbortController;
	};
	export type DownloadQR = {
		value: string;
	} & CommonParams;
}

export default class QRCodeUtil {
	public static async downloadQR(
		params: QRCodeUtilParams.DownloadQR
	): Promise<void> {
		const { value, controller } = params;

		const requestHandler = new RequestHandler();

		if (controller) {
			requestHandler.setAbortController(controller);
		}

		requestHandler.setMethod(RequestMethodEnum.Get);
		requestHandler.setUrl('/files/qr-code');
		requestHandler.addParam('value', encodeURIComponent(value));
		requestHandler.addConfiguration('responseType', 'blob');

		return requestHandler.execute().then((response) => {
			const fileName = response
				.getHeaders()
				['content-disposition'].split('"')[1]
				.trim();
			const url = window.URL.createObjectURL(
				new Blob([response.getData() as BlobPart])
			);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', fileName);
			document.body.appendChild(link);
			link.click();
			link.remove();
		});
	}
}
