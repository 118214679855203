export default class APIRequestUtil {
	public static async getErrorMessage(error: any): Promise<string> {
		return import('./CommonUtil').then(({ default: CommonUtil }) =>
			CommonUtil.processError(error)
		);
	}

	public static async handleRequestFailure(error: any): Promise<void> {
		const message = await APIRequestUtil.getErrorMessage(error);

		if (message) {
			await import('./ToastUtil').then(({ default: ToastUtil }) =>
				ToastUtil.makeFailureToast(message)
			);
		}
	}
}
