import classNames from 'classnames';

import Box from './Box';
import Typography from './Typography';

import { Badge as BadeProps } from '../types/components';

const Badge: React.FC<BadeProps> = ({ text, variant, className }) => {
	return (
		<Box className={classNames('badge badge--' + variant, className)}>
			<Typography variant='p' text={text} />
		</Box>
	);
};

export default Badge;
