import Enum from '../base-classes/Enum';

export default class PortalTypeEnum extends Enum {
	public static readonly Team = new PortalTypeEnum('team','Team');
	public static readonly Personal = new PortalTypeEnum('personal','Personal');

	public static getValue(value: string): PortalTypeEnum {
		if (value === this.Team.value || value === this.Team.mappedValue) {
			return this.Team;
		} else {
			return this.Personal;
		}
	}

	public static getAllValues(): Array<PortalTypeEnum> {
		return [this.Team, this.Personal];
	}
}
