import { useParams } from 'react-router-dom';
import { useCallback, useMemo, useState } from 'react';

import Box from '../../components/Box';
import Card from '../../components/Card';
import View from '../../components/View';
import Image from '../../components/Image';
import Button from '../../components/Button';
import Loader from '../../components/loader';
import ViewTitle from '../../components/ViewTitle';
import Typography from '../../components/Typography';
import ContainerCenter from '../../components/ContainerCenter';

import ExternalApps from './data';
import useCRUDMapper from '../../hooks/useCRUDMapper';
import APIRequestUtil from '../../utils/APIRequestUtil';
import ExternalAppPojo from '../../pojos/ExternalAppPojo';
import ExternalAppUtil from '../../utils/ExternalAppUtil';
import ConnectedAppPojo from '../../pojos/ConnectedAppPojo';
import JsonToExternalAppPojoMapper from '../../mappers/JsonToExternalAppPojoMapper';
import JsonToConnectedAppPojoMapper from '../../mappers/JsonToConnectedAppPojoMapper';

const ExternalAppCard: React.FC<{ externalAppPojo: ExternalAppPojo }> = ({
	externalAppPojo
}) => {
	const { portal_id } = useParams();

	const [isProcessing, setIsProcessing] = useState(false);

	const initiateConnection = useCallback(() => {
		setIsProcessing(true);

		ExternalAppUtil.getInstance(
			portal_id as string,
			externalAppPojo.getApp().getSelectOptionValue()
		)
			.connect(window.location.href)
			.then((url) => {
				window.location.href = url;
				setIsProcessing(false);
			})
			.catch(APIRequestUtil.handleRequestFailure);
	}, [externalAppPojo, portal_id]);
	return (
		<Card className='card--external-app'>
			<Image
				src={externalAppPojo.getIcon()}
				className='card--external-app__icon'
			/>
			<Typography
				variant='h6'
				fontSize={15}
				fontWeight={500}
				className='card--external-app__text'
				text={externalAppPojo.getApp().getMappedValue()}
			/>
			{externalAppPojo.isConnected() ? (
				<Typography variant='p' fontSize={13} text='Connected' />
			) : (
				<Button
					size='sm'
					label='Connect'
					variant='primary'
					isLoading={isProcessing}
					onClick={initiateConnection}
				/>
			)}
		</Card>
	);
};

const ExternalAppsView: React.FC = () => {
	const { portal_id } = useParams();

	const { data, isLoading } = useCRUDMapper<ConnectedAppPojo>({
		url: `/portals/${portal_id}/connected-apps`,
		mapper: JsonToConnectedAppPojoMapper.map
	});

	const externalAppPojos = useMemo<Array<ExternalAppPojo>>(() => {
		const externalAppPojos = ExternalApps.map(JsonToExternalAppPojoMapper.map);

		for (const externalAppPojo of externalAppPojos) {
			const isAppConnected = !!data.find(
				(obj) => obj.getApp() === externalAppPojo.getApp()
			);

			externalAppPojo.setConnected(isAppConnected);
		}

		return externalAppPojos;
	}, [data]);

	if (isLoading) {
		return (
			<ContainerCenter>
				<Loader />
			</ContainerCenter>
		);
	} else {
		return (
			<View>
				<ViewTitle text='External Apps' marginBottom='20px' />
				<Box className='container--external-apps__cards'>
					{externalAppPojos.map((item) => (
						<ExternalAppCard externalAppPojo={item} key={item.getId()} />
					))}
				</Box>
			</View>
		);
	}
};

export default ExternalAppsView;
