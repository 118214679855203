export default abstract class DataModel {
	protected id: string;

	constructor() {
		this.id = '';
	}

	public getId(): string {
		return this.id;
	}

	public setId(id: string): void {
		this.id = id;
	}

	public getUpdatePayload?(): Record<string, unknown> | FormData;
	public abstract getCreatePayload(): Record<string, unknown> | FormData;
}
