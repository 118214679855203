import Enum from '../base-classes/Enum';

export default class LeadTypeEnum extends Enum {
	public static readonly Internal = new LeadTypeEnum('internal', 'Internal');
	public static readonly External = new LeadTypeEnum('external', 'External');

	public static readonly getValue = (value: string): LeadTypeEnum => {
		if (value === this.External.value || value === this.External.mappedValue) {
			return this.External;
		} else {
			return this.Internal;
		}
	};
}
