import { useMemo } from 'react';

import ProfileTemplateNode from './ProfileTemplateNode';
import UserInformationDto from '../dtos/UserInformationDto';
import UserInformationReplacer from '../replacers/UserInformationReplacer';
import ProfileTemplateNodeCategoryEnum from '../enums/ProfileTemplateNodeCategoryEnum';

export type ProfileTemplateTextNodeProps = {
	disabled?: boolean;
	userInformationPojo: UserInformationDto;
	profileTemplateNode: ProfileTemplateNode;
};

const ProfileTemplateTextNode: React.FC<ProfileTemplateTextNodeProps> = ({
	userInformationPojo,
	profileTemplateNode
}) => {
	const style = useMemo<React.CSSProperties>(
		() => profileTemplateNode.getStyles().toJSON(),
		[profileTemplateNode]
	);

	const displayText = useMemo<string>(() => {
		if (
			profileTemplateNode.getCategory() ===
			ProfileTemplateNodeCategoryEnum.Dynamic
		) {
			return UserInformationReplacer.getInstance(userInformationPojo).replace(
				profileTemplateNode.getProperties().getValue() as string
			);
		} else {
			return profileTemplateNode.getProperties().getValue() as string;
		}
	}, [userInformationPojo, profileTemplateNode]);

	return <p style={style}>{displayText}</p>;
};

export default ProfileTemplateTextNode;
