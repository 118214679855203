import { MappedValue } from '../datatypes';

export default class LeadConstants {
	static readonly Types = {
		Internal: new MappedValue('internal', 'Internal'),
		External: new MappedValue('external', 'External')
	};

	static readonly getTypeByValue = (value: string): MappedValue => {
		for (const type of Object.values(this.Types)) {
			if (type.getValue() === value) {
				return type;
			}
		}
		return this.Types.Internal;
	};

	static readonly getTypeByMappedValue = (value: string): MappedValue => {
		for (const type of Object.values(this.Types)) {
			if (type.getMappedValue() === value) {
				return type;
			}
		}
		return this.Types.Internal;
	};
}
