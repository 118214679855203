export default class ForeignKey {
	private id: string;
	private name: string;

	constructor() {
		this.id = '';
		this.name = '';
	}

	public getId(): string {
		return this.id;
	}

	public setId(id: string): void {
		this.id = id;
	}

	public getName(): string {
		return this.name;
	}

	public setName(name: string): void {
		this.name = name;
	}

	public loadFromJSON = (json: Record<string, unknown>): void => {
		if (json) {
			this.id = json.id as string;
			this.name = json.name as string;
		}
	};
}
