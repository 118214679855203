const ErrorMessageConstants = Object.freeze({
	Common: {
		PermissionRestricted:
			"You don't have the permission to perform this operation.",
		DataAlreadyExists: '{0} already exists.',
		NoDataFound: 'The requested resource does not exists on the server.'
	},
	Ajv: {
		Common: {
			NonEmpty: '{0} cannot be empty.',
			InvalidValue: 'Invalid value for {0}. {0} must be a {1}'
		},
		String: {
			Format: {
				Identifier:
					'Invalid value for {0}. {0} must be contain only alphanumeric characters, whitespaces in the middle and underscores.'
			},
			Maxlength: '{0} should contain a maximum of {1} characters.',
			Enum: 'Invalid value for {0}. {0} must be one of the following values: {1}'
		},
		Array: {
			Type: 'Invalid value for {0}. {0} must be array of {1}',
			MaxItems: '{0} should contain a maximum of {1} items.'
		},
		Number: {
			Type: '{0} must be a number.',
			Maximum: '{0} must be less than or equal to {1}.',
			Minimum: '{0} must be greater than or equal to {1}.'
		}
	},
	Card: {
		Audit: {
			Approved: 'The card design has already been approved.',
			NotReady: 'The card has not been submitted for auditing yet.'
		},
		Status: {
			NotReadyForStatusUpdate:
				'You cannot activate the card until it has been approved.'
		},

		Update: {
			Rejected:
				'Card modifications are rejected since the design has been accepted.',
			Restricted: 'Card modifictions are restricted during the audit phase.'
		}
	},
	UserInformation: {
		Card: {
			Inactive:
				'The card is currently inactive. Please activate it to access the information.'
		}
	}
});

export default ErrorMessageConstants;
