export default class TouchpointTemplateImageNodeProperties {
	private src: string;

	constructor() {
		this.src = '';
	}

	public getSrc(): string {
		return this.src;
	}

	public setSrc(src: string): void {
		this.src = src;
	}

	public loadFromJSON(json: Record<string, string>) {
		this.src = json.src;
	}

	public toJSON(): Record<string, string> {
		return JSON.parse(
			JSON.stringify({
				src: this.src
			})
		);
	}
}
