import classNames from 'classnames';
import { NavLink, Outlet, useOutletContext } from 'react-router-dom';

import PortalUserRole from '../../portal-users/PortalUserRole';

import {
	PortalLayoutContext,
	SettingsLayoutContext
} from '../../types/context';

const SettingsLayout: React.FunctionComponent = () => {
	const { portalUserPojo, ...others } = useOutletContext<PortalLayoutContext>();
	return (
		<div className='container__settings'>
			<div className='sidebar--secondary'>
				<NavLink
					to='my-information'
					className={({ isActive }) =>
						classNames(
							'navlink navlink--secondary',
							isActive && 'navlink--secondary--active'
						)
					}>
					<p className='title__navlink--secondary'>My Information</p>
				</NavLink>

				{portalUserPojo.getRole() === PortalUserRole.Admin && (
					<NavLink
						to='external-apps'
						className={({ isActive }) =>
							classNames(
								'navlink navlink--secondary',
								isActive && 'navlink--secondary--active'
							)
						}>
						<p className='title__navlink--secondary'>External Apps</p>
					</NavLink>
				)}
			</div>
			<div className='container__settings-content'>
				<Outlet
					context={
						{
							portalUserPojo,
							...others
						} satisfies SettingsLayoutContext
					}
				/>
			</div>
		</div>
	);
};

export default SettingsLayout;
