export default class TemplateNodeStyles {
	private top?: string;
	private left?: string;
	private right?: string;
	private scale?: string;
	private color?: string;
	private width?: string;
	private bottom?: string;
	private height?: string;
	private rowGap?: string;
	private margin?: string;
	private padding?: string;
	private display?: string;
	private minWidth?: string;
	private position?: string;
	private overflow?: string;
	private fontSize?: string;
	private minHeight?: string;
	private textAlign?: string;
	private marginTop?: string;
	private columnGap?: string;
	private paddingTop?: string;
	private marginLeft?: string;
	private alignItems?: string;
	private fontFamily?: string;
	private fontWeight?: string;
	private paddingLeft?: string;
	private marginRight?: string;
	private paddingRight?: string;
	private borderRadius?: string;
	private marginBottom?: string;
	private paddingBottom?: string;
	private flexDirection?: string;
	private justifyContent?: string;
	private backgroundImage?: string;
	private backgroundColor?: string;

	public getTop(): string | undefined {
		return this.top;
	}

	public setTop(top: string | undefined): void {
		this.top = top;
	}

	public getLeft(): string | undefined {
		return this.left;
	}

	public setLeft(left: string | undefined): void {
		this.left = left;
	}

	public getRight(): string | undefined {
		return this.right;
	}

	public setRight(right: string | undefined): void {
		this.right = right;
	}

	public getScale(): string | undefined {
		return this.scale;
	}

	public setScale(scale: string | undefined): void {
		this.scale = scale;
	}

	public getColor(): string | undefined {
		return this.color;
	}

	public setColor(color: string | undefined) {
		this.color = color;
	}

	public getWidth(): string | undefined {
		return this.width;
	}

	public setWidth(width: string | undefined) {
		this.width = width;
	}

	public getBottom(): string | undefined {
		return this.bottom;
	}

	public setBottom(bottom: string | undefined): void {
		this.bottom = bottom;
	}

	public getHeight(): string | undefined {
		return this.height;
	}

	public setHeight(height: string | undefined) {
		this.height = height;
	}

	public getRowGap(): string | undefined {
		return this.rowGap;
	}

	public setRowGap(rowGap: string | undefined) {
		this.rowGap = rowGap;
	}

	public getMargin(): string | undefined {
		return this.margin;
	}

	public setMargin(margin: string | undefined) {
		this.margin = margin;
	}

	public getPadding(): string | undefined {
		return this.padding;
	}

	public setPadding(padding: string | undefined) {
		this.padding = padding;
	}

	public getDisplay(): string | undefined {
		return this.display;
	}

	public setDisplay(display: string | undefined) {
		this.display = display;
	}

	public getMinWidth(): string | undefined {
		return this.minWidth;
	}

	public setMinWidth(minWidth: string | undefined): void {
		this.minWidth = minWidth;
	}

	public getPosition(): string | undefined {
		return this.position;
	}

	public setPosition(position: string | undefined): void {
		this.position = position;
	}

	public getOverflow(): string | undefined {
		return this.overflow;
	}

	public setOverflow(overflow: string | undefined) {
		this.overflow = overflow;
	}

	public getFontSize(): string | undefined {
		return this.fontSize;
	}

	public setFontSize(fontSize: string | undefined) {
		this.fontSize = fontSize;
	}

	public getMinHeight(): string | undefined {
		return this.minHeight;
	}

	public setMinHeight(minHeight: string | undefined): void {
		this.minHeight = minHeight;
	}

	public getTextAlign(): string | undefined {
		return this.textAlign;
	}

	public setTextAlign(textAlign: string | undefined): void {
		this.textAlign = textAlign;
	}

	public getMarginTop(): string | undefined {
		return this.marginTop;
	}

	public setMarginTop(marginTop: string | undefined) {
		this.marginTop = marginTop;
	}

	public getColumnGap(): string | undefined {
		return this.columnGap;
	}

	public setColumnGap(columnGap: string | undefined) {
		this.columnGap = columnGap;
	}

	public getPaddingTop(): string | undefined {
		return this.paddingTop;
	}

	public setPaddingTop(paddingTop: string | undefined) {
		this.paddingTop = paddingTop;
	}

	public getMarginLeft(): string | undefined {
		return this.marginLeft;
	}

	public setMarginLeft(marginLeft: string | undefined) {
		this.marginLeft = marginLeft;
	}

	public getAlignItems(): string | undefined {
		return this.alignItems;
	}

	public setAlignItems(alignItems: string | undefined) {
		this.alignItems = alignItems;
	}

	public getFontFamily(): string | undefined {
		return this.fontFamily;
	}

	public setFontFamily(fontFamily: string | undefined) {
		this.fontFamily = fontFamily;
	}

	public getFontWeight(): string | undefined {
		return this.fontWeight;
	}

	public setFontWeight(fontWeight: string | undefined) {
		this.fontWeight = fontWeight;
	}

	public getPaddingLeft(): string | undefined {
		return this.paddingLeft;
	}

	public setPaddingLeft(paddingLeft: string | undefined) {
		this.paddingLeft = paddingLeft;
	}

	public getMarginRight(): string | undefined {
		return this.marginRight;
	}

	public setMarginRight(marginRight: string | undefined) {
		this.marginRight = marginRight;
	}

	public getPaddingRight(): string | undefined {
		return this.paddingRight;
	}

	public setPaddingRight(paddingRight: string | undefined) {
		this.paddingRight = paddingRight;
	}

	public getBorderRadius(): string | undefined {
		return this.borderRadius;
	}

	public setBorderRadius(borderRadius: string | undefined) {
		this.borderRadius = borderRadius;
	}

	public getMarginBottom(): string | undefined {
		return this.marginBottom;
	}

	public setMarginBottom(marginBottom: string | undefined) {
		this.marginBottom = marginBottom;
	}

	public getPaddingBottom(): string | undefined {
		return this.paddingBottom;
	}

	public setPaddingBottom(paddingBottom: string | undefined) {
		this.paddingBottom = paddingBottom;
	}

	public getFlexDirection?(): string | undefined {
		return this.flexDirection;
	}

	public setFlexDirection?(flexDirection: string | undefined): void {
		this.flexDirection = flexDirection;
	}

	public getJustifyContent(): string | undefined {
		return this.justifyContent;
	}

	public setJustifyContent(justifyContent: string | undefined) {
		this.justifyContent = justifyContent;
	}

	public getBackgroundImage(): string | undefined {
		return this.backgroundImage;
	}

	public setBackgroundImage(backgroundImage: string | undefined) {
		this.backgroundImage = backgroundImage;
	}

	public getBackgroundColor(): string | undefined {
		return this.backgroundColor;
	}

	public setBackgroundColor(backgroundColor: string | undefined) {
		this.backgroundColor = backgroundColor;
	}

	public loadFromJSON(json: Record<string, string>) {
		this.top = json.top;
		this.left = json.left;
		this.right = json.right;
		this.scale = json.scale;
		this.color = json.color;
		this.width = json.width;
		this.height = json.height;
		this.bottom = json.bottom;
		this.rowGap = json.rowGap;
		this.margin = json.margin;
		this.padding = json.padding;
		this.display = json.display;
		this.minWidth = json.minWidth;
		this.position = json.position;
		this.overflow = json.overflow;
		this.fontSize = json.fontSize;
		this.minHeight = json.minHeight;
		this.textAlign = json.textAlign;
		this.marginTop = json.marginTop;
		this.columnGap = json.columnGap;
		this.paddingTop = json.paddingTop;
		this.marginLeft = json.marginLeft;
		this.alignItems = json.alignItems;
		this.fontFamily = json.fontFamily;
		this.fontWeight = json.fontWeight;
		this.paddingLeft = json.paddingLeft;
		this.marginRight = json.marginRight;
		this.paddingRight = json.paddingRight;
		this.borderRadius = json.borderRadius;
		this.marginBottom = json.marginBottom;
		this.paddingBottom = json.paddingBottom;
		this.flexDirection = json.flexDirection;
		this.justifyContent = json.justifyContent;
		this.backgroundImage = json.backgroundImage;
		this.backgroundColor = json.backgroundColor;
	}

	public toJSON(): Record<string, unknown> {
		return JSON.parse(
			JSON.stringify({
				top: this.top,
				left: this.left,
				right: this.right,
				scale: this.scale,
				color: this.color,
				width: this.width,
				bottom: this.bottom,
				height: this.height,
				rowGap: this.rowGap,
				margin: this.margin,
				padding: this.padding,
				display: this.display,
				minWidth: this.minWidth,
				position: this.position,
				overflow: this.overflow,
				fontSize: this.fontSize,
				minHeight: this.minHeight,
				textAlign: this.textAlign,
				marginTop: this.marginTop,
				columnGap: this.columnGap,
				paddingTop: this.paddingTop,
				marginLeft: this.marginLeft,
				alignItems: this.alignItems,
				fontFamily: this.fontFamily,
				fontWeight: this.fontWeight,
				paddingLeft: this.paddingLeft,
				marginRight: this.marginRight,
				paddingRight: this.paddingRight,
				borderRadius: this.borderRadius,
				marginBottom: this.marginBottom,
				paddingBottom: this.paddingBottom,
				flexDirection: this.flexDirection,
				justifyContent: this.justifyContent,
				backgroundImage: this.backgroundImage,
				backgroundColor: this.backgroundColor
			})
		);
	}

	public toString(): string {
		return JSON.stringify(this.toJSON());
	}
}
