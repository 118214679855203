export default class SocialUrls {
	private twitter_url: string;
	private linkedIn_url: string;
	private facebook_url: string;

	constructor() {
		this.twitter_url = '';
		this.facebook_url = '';
		this.linkedIn_url = '';
	}

	public getTwitter_url(): string {
		return this.twitter_url;
	}

	public setTwitter_url(twitter_url: string): void {
		this.twitter_url = twitter_url;
	}

	public getLinkedIn_url(): string {
		return this.linkedIn_url;
	}

	public setLinkedIn_url(linkedIn_url: string): void {
		this.linkedIn_url = linkedIn_url;
	}

	public getFacebook_url(): string {
		return this.facebook_url;
	}

	public setFacebook_url(facebook_url: string): void {
		this.facebook_url = facebook_url;
	}

	public loadFromJson = (json: Record<string, string>): void => {
		this.twitter_url = json.twitter_url;
		this.facebook_url = json.facebook_url;
		this.linkedIn_url = json.linkedIn_url;
	};

	public isEmpty(): boolean {
		return !this.twitter_url && !this.facebook_url && !this.linkedIn_url;
	}

	public toJSON(): Record<string, string> {
		const json: Record<string, string> = {};

		if (this.twitter_url) {
			json.twitter_url = this.twitter_url;
		}

		if (this.facebook_url) {
			json.facebook_url = this.facebook_url;
		}

		if (this.linkedIn_url) {
			json.linkedIn_url = this.linkedIn_url;
		}

		return json;
	}

	public toString = (): string => {
		return JSON.stringify(this.toJSON());
	};
}
