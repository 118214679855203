export default class LeadDataModel {
	private user_id: string;
	private portal_id: string;
	private values: Record<string, string>;

	constructor() {
		this.values = {};
		this.user_id = '';
		this.portal_id = '';
	}

	public getUser_id(): string {
		return this.user_id;
	}

	public setUser_id(user_id: string): void {
		this.user_id = user_id;
	}

	public getPortal_id(): string {
		return this.portal_id;
	}

	public setPortal_id(portal_id: string): void {
		this.portal_id = portal_id;
	}

	public getValues(): Record<string, string> {
		return this.values;
	}

	public setValues(values: Record<string, string>): void {
		this.values = values;
	}

	public getInsertPayload(): Record<string, string> {
		return this.values;
	}
}
