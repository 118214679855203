const Loader: React.FunctionComponent = () => {
	return (
		<div className='circle-loader'>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	);
};

export default Loader;
