import classNames from 'classnames';

import { TableDataCell as TableDataCellProps } from '../types/components';

const TableDataCell: React.FC<TableDataCellProps> = ({
	text,
	colSpan,
	className
}) => {


	return (
		<td colSpan={colSpan} className={classNames('table__cell', className)}>
			{text}
		</td>
	);
};

export default TableDataCell;
