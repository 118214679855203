import { MappedValue } from '../datatypes';

export default class TemplateConstants {
	static readonly Types = {
		Card: new MappedValue('card', 'Card'),
		Profile: new MappedValue('profile', 'Profile')
	};

	static readonly Category = {
		Form: new MappedValue('form', 'Form'),
		FormWithView: new MappedValue('form with view', 'Form With View')
	};

	static readonly getTypeByValue = (value: string): MappedValue => {
		for (const type of Object.values(this.Types)) {
			if (type.getValue() === value) {
				return type;
			}
		}
		return this.Types.Profile;
	};

	static readonly getTypeByMappedValue = (
		value: string
	): MappedValue => {
		for (const type of Object.values(this.Types)) {
			if (type.getMappedValue() === value) {
				return type;
			}
		}
		return this.Types.Profile;
	};

	static getAllMappedValueOfTypes = (): Array<string> => {
		return Object.values(this.Types).map((item) => item.getMappedValue());
	};

	static readonly getCategoryByValue = (value: string): MappedValue => {
		for (const category of Object.values(this.Category)) {
			if (category.getValue() === value) {
				return category;
			}
		}
		return this.Category.Form;
	};

	static readonly getCategoryByMappedValue = (
		value: string
	): MappedValue => {
		for (const category of Object.values(this.Category)) {
			if (category.getMappedValue() === value) {
				return category;
			}
		}
		return this.Category.Form;
	};

	static getAllMappedValueOfCategory = (): Array<string> => {
		return Object.values(this.Category).map((item) => item.getMappedValue());
	};

	public static getAllCategory = (): Array<MappedValue> => {
		return Object.values(this.Category);
	};
}
