import classNames from 'classnames';

import Typography from './Typography';

import { ViewTitle as ViewTitleProps } from '../types/components';

const ViewTitle: React.FC<ViewTitleProps> = ({
	text,
	className,
	marginBottom
}) => {
	return (
		<Typography
			variant='h6'
			text={text}
			marginBottom={marginBottom}
			className={classNames('title--page', className)}
		/>
	);
};

export default ViewTitle;
