import classNames from 'classnames';

import CircularLoaderIcon from '../icons/CircularLoaderIcon';

import { LoaderLoadMoreRecords as LoaderLoadMoreRecordsProps } from '../types/components';

const LoaderLoadMoreRecords: React.FC<LoaderLoadMoreRecordsProps> = ({ className }) => {
	return (
		<div className={classNames('container--load-more', className)}>
			<CircularLoaderIcon className='loader--load-more' />
		</div>
	);
};

export default LoaderLoadMoreRecords;
