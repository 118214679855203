import { Icon as IconProps } from '../types/components';

const FacebookIcon = ({
	style,
	widith,
	height,
	onClick,
	className
}: IconProps) => {
	return (
		<svg
			style={style}
			width={widith}
			height={height}
			onClick={onClick}
			className={className}
			viewBox='0 0 256 256'
			xmlns='http://www.w3.org/2000/svg'>
			<g
				strokeWidth='1'
				strokeLinecap='butt'
				strokeLinejoin='miter'
				strokeMiterlimit='10'
				strokeDasharray=''
				strokeDashoffset='0'
				style={{ mixBlendMode: 'normal' }}>
				<g transform='scale(5.33333,5.33333)'>
					<path
						d='M42,37c0,2.762 -2.238,5 -5,5h-26c-2.761,0 -5,-2.238 -5,-5v-26c0,-2.762 2.239,-5 5,-5h26c2.762,0 5,2.238 5,5z'
						fill='#0765ff'></path>
					<path
						d='M34.368,25h-3.368v13h-5v-13h-3v-4h3v-2.41c0.002,-3.508 1.459,-5.59 5.592,-5.59h3.408v4h-2.287c-1.609,0 -1.713,0.6 -1.713,1.723v2.277h4z'
						fill='#ffffff'></path>
				</g>
			</g>
		</svg>
	);
};

export default FacebookIcon;
