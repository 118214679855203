import { useMemo } from 'react';

import TouchpointTemplateNode from './TouchpointTemplateNode';
import TouchpointTemplateInputNodeProperties from './TouchpointTemplateInputNodeProperties';

type TouchpointTemplateContainerNodeProps = {
	touchpointTemplateNode: TouchpointTemplateNode;
};

export default function TouchpointTemplateInputNode({
	touchpointTemplateNode
}: TouchpointTemplateContainerNodeProps) {
	const properties = useMemo(() => {
		const properties = new TouchpointTemplateInputNodeProperties();
		properties.loadFromJSON(
			touchpointTemplateNode.getProperties() as Record<string, string>
		);
		return properties;
	}, [touchpointTemplateNode]);
	return (
		<p style={touchpointTemplateNode.getStyles().toJSON()}>
			{properties.getValue() || properties.getDefaultValue()}
		</p>
	);
}
