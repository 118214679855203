import { MappedValue } from '../datatypes';

export default class TemplateAssetConstants {
	public static readonly Types = {
		Text: new MappedValue('text', 'Text'),
		File: new MappedValue('file', 'File')
	};


	public static readonly getTypeByValue = (value: string): MappedValue => {
		for (const type of Object.values(this.Types)) {
			if (type.getValue() === value) {
				return type;
			}
		}

		return this.Types.Text;
	};

	public static readonly getTypeByMappedValue = (
		value: string
	): MappedValue => {
		for (const type of Object.values(this.Types)) {
			if (type.getMappedValue() === value) {
				return type;
			}
		}

		return this.Types.Text;
	};
}
