import LeadStorageLocationEnum from '../enums/LeadStorageLocationEnum';

export default class ExternalAppPojo {
	private id: string;
	private icon: string;
	private connected: boolean;
    
	private app: LeadStorageLocationEnum;

	constructor() {
		this.id = '';
		this.icon = '';
		this.connected = false;
		this.app = LeadStorageLocationEnum.Application;
	}

	public getId(): string {
		return this.id;
	}

	public setId(id: string): void {
		this.id = id;
	}
	public getIcon(): string {
		return this.icon;
	}

	public setIcon(icon: string): void {
		this.icon = icon;
	}

	public isConnected(): boolean {
		return this.connected;
	}

	public setConnected(connected: boolean): void {
		this.connected = connected;
	}

	public getApp(): LeadStorageLocationEnum {
		return this.app;
	}

	public setApp(app: LeadStorageLocationEnum): void {
		this.app = app;
	}

	public loadFromJson(json: Record<string, unknown>): void {
		this.id = json.id as string;
		this.icon = json.icon as string;
		this.app = LeadStorageLocationEnum.getValue(json.app as string);
	}
}
