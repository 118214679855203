import classNames from 'classnames';
import { XYCoord, useDrag, useDrop } from 'react-dnd';
import { useOutletContext } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Box from '../../components/Box';
import Card from '../../components/Card';
import EditIcon from '../../icons/EditIcon';
import Loader from '../../components/loader';
import Button from '../../components/Button';
import Typography from '../../components/Typography';
import CreateFormFieldModal from './CreateFormFieldModal';
import UpdateFormFieldModal from './UpdateFormFieldModal';
import FormFieldGroup from '../../components/FormFieldGroup';
import FormFieldLabel from '../../components/FormFieldLabel';
import ContainerCenter from '../../components/ContainerCenter';
import FormFieldTextInput from '../../components/FormFieldTextInput';
import FormFieldTextareaInput from '../../components/FormFieldTextareaInput';
import ContainerProfileCategoryBody from '../../components/ContainerProfileCategoryBody';
import ContainerProfileCategoryFooter from '../../components/ContainerProfileCategoryFooter';
import ContainerProfileCategoryHeader from '../../components/ContainerProfileCategoryHeader';

import FormUtil from '../../utils/FormUtil';
import ToastUtil from '../../utils/ToastUtil';
import FormFieldDto from '../../dtos/FormFieldDto';
import FormFieldUtil from '../../utils/FormFieldUtil';
import { EventEmitterInstance } from '../../instances';
import APIRequestUtil from '../../utils/APIRequestUtil';
import ConnectedAppUtil from '../../utils/ConnectedAppUtil';
import FormFieldType from '../../enums/FormFieldType';
import FormFieldDataModel from '../../model/FormFieldDataModel';
import ProfileTemplateDto from '../../profile-templates/ProfileTemplateDto';
import UserInformationUtil from '../../utils/UserInformationUtil';
import ExternalAppField from '../../external-app/ExternalAppField';
import EventNamesConstants from '../../constants/EventNamesConstants';
import UserInformationDataModel from '../../model/UserInformationDataModel';

import { ProfilePickerLayoutContext } from '../../types/context';

const ZohoCrmFieldCard = ({
	zohoCrmField
}: {
	zohoCrmField: ExternalAppField;
}) => {
	const [{ isDragging }, ref] = useDrag({
		type: 'zoho-crm-field',
		item: {
			zohoCrmField
		},
		collect: (monitor) => ({
			isDragging: monitor.isDragging()
		})
	});
	return (
		<Card
			className={classNames(
				'card--profile-lead-gen__zoho-crm-field',
				isDragging && 'card--profile-lead-gen__zoho-crm-field--dragging'
			)}
			ref={ref}>
			<Typography
				variant='h6'
				fontSize={14}
				fontWeight={500}
				text={zohoCrmField.getSelectOptionLabel()}
			/>
		</Card>
	);
};

const SystemFieldCard = ({
	systemField,
	index: hoverIndex
}: {
	index: number;
	systemField: FormFieldDto;
}) => {
	const ref = useRef<HTMLDivElement>(null);
	const [, drop] = useDrop<{ index: number }>({
		accept: 'system-field-card',
		hover(item, monitor) {
			const dragIndex = item.index;

			if (!ref.current) {
				return;
			}
			if (dragIndex === hoverIndex) {
				return;
			}

			const hoverBoundingRect = ref.current?.getBoundingClientRect();

			const hoverMiddleY =
				(hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

			const clientOffset = monitor.getClientOffset();

			const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}

			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}

			EventEmitterInstance.emit(
				EventNamesConstants.FormFields.Data.Reorder,
				dragIndex,
				hoverIndex
			);
			item.index = hoverIndex;
		}
	});
	const [{ isDragging }, drag] = useDrag({
		type: 'system-field-card',
		item: () => {
			return { index: hoverIndex };
		},
		collect: (monitor: any) => ({
			isDragging: monitor.isDragging()
		})
	});

	const editSystemField = useCallback(() => {
		EventEmitterInstance.emit(
			EventNamesConstants.FormFields.Modal.Update,
			systemField
		);
	}, [systemField]);

	drag(drop(ref));

	return (
		<Card
			ref={ref}
			className={classNames(
				'card--profile-lead-gen__system-field',
				isDragging && 'card--profile-lead-gen__system-field--dragging'
			)}>
			<Typography
				variant='h6'
				fontSize={14}
				fontWeight={500}
				text={systemField.getName() + (systemField.isRequired() ? ' * ' : '')}
				className='card--profile-lead-gen__system-field__title'
			/>
			<EditIcon
				className='card--profile-lead-gen__system-field__icon'
				onClick={editSystemField}
			/>
		</Card>
	);
};

const ProfileTemplateBuilderContainer = ({
	profileTemplateDto
}: {
	profileTemplateDto: ProfileTemplateDto;
}) => {
	const { portalUserPojo, currentProfileTemplatePojo } =
		useOutletContext<ProfilePickerLayoutContext>();

	const [, dropRef] = useDrop({
		accept: 'zoho-crm-field',
		drop: ({ zohoCrmField }: { zohoCrmField: ExternalAppField }) => {
			EventEmitterInstance.emit(
				EventNamesConstants.FormFields.Modal.Create,
				zohoCrmField
			);
		}
	});

	const [isLoading, setIsLoading] = useState(true);
	const [isTemplateActivating, setIsTemplateActivating] = useState(false);
	const [isFieldSequenceUpdated, setIsFieldSequenceUpdated] = useState(false);
	const [issFormFieldSequenceUpdating, setIsFormFieldSequenceUpdating] =
		useState(false);

	const [formFields, setFormFields] = useState<Array<FormFieldDto>>([]);
	const [externalAppFields, setExternalAppFields] = useState<
		Array<ExternalAppField>
	>([]);

	const availableExternalAppFields = useMemo<Array<ExternalAppField>>(
		() =>
			externalAppFields.filter(
				(item) =>
					!formFields.find(
						(obj) => obj.getExternal_app_field().getId() === item.getId()
					)
			),
		[externalAppFields, formFields]
	);

	const activateProfileTemplate = useCallback(() => {
		setIsTemplateActivating(true);

		const userInformationDataModel = new UserInformationDataModel();
		userInformationDataModel.setUser_id(portalUserPojo.getId());
		userInformationDataModel.setProfile_template(profileTemplateDto.getName());
		userInformationDataModel.setPortal_id(
			profileTemplateDto.getPortal().getId()
		);

		UserInformationUtil.updateUserInformation({ userInformationDataModel })
			.then(() => {
				EventEmitterInstance.emit(
					EventNamesConstants.ProfileTemplates.Data.Activate,
					profileTemplateDto
				);
				ToastUtil.makeSuccessToast('Template has been activated successfully.');
			})
			.catch(APIRequestUtil.handleRequestFailure)
			.finally(() => {
				setIsTemplateActivating(false);
			});
	}, [portalUserPojo, profileTemplateDto]);

	const updateFormFieldSequences = useCallback(() => {
		const form_id = profileTemplateDto.getForm().getId();
		const portal_id = profileTemplateDto.getPortal().getId();
		setIsFormFieldSequenceUpdating(true);

		FormFieldUtil.getInstance(portal_id, form_id)
			.updateFormFieldSequences(
				formFields.map((item, index) => {
					const formFieldDataModel = new FormFieldDataModel();
					formFieldDataModel.setId(item.getId());
					formFieldDataModel.setSequence(index + 1);
					return formFieldDataModel;
				})
			)
			.then((records) => {
				setFormFields(records);
				setIsFieldSequenceUpdated(false);
				ToastUtil.makeSuccessToast(
					'System fields sequence has been saved successfully.'
				);
			})
			.catch(APIRequestUtil.handleRequestFailure)
			.finally(() => setIsFormFieldSequenceUpdating(false));
	}, [formFields, profileTemplateDto]);

	const dummyFormFieldOnChange = useCallback((name: string, value: string) => {
		// This is a dummy function
	}, []);

	const dummyButtonClick = useCallback(() => {
		// This is a dummy function
	}, []);

	useEffect(() => {
		setIsLoading(true);

		const form_id = profileTemplateDto.getForm().getId();
		const portal_id = profileTemplateDto.getPortal().getId();
		FormUtil.getInstance(portal_id)
			.getForm(form_id)
			.then((formDto) => {
				ConnectedAppUtil.getInstance(portal_id)
					.getConnectedAppFields(
						formDto.getStorage_location().getSelectOptionValue(),
						formDto.getExternal_app_module().getApi_name()
					)
					.then((externalAppFields) => {
						setExternalAppFields(externalAppFields);
						FormFieldUtil.getInstance(portal_id, form_id)
							.getFormFields()
							.then((formFields) => {
								setIsLoading(false);
								setFormFields(formFields);
							})
							.catch(APIRequestUtil.handleRequestFailure);
					})
					.catch(APIRequestUtil.handleRequestFailure);
			})
			.catch(APIRequestUtil.handleRequestFailure);

		const createFormFieldEventListener = (formFieldDto: FormFieldDto) => {
			setFormFields((prev) => prev.concat([formFieldDto]));
		};

		const reorderFormFieldsEventListener = (
			sourceIndex: number,
			destinationIndex: number
		) => {
			setIsFieldSequenceUpdated(true);
			setFormFields((prev) => {
				const temp = Array.from(prev);

				if (sourceIndex < 0 || sourceIndex >= temp.length) {
					return temp;
				}
				if (destinationIndex < 0 || destinationIndex >= temp.length) {
					return temp;
				}

				const draggedItem = temp.splice(sourceIndex, 1)[0];
				temp.splice(destinationIndex, 0, draggedItem);

				return temp;
			});
		};

		const updateFormFieldEventListener = (formFieldDto: FormFieldDto) => {
			setFormFields((prev) => {
				const temp = Array.from(prev);
				const index = temp.findIndex(
					(obj) => obj.getId() === formFieldDto.getId()
				);

				if (index !== -1) {
					temp[index] = formFieldDto;
				}
				return temp;
			});
		};

		const cSubscription = EventEmitterInstance.addListener(
			EventNamesConstants.FormFields.Data.Create,
			createFormFieldEventListener
		);

		const rSubscription = EventEmitterInstance.addListener(
			EventNamesConstants.FormFields.Data.Reorder,
			reorderFormFieldsEventListener
		);
		const uSubscription = EventEmitterInstance.addListener(
			EventNamesConstants.FormFields.Data.Update,
			updateFormFieldEventListener
		);

		return () => {
			cSubscription.remove();
			uSubscription.remove();
			rSubscription.remove();
		};
	}, [profileTemplateDto]);

	if (isLoading) {
		return (
			<ContainerCenter>
				<Loader />
			</ContainerCenter>
		);
	} else {
		return (
			<>
				<ContainerProfileCategoryHeader>
					<Typography
						variant='h6'
						fontSize={22}
						text='Lead Gen'
						fontWeight={600}
						marginRight='auto'
					/>
					<Button
						label='Activate'
						variant='primary'
						isLoading={isTemplateActivating}
						onClick={activateProfileTemplate}
						disabled={
							isFieldSequenceUpdated ||
							currentProfileTemplatePojo.getId() === profileTemplateDto.getId()
						}
					/>
				</ContainerProfileCategoryHeader>
				<ContainerProfileCategoryBody className='container--profile--lead-gen__category__body'>
					<Box className='container--profile--lead-gen__zoho-crm-fields'>
						<Typography
							text='Zoho Crm Fields'
							variant='h6'
							fontWeight={600}
							fontSize={22}
							marginBottom='10px'
						/>
						{availableExternalAppFields.map((item) => (
							<ZohoCrmFieldCard zohoCrmField={item} key={item.getId()} />
						))}
					</Box>
					<Box
						className='container--profile--lead-gen__system-fields'
						ref={dropRef}>
						<Typography
							variant='h6'
							fontSize={22}
							fontWeight={600}
							text='System Fields'
							marginBottom='10px'
						/>
						{formFields.map((item, index) => (
							<SystemFieldCard
								index={index}
								key={item.getId()}
								systemField={item}
							/>
						))}
					</Box>
					<Box className='container--profile--lead-gen__preview'>
						<Typography
							variant='h6'
							fontSize={22}
							fontWeight={600}
							text='Preview'
							marginBottom='10px'
						/>

						<Card className='card--profile-lead-gen__preview'>
							<Typography
								variant='h6'
								fontSize={18}
								fontWeight={600}
								marginBottom='15px'
								text='Share your details'
							/>
							{formFields.map((item) => (
								<FormFieldGroup key={item.getId()}>
									<FormFieldLabel
										label={item.getLabel() + (item.isRequired() ? '*' : '')}
										htmlFor={item.getName()}
									/>
									{item.getType() === FormFieldType.Textarea ? (
										<FormFieldTextareaInput
											value=''
											disabled={true}
											name={item.getName()}
											onChange={dummyFormFieldOnChange}
											placeholder={item.getPlaceholder()}
										/>
									) : (
										<FormFieldTextInput
											value=''
											disabled={true}
											name={item.getName()}
											onChange={dummyFormFieldOnChange}
											placeholder={item.getPlaceholder()}
										/>
									)}
								</FormFieldGroup>
							))}
							<ContainerCenter>
								<Button
									label='Save Details'
									disabled={true}
									variant='primary'
									onClick={dummyButtonClick}
								/>
							</ContainerCenter>
						</Card>
					</Box>
				</ContainerProfileCategoryBody>
				<ContainerProfileCategoryFooter>
					<Button
						variant='primary'
						disabled={!isFieldSequenceUpdated}
						label='Save System Fields Sequence'
						onClick={updateFormFieldSequences}
						isLoading={issFormFieldSequenceUpdating}
					/>
				</ContainerProfileCategoryFooter>
				<CreateFormFieldModal profileTemplateDto={profileTemplateDto} />
				<UpdateFormFieldModal profileTemplateDto={profileTemplateDto} />
			</>
		);
	}
};

export default ProfileTemplateBuilderContainer;
