import Datetime from '../datatypes/Datetime';
import ForeignKey from '../datatypes/ForeignKey';
import PartialPortalUserDto from '../dtos/PartialPortalUserDto';
import TouchpointTemplateStatus from './TouchpointTemplateStatus';
import TouchpointTemplatePreview from './TouchpointTemplatePreview';

export default class TouchpointTemplateDto {
	private id: string;
	private name: string;
	private portal: ForeignKey;
	private created_time: Datetime;
	private creator: PartialPortalUserDto;
	private status: TouchpointTemplateStatus;
	private preview: TouchpointTemplatePreview;

	constructor() {
		this.id = '';
		this.name = '';
		this.portal = new ForeignKey();
		this.created_time = new Datetime();
		this.creator = new PartialPortalUserDto();
		this.preview = new TouchpointTemplatePreview();
		this.status = TouchpointTemplateStatus.Drafted;
	}

	public getId(): string {
		return this.id;
	}

	public setId(id: string): void {
		this.id = id;
	}

	public getName(): string {
		return this.name;
	}

	public setName(name: string): void {
		this.name = name;
	}

	public getPortal(): ForeignKey {
		return this.portal;
	}

	public setPortal(portal: ForeignKey): void {
		this.portal = portal;
	}

	public getCreated_time(): Datetime {
		return this.created_time;
	}

	public setCreated_time(created_time: Datetime): void {
		this.created_time = created_time;
	}

	public getCreator(): PartialPortalUserDto {
		return this.creator;
	}

	public setCreator(creator: PartialPortalUserDto): void {
		this.creator = creator;
	}

	public getStatus(): TouchpointTemplateStatus {
		return this.status;
	}

	public setStatus(status: TouchpointTemplateStatus): void {
		this.status = status;
	}

	public getPreview(): TouchpointTemplatePreview {
		return this.preview;
	}

	public setPreview(preview: TouchpointTemplatePreview): void {
		this.preview = preview;
	}

	public loadFromJSON(json: Record<string, unknown>): void {
		this.id = json.id as string;
		this.name = json.name as string;
		this.created_time.setValue(json.created_time as string);
		this.portal.loadFromJSON(json.portal as Record<string, unknown>);
		this.creator.loadFromJSON(json.creator as Record<string, unknown>);
		this.status = TouchpointTemplateStatus.getValue(json.status as string);
		this.preview.loadFromJSON(
			json.preview as Record<string, Record<string, unknown>>
		);
		// Mapping the download path for preview
		this.preview
			.getFront()
			.setDownloadPath(
				'/portals/' +
					this.portal.getId() +
					'/touchpoint-templates/' +
					this.id +
					'/assets'
			);
		this.preview
			.getBack()
			.setDownloadPath(
				'/portals/' +
					this.portal.getId() +
					'/touchpoint-templates/' +
					this.id +
					'/assets'
			);
	}
}
