import SocialUrls from '../datatypes/SocialUrls';
import PhoneNumber from '../datatypes/PhoneNumber';
import DataModel from '../abstract-classes/DataModel';

export default class UserInformationDataModel extends DataModel {
	private role: string;
	private about: string;
	private user_id: string;
	private company: string;
	private portal_id: string;
	private email_id: string;
	private last_name: string;
	private first_name: string;
	private image: File | null;
	private profile_template: string;
	private social_urls: SocialUrls;
	private phone_number: PhoneNumber;

	constructor() {
		super();

		this.role = '';
		this.about = '';
		this.company = '';
		this.user_id = '';
		this.email_id = '';
		this.portal_id = '';
		this.last_name = '';
		this.first_name = '';
		this.profile_template = '';

		this.image = null;
		this.social_urls = new SocialUrls();
		this.phone_number = new PhoneNumber();
	}

	public getRole(): string {
		return this.role;
	}

	public setRole(role: string): void {
		this.role = role;
	}

	public getAbout(): string {
		return this.about;
	}

	public setAbout(about: string): void {
		this.about = about;
	}

	public getCompany(): string {
		return this.company;
	}

	public setCompany(company: string): void {
		this.company = company;
	}

	public getUser_id(): string {
		return this.user_id;
	}

	public setUser_id(user_id: string): void {
		this.user_id = user_id;
	}

	public getPortal_id(): string {
		return this.portal_id;
	}

	public setPortal_id(portal_id: string): void {
		this.portal_id = portal_id;
	}

	public getEmail_id(): string {
		return this.email_id;
	}

	public setEmail_id(email_id: string): void {
		this.email_id = email_id;
	}

	public getLast_name(): string {
		return this.last_name;
	}

	public setLast_name(last_name: string): void {
		this.last_name = last_name;
	}

	public getFirst_name(): string {
		return this.first_name;
	}

	public setFirst_name(first_name: string): void {
		this.first_name = first_name;
	}

	public getProfile_template(): string {
		return this.profile_template;
	}

	public setProfile_template(profile_template: string): void {
		this.profile_template = profile_template;
	}

	public getImage(): File | null {
		return this.image;
	}

	public setImage(image: File | null): void {
		this.image = image;
	}

	public getSocial_urls(): SocialUrls {
		return this.social_urls;
	}

	public setSocial_urls(social_urls: SocialUrls): void {
		this.social_urls = social_urls;
	}

	public getPhone_number(): PhoneNumber {
		return this.phone_number;
	}

	public setPhone_number(phone_number: PhoneNumber): void {
		this.phone_number = phone_number;
	}

	public getCreatePayload(): Record<string, unknown> {
		throw new Error('Method not implemented.');
	}

	public getUpdatePayload(): FormData {
		const formData = new FormData();

		if (this.role) {
			formData.append('role', this.role);
		}
		if (this.about) {
			formData.append('about', this.about);
		}
		if (this.company) {
			formData.append('company', this.company);
		}
		if (this.email_id) {
			formData.append('email_id', this.email_id);
		}
		if (this.last_name) {
			formData.append('last_name', this.last_name);
		}
		if (this.first_name) {
			formData.append('first_name', this.first_name);
		}
		if (this.profile_template) {
			formData.append('profile_template', this.profile_template);
		}

		if (this.image) {
			formData.append('image', this.image);
		}
		if (!this.social_urls.isEmpty()) {
			formData.append('social_urls', this.social_urls.toString());
		}
		if (!this.phone_number.isEmpty()) {
			formData.append('phone_number', this.phone_number.toString());
		}

		return formData;
	}
}
