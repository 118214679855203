
export default class PortalUserConfirmationStatus {
	public static readonly Pending = new PortalUserConfirmationStatus(
		'pending',
		'Pending'
	);
	public static readonly Accepted = new PortalUserConfirmationStatus(
		'accepted',
		'Accepted'
	);

	private value: string;
	private mappedValue: string;

	constructor(value: string, mappedValue: string) {
		this.value = value;
		this.mappedValue = mappedValue;
	}

	public getName(): string {
		return this.mappedValue;
	}

	public getValue(): string {
		return this.value;
	}

	public getMappedValue(): string {
		return this.mappedValue;
	}
	

	public static getValue(value: string): PortalUserConfirmationStatus {
		if (value === this.Accepted.value || value === this.Accepted.mappedValue) {
			return this.Accepted;
		} else {
			return this.Pending;
		}
	}

	public static getAllValues(): Array<PortalUserConfirmationStatus> {
		return [this.Pending, this.Accepted];
	}
}
