import { JSONSchemaType } from 'ajv';
import { useParams } from 'react-router-dom';
import { CountryData } from 'react-phone-input-2';
import { useCallback, useContext, useEffect, useState } from 'react';

import View from '../../components/View';
import Card from '../../components/Card';
import Form from '../../components/Form';
import Loader from '../../components/loader';
import Button from '../../components/Button';
import Typography from '../../components/Typography';
import ContainerFlex from '../../components/ContainerFlex';
import FormFieldError from '../../components/FormFieldError';
import FormFieldLabel from '../../components/FormFieldLabel';
import FormFieldGroup from '../../components/FormFieldGroup';
import ContainerCenter from '../../components/ContainerCenter';
import FormInputSubGroup from '../../components/FormFieldSubGroup';
import FormFieldTextInput from '../../components/FormFieldTextInput';
import FormFieldImageInput from '../../components/FormFieldImageInput';
import FormFieldPhoneInput from '../../components/FormFieldPhoneInput';
import FormFieldTextareaInput from '../../components/FormFieldTextareaInput';

import useForm from '../../hooks/useForm';
import ToastUtil from '../../utils/ToastUtil';
import CommonUtil from '../../utils/CommonUtil';
import APIRequestUtil from '../../utils/APIRequestUtil';
import AjvInstance from '../../instances/AjvInstance';
import GlobalContext from '../../context/GlobalContext';
import RequestMethodEnum from '../../enums/RequestMethodEnum';
import RequestHandler from '../../handlers/RequestHandler';
import UserInformationPojo from '../../pojos/UserInformationPojo';
import ErrorMessageConstants from '../../constants/ErrorMessageConstants';

import { UserInformationType } from '../../types/data';
type FormValues = Omit<
	UserInformationType,
	'id' | 'user' | 'image' | 'template_id' | 'status' | 'created_time'
> & { image: File | null };

const initialValues: FormValues = {
	role: '',
	about: '',
	company: '',
	email_id: '',
	first_name: '',
	image: null,
	last_name: '',
	phone_number: {
		country: '',
		dial_code: '',
		format: '',
		value: ''
	},
	social_urls: {
		twitter_url: '',
		facebook_url: '',
		linkedIn_url: ''
	}
};

const MappedErrors: Record<string, { name: string; displayMessage: string }> = {
	[CommonUtil.format(ErrorMessageConstants.Ajv.Common.NonEmpty, 'first_name')]:
		{
			name: 'first_name',
			displayMessage: 'First name cannot be empty.'
		},
	[CommonUtil.format(ErrorMessageConstants.Ajv.Common.NonEmpty, 'last_name')]: {
		name: 'last_name',
		displayMessage: 'Last name cannot be empty'
	},
	[CommonUtil.format(ErrorMessageConstants.Ajv.Common.NonEmpty, 'email_id')]: {
		name: 'email_id',
		displayMessage: 'Email address cannot be empty.'
	},
	'Invalid value for phone_number.': {
		name: 'phone_number',
		displayMessage: 'Invalid value for phone number'
	},
	[CommonUtil.format(
		ErrorMessageConstants.Ajv.Common.InvalidValue,
		'email_id',
		'email address'
	)]: {
		name: 'email_id',
		displayMessage: 'Invalid value for email address.'
	},
	[CommonUtil.format(ErrorMessageConstants.Ajv.Common.NonEmpty, 'company')]: {
		name: 'company',
		displayMessage: 'Company cannot be empty.'
	},
	[CommonUtil.format(ErrorMessageConstants.Ajv.Common.NonEmpty, 'role')]: {
		name: 'role',
		displayMessage: 'Role cannot be empty.'
	},
	[CommonUtil.format(ErrorMessageConstants.Ajv.Common.NonEmpty, 'about')]: {
		name: 'about',
		displayMessage: 'About yourself cannot be empty'
	},
	[CommonUtil.format(
		ErrorMessageConstants.Ajv.Common.NonEmpty,
		'facebook_url'
	)]: {
		name: 'social_urls.facebook_url',
		displayMessage: 'Facebook url cannot be empty.'
	},
	[CommonUtil.format(
		ErrorMessageConstants.Ajv.Common.InvalidValue,
		'facebook_url',
		'url'
	)]: {
		name: 'social_urls.facebook_url',
		displayMessage: 'Invalid value for facebook url.'
	},
	[CommonUtil.format(ErrorMessageConstants.Ajv.Common.NonEmpty, 'twitter_url')]:
		{
			name: 'social_urls.twitter_url',
			displayMessage: 'Twitter url cannot be empty.'
		},
	[CommonUtil.format(
		ErrorMessageConstants.Ajv.Common.InvalidValue,
		'twitter_url',
		'url'
	)]: {
		name: 'social_urls.twitter_url',
		displayMessage: 'Invalid value for twitter url.'
	},
	[CommonUtil.format(
		ErrorMessageConstants.Ajv.Common.NonEmpty,
		'linkedIn_url'
	)]: {
		name: 'social_urls.linkedIn_url',
		displayMessage: 'LinkedIn url cannot be empty.'
	},
	[CommonUtil.format(
		ErrorMessageConstants.Ajv.Common.InvalidValue,
		'linkedIn_url',
		'url'
	)]: {
		name: 'social_urls.linkedIn_url',
		displayMessage: 'Invalid value for linkedIn url.'
	}
};

const FormValueSchema: JSONSchemaType<Omit<FormValues, 'image'>> = {
	type: 'object',
	properties: {
		first_name: {
			type: 'string',
			minLength: 1,
			errorMessage: {
				minLength: CommonUtil.format(
					ErrorMessageConstants.Ajv.Common.NonEmpty,
					'first_name'
				)
			}
		},
		last_name: {
			type: 'string',
			minLength: 1,
			errorMessage: {
				minLength: CommonUtil.format(
					ErrorMessageConstants.Ajv.Common.NonEmpty,
					'last_name'
				)
			}
		},
		email_id: {
			type: 'string',
			format: 'email',
			errorMessage: {
				format: CommonUtil.format(
					ErrorMessageConstants.Ajv.Common.InvalidValue,
					'email_id',
					'email address'
				)
			}
		},
		phone_number: {
			type: 'object',
			properties: {
				value: {
					type: 'string',
					pattern: '^[1-9]{1}[0-9]{3,14}$',
					errorMessage: {
						pattern: 'Invalid value for phone_number.'
					}
				},
				format: {
					type: 'string',
					minLength: 1,
					errorMessage: {
						minLength: CommonUtil.format(
							ErrorMessageConstants.Ajv.Common.NonEmpty,
							'format'
						)
					}
				},
				country: {
					type: 'string',
					minLength: 1,
					errorMessage: {
						minLength: CommonUtil.format(
							ErrorMessageConstants.Ajv.Common.NonEmpty,
							'country'
						)
					}
				},
				dial_code: {
					type: 'string',
					minLength: 1,
					errorMessage: {
						minLength: CommonUtil.format(
							ErrorMessageConstants.Ajv.Common.NonEmpty,
							'dial_code'
						)
					}
				}
			},
			required: ['country', 'format', 'value', 'dial_code'],
			errorMessage: {
				type: CommonUtil.format(
					ErrorMessageConstants.Ajv.Common.InvalidValue,
					'phone_number',
					'object'
				),
				required: {
					country: CommonUtil.format(
						ErrorMessageConstants.Ajv.Common.NonEmpty,
						'country'
					),
					format: CommonUtil.format(
						ErrorMessageConstants.Ajv.Common.NonEmpty,
						'format'
					),
					value: CommonUtil.format(
						ErrorMessageConstants.Ajv.Common.NonEmpty,
						'value'
					),
					dial_code: CommonUtil.format(
						ErrorMessageConstants.Ajv.Common.NonEmpty,
						'dial_code'
					)
				}
			}
		},
		company: {
			type: 'string',
			minLength: 1,
			errorMessage: {
				minLength: CommonUtil.format(
					ErrorMessageConstants.Ajv.Common.NonEmpty,
					'company'
				)
			}
		},
		role: {
			type: 'string',
			minLength: 1,
			errorMessage: {
				minLength: CommonUtil.format(
					ErrorMessageConstants.Ajv.Common.NonEmpty,
					'role'
				)
			}
		},

		about: {
			type: 'string',
			minLength: 1,
			errorMessage: {
				minLength: CommonUtil.format(
					ErrorMessageConstants.Ajv.Common.NonEmpty,
					'about'
				)
			}
		},
		social_urls: {
			type: 'object',
			properties: {
				facebook_url: {
					type: 'string',
					format: 'url',
					errorMessage: {
						format: CommonUtil.format(
							ErrorMessageConstants.Ajv.Common.InvalidValue,
							'facebook_url',
							'url'
						)
					}
				},
				twitter_url: {
					type: 'string',
					format: 'url',
					errorMessage: {
						format: CommonUtil.format(
							ErrorMessageConstants.Ajv.Common.InvalidValue,
							'twitter_url',
							'url'
						)
					}
				},

				linkedIn_url: {
					type: 'string',
					format: 'url',
					errorMessage: {
						format: CommonUtil.format(
							ErrorMessageConstants.Ajv.Common.InvalidValue,
							'linkedIn_url',
							'url'
						)
					}
				}
			},
			required: []
		}
	},
	required: [
		'role',
		'about',
		'company',
		'email_id',
		'last_name',
		'first_name',
		'phone_number'
	],
	errorMessage: {
		required: {
			first_name: CommonUtil.format(
				ErrorMessageConstants.Ajv.Common.NonEmpty,
				'first_name'
			),
			last_name: CommonUtil.format(
				ErrorMessageConstants.Ajv.Common.NonEmpty,
				'last_name'
			),
			email_id: CommonUtil.format(
				ErrorMessageConstants.Ajv.Common.NonEmpty,
				'email_id'
			),
			role: CommonUtil.format(
				ErrorMessageConstants.Ajv.Common.NonEmpty,
				'role'
			),
			about: CommonUtil.format(
				ErrorMessageConstants.Ajv.Common.NonEmpty,
				'about'
			),
			company: CommonUtil.format(
				ErrorMessageConstants.Ajv.Common.NonEmpty,
				'company'
			),
			phone_number: CommonUtil.format(
				ErrorMessageConstants.Ajv.Common.NonEmpty,
				'phone_number'
			)
		}
	}
};
const UpdateUserInformationPage: React.FunctionComponent<{
	cbToSkipUpdate?: () => void;
}> = ({ cbToSkipUpdate }) => {
	const { portal_id } = useParams();
	const { currentUser } = useContext(GlobalContext);
	const { errors, values, setError, setValue, clearErrors, setMultipleValues } =
		useForm<FormValues>({
			initialValues
		});
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isProcessing, setIsProcessing] = useState(false);

	useEffect(() => {
		setIsLoading(true);

		const apiRequestHandler = new RequestHandler();
		apiRequestHandler.setMethod(RequestMethodEnum.Get);
		apiRequestHandler.setUrl(
			`/api/portals/${portal_id}/user-informations/${currentUser.getId()}`
		);
		apiRequestHandler
			.execute()
			.then((response) => {
				const userInformationPojo = new UserInformationPojo();
				userInformationPojo.loadFromJson(
					(response.getData() as Record<string, unknown>).data as Record<
						string,
						unknown
					>
				);

				let dummyImage: null | File;
				if (userInformationPojo.getImage().getId()) {
					dummyImage = new File(
						[''],
						userInformationPojo.getImage().getName(),
						{
							type: userInformationPojo.getImage().getMimetype()
						}
					);
				} else {
					dummyImage = null;
				}

				setMultipleValues({
					image: dummyImage,
					role: userInformationPojo.getRole(),
					about: userInformationPojo.getAbout(),
					company: userInformationPojo.getCompany(),
					email_id: userInformationPojo.getEmail_id(),
					last_name: userInformationPojo.getLast_name(),
					first_name: userInformationPojo.getFirst_name(),
					social_urls: {
						facebook_url: userInformationPojo
							.getSocial_urls()
							.getFacebook_url(),
						linkedIn_url: userInformationPojo
							.getSocial_urls()
							.getLinkedIn_url(),
						twitter_url: userInformationPojo.getSocial_urls().getTwitter_url()
					},
					phone_number: {
						value: userInformationPojo.getPhone_number().getValue(),
						format: userInformationPojo.getPhone_number().getFormat(),
						country: userInformationPojo.getPhone_number().getCountry(),
						dial_code: userInformationPojo.getPhone_number().getDial_code()
					}
				});
				setIsLoading(false);
			})
			.catch(APIRequestUtil.handleRequestFailure);
	}, [cbToSkipUpdate, currentUser, portal_id, setMultipleValues, setValue]);

	const saveUserInformation = useCallback(() => {
		clearErrors();
		const social_urls = {
			...(values.social_urls.facebook_url
				? { facebook_url: values.social_urls.facebook_url }
				: {}),
			...(values.social_urls.linkedIn_url
				? { linkedIn_url: values.social_urls.linkedIn_url }
				: {}),
			...(values.social_urls.twitter_url
				? { twitter_url: values.social_urls.twitter_url }
				: {})
		};
		const compliedSchema = AjvInstance.compile(FormValueSchema);

		const isInvalidData = compliedSchema({
			social_urls,
			role: values.role,
			about: values.about,
			company: values.company,
			email_id: values.email_id,
			last_name: values.last_name,
			first_name: values.first_name,
			phone_number: values.phone_number
		});

		if (
			!isInvalidData &&
			compliedSchema.errors &&
			compliedSchema.errors.length
		) {
			const { message } = compliedSchema.errors[0];

			if (message) {
				const mappedError = MappedErrors[message];
				if (mappedError) {
					setError(mappedError.name, mappedError.displayMessage);
				}
				return;
			}
		}

		if (!values.image) {
			setError('image', 'Image cannot be empty.');
			return;
		}

		const formData = new FormData();
		for (const [key, value] of Object.entries(values)) {
			if (['phone_number'].includes(key)) {
				formData.append(key, JSON.stringify(value));
			} else if (key === 'image') {
				if ((value as File).size) {
					formData.append(key, value as File);
				}
			} else if (key !== 'social_urls') {
				formData.append(key, value as string);
			}
		}

		if (
			social_urls.twitter_url ||
			social_urls.facebook_url ||
			social_urls.linkedIn_url
		) {
			formData.append('social_urls', JSON.stringify(social_urls));
		}

		setIsProcessing(true);
		const apiRequestHandler = new RequestHandler();
		apiRequestHandler.setBody(formData);
		apiRequestHandler.setMethod(RequestMethodEnum.Put);
		apiRequestHandler.setUrl(
			`/api/portals/${portal_id}/user-informations/${currentUser.getId()}`
		);
		apiRequestHandler
			.execute()
			.then(() =>
				ToastUtil.makeSuccessToast(
					'Your information has been updated successfully.'
				)
			)
			.catch((err) => {
				APIRequestUtil.getErrorMessage(err).then((message) => {
					const mappedMessage = MappedErrors[message];

					if (mappedMessage) {
						setError(mappedMessage.name, mappedMessage.displayMessage);
					} else {
						ToastUtil.makeFailureToast(message);
					}
				});
			})
			.finally(() => {
				setIsProcessing(false);
			});
	}, [values, setError, portal_id, clearErrors, currentUser]);

	const onImageChange = useCallback(
		(name: string, file: File) => {
			setError(name, '');
			setValue(name, file);
		},
		[setError, setValue]
	);

	const onTextInputChange = useCallback(
		(key: string, value: string) => {
			setError(key, '');
			setValue(key, value);
		},
		[setError, setValue]
	);
	const onPhoneInputChange = useCallback(
		(name: string, value: string, countryData: CountryData) => {
			setValue(`${name}.format`, countryData.format);
			setValue(`${name}.dial_code`, countryData.dialCode);
			setValue(`${name}.country`, countryData.countryCode);
			setValue(`${name}.value`, value);
			setError(`${name}`, '');
		},
		[setError, setValue]
	);

	if (isLoading) {
		return (
			<ContainerCenter>
				<Loader />
			</ContainerCenter>
		);
	} else {
		return (
			<View>
				<Card className='card--update-information'>
					<Typography
						text='Update Your Information'
						variant='h6'
						fontSize={22}
						fontWeight={600}
						className='mb-20'
					/>
					<Form>
						<FormFieldGroup variant='multiple'>
							<FormInputSubGroup>
								<FormFieldLabel label='First Name' htmlFor='first_name' />
								<FormFieldTextInput
									id='first_name'
									name='first_name'
									value={values.first_name}
									error={errors.first_name}
									onChange={onTextInputChange}
									placeholder='Enter your first name'
								/>
								<FormFieldError error={errors.first_name} />
							</FormInputSubGroup>
							<FormInputSubGroup>
								<FormFieldLabel label='Last Name' htmlFor='last_name' />
								<FormFieldTextInput
									id='last_name'
									name='last_name'
									value={values.last_name}
									error={errors.last_name}
									onChange={onTextInputChange}
									placeholder='Enter your last name'
								/>
								<FormFieldError error={errors.last_name} />
							</FormInputSubGroup>
						</FormFieldGroup>
						<FormFieldGroup variant='multiple'>
							<FormInputSubGroup>
								<FormFieldLabel label='Email Address' htmlFor='email_id' />
								<FormFieldTextInput
									id='email_id'
									name='email_id'
									value={values.email_id}
									error={errors.email_id}
									onChange={onTextInputChange}
									placeholder='Enter your email address'
								/>
								<FormFieldError error={errors.email_id} />
							</FormInputSubGroup>
							<FormInputSubGroup>
								<FormFieldLabel label='Phone Number' htmlFor='phone_number' />
								<FormFieldPhoneInput
									name='phone_number'
									onChange={onPhoneInputChange}
									value={values.phone_number.value}
									placholder='Enter your phone number'
									country={values.phone_number.country || 'in'}
								/>
								<FormFieldError error={errors.phone_number} />
							</FormInputSubGroup>
						</FormFieldGroup>
						<FormFieldGroup variant='multiple'>
							<FormInputSubGroup>
								<FormFieldLabel label='Company' htmlFor='company' />
								<FormFieldTextInput
									id='company'
									name='company'
									value={values.company}
									error={errors.company}
									onChange={onTextInputChange}
									placeholder='Enter your company'
								/>
								<FormFieldError error={errors.company} />
							</FormInputSubGroup>
							<FormInputSubGroup>
								<FormFieldLabel label='Role' htmlFor='role' />
								<FormFieldTextInput
									id='role'
									name='role'
									value={values.role}
									error={errors.role}
									onChange={onTextInputChange}
									placeholder='Enter your role'
								/>
								<FormFieldError error={errors.role} />
							</FormInputSubGroup>
						</FormFieldGroup>

						<FormFieldGroup>
							<FormFieldLabel label='About yourself' htmlFor='about' />
							<FormFieldTextareaInput
								id='about'
								name='about'
								value={values.about}
								error={errors.about}
								onChange={onTextInputChange}
								placeholder='Enter a short description about yourself'
							/>
							<FormFieldError error={errors.about} />
						</FormFieldGroup>

						<FormFieldGroup>
							<FormFieldLabel
								label='Facebook Url'
								htmlFor='social_urls.facebook_url'
							/>
							<FormFieldTextInput
								id='social_urls.facebook_url'
								name='social_urls.facebook_url'
								value={values.social_urls.facebook_url}
								error={errors.social_urls?.facebook_url}
								onChange={onTextInputChange}
								placeholder='Enter your facebook url'
							/>
							<FormFieldError error={errors.social_urls?.facebook_url} />
						</FormFieldGroup>
						<FormFieldGroup>
							<FormFieldLabel
								label='Twitter Url'
								htmlFor='social_urls.twitter_url'
							/>
							<FormFieldTextInput
								id='social_urls.twitter_url'
								name='social_urls.twitter_url'
								value={values.social_urls.twitter_url}
								error={errors.social_urls?.twitter_url}
								onChange={onTextInputChange}
								placeholder='Enter your twitter url'
							/>
							<FormFieldError error={errors.social_urls?.twitter_url} />
						</FormFieldGroup>
						<FormFieldGroup>
							<FormFieldLabel
								label='LinkedIn Url'
								htmlFor='social_urls.linkedIn_url'
							/>
							<FormFieldTextInput
								id='social_urls.linkedIn_url'
								name='social_urls.linkedIn_url'
								value={values.social_urls.linkedIn_url}
								error={errors.social_urls?.linkedIn_url}
								onChange={onTextInputChange}
								placeholder='Enter your linkedIn url'
							/>
							<FormFieldError error={errors.social_urls?.linkedIn_url} />
						</FormFieldGroup>
						<FormFieldGroup>
							<FormFieldLabel label='Image' htmlFor='image' />
							<FormFieldImageInput
								name='image'
								value={values.image}
								onChange={onImageChange}
								placholder='Upload your profile image'
							/>
							<FormFieldError error={errors.image} />
						</FormFieldGroup>
						<ContainerFlex justifyContent='end'>
							<Button
								type='button'
								label='Save'
								variant='primary'
								onClick={saveUserInformation}
								isLoading={isProcessing}
							/>
						</ContainerFlex>
					</Form>
				</Card>
			</View>
		);
	}
};

export default UpdateUserInformationPage;
