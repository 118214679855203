import { useMemo } from 'react';
import TouchpointTemplateNode from './TouchpointTemplateNode';
import TouchpointTemplateImageNodeProperties from './TouchpointTemplateImageNodeProperties';

type TouchpointTemplateContainerNodeProps = {
	touchpointTemplateNode: TouchpointTemplateNode;
};

export default function TouchpointTemplateImageNode({
	touchpointTemplateNode
}: TouchpointTemplateContainerNodeProps) {
	const properties = useMemo(() => {
		const properties = new TouchpointTemplateImageNodeProperties();
		properties.loadFromJSON(
			touchpointTemplateNode.getProperties() as Record<string, string>
		);
		return properties;
	}, [touchpointTemplateNode]);
	return (
		<img
			alt='asset'
			draggable='false'
			style={touchpointTemplateNode.getStyles().toJSON()}
			src={properties.getSrc()}
		/>
	);
}
