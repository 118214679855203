import { nanoid } from 'nanoid';

import RequestHandler from '../handlers/RequestHandler';
import RequestMethodEnum from '../enums/RequestMethodEnum';
import ProfileTemplateDto from './ProfileTemplateDto';
import ProfileTemplateNode from './ProfileTemplateNode';
import ProfileTemplateDataModel from './ProfileTemplateDataModel';
import ProfileTemplateCategory from './ProfileTemplateCategory';
import JsonToProfileTemplateDtoMapper from '../mappers/JsonToProfileTemplatePojoMapper';

namespace ProfileTemplateUtilParams {
	type CommonParams = {
		controller?: AbortController;
	};
	export type GetPagedProfileTemplatesWithPortal = {
		page: number;
		perPage: number;
		portal_id: string;
		category?: string;
	} & CommonParams;

	export type GetProfileTemplateNodesWithPortal = {
		portal_id: string;
		profile_template_id: string;
	} & CommonParams;

	export type GetProfileTemplateWithPortal = {
		portal_id: string;
		profile_template_id: string;
	} & CommonParams;

	export type CreateProfileTemplate = {
		profileTemplateDataModel: ProfileTemplateDataModel;
	} & CommonParams;
}

export default class ProfileTemplateUtil {
	public static jsonToProfileTemplateNode(
		json: Record<string, unknown>
	): ProfileTemplateNode {
		const profileTemplateNodePojo = new ProfileTemplateNode();
		profileTemplateNodePojo.loadFromJson(json);
		profileTemplateNodePojo.setDummy_id(nanoid(20));
		return profileTemplateNodePojo;
	}

	public static constructProfileTemplateNodesFromJson(
		json: Array<Record<string, unknown>>
	): Array<ProfileTemplateNode> {
		return json.map(({ nodes, ...others }) => {
			const profileTemplateNode = this.jsonToProfileTemplateNode(others);

			if (nodes && Array.isArray(nodes)) {
				profileTemplateNode.setNodes(
					this.constructProfileTemplateNodesFromJson(
						nodes as Array<Record<string, unknown>>
					)
				);
			}
			return profileTemplateNode;
		});
	}

	public static async getPagedProfileTemplatesWithPortal(
		params: ProfileTemplateUtilParams.GetPagedProfileTemplatesWithPortal
	): Promise<Array<ProfileTemplateDto>> {
		const { page, perPage, portal_id, category, controller } = params;

		const requestHandler = new RequestHandler();

		if (controller) {
			requestHandler.setAbortController(controller);
		}

		requestHandler.setMethod(RequestMethodEnum.Get);
		requestHandler.setUrl(`/api/portals/${portal_id}/profile-templates`);
		requestHandler.addParam('page', page);
		requestHandler.addParam('perPage', perPage);

		if (category) {
			requestHandler.addParam(
				'category',
				ProfileTemplateCategory.getValue(category).getValue()
			);
		}

		return requestHandler
			.execute()
			.then((response) =>
				(
					(response.getData() as Record<string, unknown>).data as Array<
						Record<string, unknown>
					>
				).map(JsonToProfileTemplateDtoMapper.map)
			);
	}

	public static async getProfileTemplateWithPortal(
		params: ProfileTemplateUtilParams.GetProfileTemplateWithPortal
	): Promise<ProfileTemplateDto> {
		const { portal_id, profile_template_id, controller } = params;

		const requestHandler = new RequestHandler();

		if (controller) {
			requestHandler.setAbortController(controller);
		}

		requestHandler.setMethod(RequestMethodEnum.Get);
		requestHandler.setUrl(
			`/api/portals/${portal_id}/profile-templates/${profile_template_id}`
		);

		return requestHandler
			.execute()
			.then((response) =>
				JsonToProfileTemplateDtoMapper.map(
					(response.getData() as Record<string, unknown>).data as Record<
						string,
						unknown
					>
				)
			);
	}
	public static async getProfileTemplateNodesWithPortal(
		params: ProfileTemplateUtilParams.GetProfileTemplateNodesWithPortal
	): Promise<Array<ProfileTemplateNode>> {
		const { portal_id, profile_template_id, controller } = params;

		const requestHandler = new RequestHandler();

		if (controller) {
			requestHandler.setAbortController(controller);
		}

		requestHandler.setMethod(RequestMethodEnum.Get);
		requestHandler.setUrl(
			`/api/portals/${portal_id}/profile-templates/${profile_template_id}/nodes`
		);

		return requestHandler
			.execute()
			.then((response) =>
				this.constructProfileTemplateNodesFromJson(
					(response.getData() as Record<string, Array<Record<string, unknown>>>)
						.data
				)
			);
	}

	public static async createProfileTemplate(
		params: ProfileTemplateUtilParams.CreateProfileTemplate
	): Promise<ProfileTemplateDto> {
		const { controller, profileTemplateDataModel } = params;

		const requestHandler = new RequestHandler();

		if (controller) {
			requestHandler.setAbortController(controller);
		}

		requestHandler.setMethod(RequestMethodEnum.Post);
		requestHandler.setUrl(
			`/api/portals/${profileTemplateDataModel.getPortal_id()}/profile-templates`
		);
		requestHandler.setBody(profileTemplateDataModel.getCreatePayload());

		return requestHandler
			.execute()
			.then((response) =>
				JsonToProfileTemplateDtoMapper.map(
					(response.getData() as Record<string, unknown>).data as Record<
						string,
						unknown
					>
				)
			);
	}
}
