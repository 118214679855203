import React, { useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '../../components/Box';
import Card from '../../components/Card';
import View from '../../components/View';
import Badge from '../../components/Badge';
import PlusIcon from '../../icons/PlusIcon';
import Loader from '../../components/loader';
import ViewTitle from '../../components/ViewTitle';
import CreatePortalModal from './CreatePortalModal';
import Typography from '../../components/Typography';
import ContainerCenter from '../../components/ContainerCenter';
import LoaderLoadMoreRecords from '../../components/LoaderLoadMoreRecords';

import PortalPojo from '../../pojos/PortalPojo';
import useOnScroll from '../../hooks/useOnScroll';
import useCRUDMapper from '../../hooks/useCRUDMapper';
import { EventEmitterInstance } from '../../instances';
import EventNamesConstants from '../../constants/EventNamesConstants';
import JsonToPortalPojoMapper from '../../mappers/JsonToPortalPojoMapper';

const PortalCard: React.FC<{ portalPojo: PortalPojo }> = ({ portalPojo }) => {
	const navigate = useRef(useNavigate());

	const openPortal = useCallback(() => {
		navigate.current(`/portals/${portalPojo.getId()}`);
	}, [portalPojo]);

	return (
		<Card className='card--portal' onClick={openPortal}>
			<Box className='card--portal__header'>
				<Box className='card--portal__properties'>
					{portalPojo.isIs_default() && (
						<Badge text='Default' variant='green' />
					)}
					<Badge text='Team' variant='orange' />
				</Box>
			</Box>
			<Box className='card--portal__body'>
				<Typography
					variant='h6'
					text={portalPojo.getName()}
					className='card--portal__title'
				/>
			</Box>
			<Box className='card--portal__footer'>
				<Typography
					variant='p'
					text={portalPojo.getCreator().getFirst_name()}
				/>
				<Typography
					variant='p'
					text={portalPojo.getCreated_time().toDateString()}
				/>
			</Box>
		</Card>
	);
};

const PortalsPage: React.FC = () => {
	const { data, isLoading, loadMoreRecords, isLoadingNextPage } =
		useCRUDMapper<PortalPojo>({
			mapper: JsonToPortalPojoMapper.map,
			url: '/portals'
		});

	const { onScrollToBottom } = useOnScroll({
		cb: loadMoreRecords
	});

	const createPortal = useCallback(() => {
		EventEmitterInstance.emit(EventNamesConstants.Portals.Modal.Create);
	}, []);

	if (isLoading) {
		return (
			<ContainerCenter>
				<Loader />
			</ContainerCenter>
		);
	} else {
		return (
			<View onScroll={onScrollToBottom}>
				<Box className='portals__bg' />
				<Box className='container--portals'>
					<ViewTitle className='title--portals' text='My Portals' />
					<Box className='container--portals__cards'>
						<Card
							className='card--portal card--portal--create'
							onClick={createPortal}>
							<Box className='card--portal--create__container'>
								<PlusIcon className='card--portal--create__icon' />
							</Box>
						</Card>

						{data.map((item) => (
							<PortalCard portalPojo={item} key={item.getId()} />
						))}
					</Box>
					{isLoadingNextPage && <LoaderLoadMoreRecords />}
				</Box>
				<CreatePortalModal />
			</View>
		);
	}
};

export default PortalsPage;
