import Datetime from '../datatypes/Datetime';
import ForeignKey from '../datatypes/ForeignKey';
import TouchpointStatus from './TouchpointStatus';
import TouchpointAuditStatus from './TouchpointAuditStatus';
import PartialPortalUserDto from '../dtos/PartialPortalUserDto';

export default class TouchpointDto {
	private id: string;
	private portal: ForeignKey;
	private created_time: Datetime;
	private status: TouchpointStatus;
	private back_view_file_id: string;
	private front_view_file_id: string;
	private user: PartialPortalUserDto;
	private touchpoint_template: ForeignKey;
	private audit_status: TouchpointAuditStatus;

	constructor() {
		this.id = '';
		this.back_view_file_id = '';
		this.front_view_file_id = '';
		this.portal = new ForeignKey();
		this.created_time = new Datetime();
		this.user = new PartialPortalUserDto();
		this.status = TouchpointStatus.Inactive;
		this.touchpoint_template = new ForeignKey();
		this.audit_status = TouchpointAuditStatus.Pending;
	}

	public getId(): string {
		return this.id;
	}

	public setId(id: string): void {
		this.id = id;
	}

	public getPortal(): ForeignKey {
		return this.portal;
	}

	public setPortal(portal: ForeignKey): void {
		this.portal = portal;
	}

	public getCreated_time(): Datetime {
		return this.created_time;
	}

	public setCreated_time(created_time: Datetime): void {
		this.created_time = created_time;
	}

	public getStatus(): TouchpointStatus {
		return this.status;
	}

	public setStatus(status: TouchpointStatus): void {
		this.status = status;
	}

	public getBack_view_file_id(): string {
		return this.back_view_file_id;
	}

	public setBack_view_file_id(back_view_file_id: string): void {
		this.back_view_file_id = back_view_file_id;
	}

	public getFront_view_file_id(): string {
		return this.front_view_file_id;
	}

	public setFront_view_file_id(front_view_file_id: string): void {
		this.front_view_file_id = front_view_file_id;
	}

	public getUser(): PartialPortalUserDto {
		return this.user;
	}

	public setUser(user: PartialPortalUserDto): void {
		this.user = user;
	}

	public getTouchpoint_template(): ForeignKey {
		return this.touchpoint_template;
	}

	public setTouchpoint_template(touchpoint_template: ForeignKey): void {
		this.touchpoint_template = touchpoint_template;
	}

	public getAudit_status(): TouchpointAuditStatus {
		return this.audit_status;
	}

	public setAudit_status(audit_status: TouchpointAuditStatus): void {
		this.audit_status = audit_status;
	}

	public loadFromJSON(json: Record<string, unknown>): void {
		this.id = json.id as string;
		this.back_view_file_id = json.back_view_file_id as string;
		this.front_view_file_id = json.front_view_file_id as string;
		this.status = TouchpointStatus.getValue(json.status as string);
		this.audit_status = TouchpointAuditStatus.getValue(
			json.audit_status as string
		);
		this.created_time.setValue(json.created_time as string);
		this.user.loadFromJSON(json.user as Record<string, unknown>);
		this.portal.loadFromJSON(json.portal as Record<string, unknown>);
		this.touchpoint_template.loadFromJSON(
			json.touchpoint_template as Record<string, unknown>
		);
	}
}
