import Styles from './table.module.css';

import LinkIcon from '../icons/LinkIcon';

import { TableCellAction as TableCellActionProps } from '../types/components';

const TableCellActionLink: React.FunctionComponent<TableCellActionProps> = ({
	onClick
}) => {
	return <LinkIcon onClick={onClick} className={Styles['table-cell-icon']} />;
};

export default TableCellActionLink;
