import { useMemo } from 'react';

import ProfileTemplateNode from './ProfileTemplateNode';
import UserInformationDto from '../dtos/UserInformationDto';
import UserInformationReplacer from '../replacers/UserInformationReplacer';
import ProfileTemplateNodeCategoryEnum from '../enums/ProfileTemplateNodeCategoryEnum';
import ProfileTemplateLinkNodeTypeEnum from '../enums/ProfileTemplateLinkNodeTypeEnum';

export type ProfileTemplateLinkNodeProps = {
	disabled?: boolean;
	userInformationPojo: UserInformationDto;
	profileTemplateNode: ProfileTemplateNode;
};

const ProfileTemplateLinkNode: React.FC<ProfileTemplateLinkNodeProps> = ({
	disabled,
	children,
	userInformationPojo,
	profileTemplateNode
}) => {
	const label = useMemo<string>(
		() => profileTemplateNode.getProperties().getLabel() as string,
		[profileTemplateNode]
	);
	const url = useMemo<string>(() => {
		const prefix =
			(profileTemplateNode.getProperties().getType() as string) ===
			ProfileTemplateLinkNodeTypeEnum.Mail
				? 'mailto:'
				: '';

		if (
			profileTemplateNode.getCategory() ===
			ProfileTemplateNodeCategoryEnum.Dynamic
		) {
			return (
				prefix +
				UserInformationReplacer.getInstance(userInformationPojo).replace(
					profileTemplateNode.getProperties().getValue() as string
				)
			);
		} else {
			return (prefix +
				profileTemplateNode.getProperties().getValue()) as string;
		}
	}, [userInformationPojo, profileTemplateNode]);

	return (
		<a href={url} target='_blank' rel='noreferrer'>
			{profileTemplateNode.getNodes() ? children : label}
		</a>
	);
};

export default ProfileTemplateLinkNode;
