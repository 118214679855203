import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import Box from '../../components/Box';
import Card from '../../components/Card';
import View from '../../components/View';
import Badge from '../../components/Badge';
import Image from '../../components/Image';
import PlusIcon from '../../icons/PlusIcon';
import Loader from '../../components/loader';
import InviteUserModal from './InviteUserModal';
import ViewTitle from '../../components/ViewTitle';
import Typography from '../../components/Typography';
import ViewHeader from '../../components/ViewHeader';
import ContainerCenter from '../../components/ContainerCenter';
import LoaderLoadMoreRecords from '../../components/LoaderLoadMoreRecords';

import DummyUser from '../../images/dummy-user.png';

import useOnScroll from '../../hooks/useOnScroll';
import useCRUDMapper from '../../hooks/useCRUDMapper';
import { EventEmitterInstance } from '../../instances';
import PortalUserPojo from '../../pojos/PortalUserPojo';
import PortalUserRole from '../../portal-users/PortalUserRole';
import EventNamesConstants from '../../constants/EventNamesConstants';
import JsonToPortalUserPojoMapper from '../../mappers/JsonToPortalUserPojoMapper';
import PortalUserConfirmationStatus from '../../portal-users/PortalUserConfirmationStatus';

const PortalUserCard: React.FC<{ portalUser: PortalUserPojo }> = ({
	portalUser
}) => {
	return (
		<Card className='card--user'>
			<Box className='card--user__header'>
				<Box className='card--user__properties'>
					<Badge
						text={portalUser.getConfirmation_status().getMappedValue()}
						variant={
							portalUser.getConfirmation_status() ===
							PortalUserConfirmationStatus.Accepted
								? 'blue'
								: 'grey'
						}
					/>
				</Box>
				<Image src={DummyUser} className='card--user__image' />
			</Box>
			<Box className='card--user__body'>
				<Badge
					text={portalUser.getRole().getMappedValue()}
					variant={
						portalUser.getRole() === PortalUserRole.Admin
							? 'green'
							: 'orange'
					}
				/>
				<Typography
					variant='h6'
					fontSize={15}
					fontWeight={500}
					text={portalUser.getFull_name()}
				/>
				<Typography variant='p' fontSize={14} text={portalUser.getEmail_id()} />
			</Box>
			<Box className='card--user__footer'>
				<Typography
					variant='p'
					text={portalUser.getCreated_time().toDateString()}
				/>
			</Box>
		</Card>
	);
};

const UsersPage: React.FC = () => {
	const { portal_id } = useParams();
	const { data, isLoading, loadMoreRecords, isLoadingNextPage } =
		useCRUDMapper<PortalUserPojo>({
			mapper: JsonToPortalUserPojoMapper.map,
			url: `/portals/${portal_id}/users`
		});

	const { onScrollToBottom } = useOnScroll({
		cb: loadMoreRecords
	});

	const inviteUsers = useCallback(() => {
		EventEmitterInstance.emit(EventNamesConstants.PortalUsers.Modal.Invite);
	}, []);

	if (isLoading) {
		return (
			<ContainerCenter>
				<Loader />
			</ContainerCenter>
		);
	} else {
		return (
			<View onScroll={onScrollToBottom}>
				<ViewHeader>
					<ViewTitle text='Users' />
				</ViewHeader>
				<Box className='container--users__cards'>
					<Card className='card--user card--user--invite' onClick={inviteUsers}>
						<Box className='card--user--invite__container'>
							<PlusIcon className='card--user--invite__icon' />
						</Box>
					</Card>
					{data.map((item) => (
						<PortalUserCard portalUser={item} key={item.getId()} />
					))}
				</Box>
				{isLoadingNextPage && <LoaderLoadMoreRecords />}
				<InviteUserModal />
			</View>
		);
	}
};

export default UsersPage;
