import TouchpointStyles from './touchpoint.module.css';

import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';

import Box from '../components/Box';
import Typography from '../components/Typography';
import TouchpointBuilder from './TouchpointBuilder';

import TouchpointUtil from './TouchpointUtil';
import { EventEmitterInstance } from '../instances';
import TouchpointTemplateNode from './TouchpointTemplateNode';
import TouchpointTemplateNodes from './TouchpointTemplateNodes';
import EventNamesConstants from '../constants/EventNamesConstants';
import { clone } from 'lodash';

function TouchpointPreviewCard({
	initialTouchpointNodes
}: {
	initialTouchpointNodes: TouchpointTemplateNodes;
}) {
	const [showFrontFace, setShowFrontFace] = useState(true);
	const [touchpointNodes, setTouchpointNodes] =
		useState<TouchpointTemplateNodes>(new TouchpointTemplateNodes());
	const toggleShowFrontView = useCallback(() => {
		setShowFrontFace((c) => !c);
	}, []);

	useEffect(() => {
		const updateTouchpointNodesEventListener = (
			touchpointNode: TouchpointTemplateNode
		) => {
			setTouchpointNodes((prev) => {
				const touchpointNodes = new TouchpointTemplateNodes();

				touchpointNodes.setFront(
					TouchpointUtil.updateTouchpointNode(
						Array.from(prev.getFront()),
						touchpointNode
					)
				);

				touchpointNodes.setBack(
					TouchpointUtil.updateTouchpointNode(
						Array.from(prev.getBack()),
						touchpointNode
					)
				);

				return touchpointNodes;
			});
		};

		setShowFrontFace(true);
		setTouchpointNodes(clone(initialTouchpointNodes));

		const subscription = EventEmitterInstance.addListener(
			EventNamesConstants.TouchpointTemplateNode.Data.Update,
			updateTouchpointNodesEventListener
		);

		return () => {
			subscription.remove();
		};
	}, [initialTouchpointNodes]);

	return (
		<>
			<ul className={TouchpointStyles['nav-links']}>
				<li
					className={classNames(
						TouchpointStyles['nav-link'],
						showFrontFace && TouchpointStyles['nav-link--active']
					)}
					onClick={toggleShowFrontView}>
					Front
				</li>
				<li
					className={classNames(
						TouchpointStyles['nav-link'],
						!showFrontFace && TouchpointStyles['nav-link--active']
					)}
					onClick={toggleShowFrontView}>
					Back
				</li>
			</ul>
			<Box className={TouchpointStyles['preview-card']}>
				<TouchpointBuilder
					nodes={
						showFrontFace
							? touchpointNodes.getFront()
							: touchpointNodes.getBack()
					}
				/>
			</Box>
		</>
	);
}

export default function TouchpointPreview({
	touchpointNodes
}: {
	touchpointNodes: TouchpointTemplateNodes;
}) {
	return (
		<Box className={TouchpointStyles['preview-container']}>
			<Box className={TouchpointStyles['preview-container__header']}>
				<Typography
					text='Preview'
					variant='h6'
					className={TouchpointStyles['preview-container__title']}
				/>
			</Box>
			<Box className={TouchpointStyles['preview-container__body']}>
				<TouchpointPreviewCard initialTouchpointNodes={touchpointNodes} />
			</Box>
		</Box>
	);
}
