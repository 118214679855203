import { MappedValue } from '../datatypes';

export default class PortalConstants {
	static readonly Types = {
		Team: new MappedValue('team', 'Team'),
		Personal: new MappedValue('personal', 'Personal')
	};

	static readonly Roles = {
		User: new MappedValue('user', 'User'),
		Admin: new MappedValue('admin', 'Admin')
	};

	static readonly ConfirmationStatus = {
		Pending: new MappedValue('pending', 'Pending'),
		Accepted: new MappedValue('accepted', 'Accepted')
	};

	static readonly getTypeByValue = (value: string): MappedValue => {
		for (const type of Object.values(this.Types)) {
			if (type.getValue() === value) {
				return type;
			}
		}
		return this.Types.Personal;
	};

	static readonly getTypeByMappedValue = (value: string): MappedValue => {
		for (const type of Object.values(this.Types)) {
			if (type.getMappedValue() === value) {
				return type;
			}
		}
		return this.Types.Personal;
	};

	static readonly getRoleByValue = (value: string): MappedValue => {
		for (const role of Object.values(this.Roles)) {
			if (role.getValue() === value) {
				return role;
			}
		}
		return this.Roles.User;
	};

	static readonly getRoleByMappedValue = (value: string): MappedValue => {
		for (const role of Object.values(this.Roles)) {
			if (role.getMappedValue() === value) {
				return role;
			}
		}
		return this.Roles.User;
	};

	static readonly getConfirmationStatusByValue = (
		value: string
	): MappedValue => {
		for (const status of Object.values(this.ConfirmationStatus)) {
			if (status.getValue() === value) {
				return status;
			}
		}
		return this.ConfirmationStatus.Pending;
	};

	static readonly getConfirmationStatusByMappedValue = (
		value: string
	): MappedValue => {
		for (const status of Object.values(this.ConfirmationStatus)) {
			if (status.getMappedValue() === value) {
				return status;
			}
		}
		return this.ConfirmationStatus.Pending;
	};
}
