import SelectOption from '../abstract-classes/SelectOption';

export default class Enum extends SelectOption {
	protected value: string;
	protected mappedValue: string;

	constructor(value: string, mappedValue: string) {
		super();

		this.value = value;
		this.mappedValue = mappedValue;
	}

	public getId(): string {
		return '';
	}

	public getSelectOptionLabel(): string {
		return this.mappedValue;
	}

	public getSelectOptionValue(): string {
		return this.value;
	}

	public getMappedValue(): string {
		return this.mappedValue;
	}

	public toJSON(): string {
		return this.mappedValue;
	}

	public toString(): string {
		return this.mappedValue;
	}
}
