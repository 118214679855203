import FileMeta from '../datatypes/FileMeta';

export default class TouchpointTemplatePreview {
	private back: FileMeta;
	private front: FileMeta;

	constructor() {
		this.back = new FileMeta();
		this.front = new FileMeta();
	}

	public getBack(): FileMeta {
		return this.back;
	}

	public setBack(back: FileMeta): void {
		this.back = back;
	}

	public getFront(): FileMeta {
		return this.front;
	}

	public setFront(front: FileMeta): void {
		this.front = front;
	}

	public loadFromJSON(json: Record<string, Record<string, unknown>>) {
		this.back.loadFromJSON(json.back);
		this.front.loadFromJSON(json.front);
	}

	public toJSON(): Record<string, unknown> {
		return {
			back: this.back.toJSON(),
			front: this.front.toJSON()
		};
	}

	public toString(): string {
		return JSON.stringify(this.toJSON());
	}
}
