import TouchpointTemplateNode from './TouchpointTemplateNode';
import JSONToTouchpointTemplateNodeMapper from './JSONToTouchpointTemplateNodeMapper';

export default class TouchpointTemplateNodes {
	private back: Array<TouchpointTemplateNode>;
	private front: Array<TouchpointTemplateNode>;

	constructor() {
		this.back = [];
		this.front = [];
	}

	public getBack(): Array<TouchpointTemplateNode> {
		return this.back;
	}

	public setBack(back: Array<TouchpointTemplateNode>): void {
		this.back = back;
	}

	public getFront(): Array<TouchpointTemplateNode> {
		return this.front;
	}

	public setFront(front: Array<TouchpointTemplateNode>): void {
		this.front = front;
	}

	public loadFromJSON(json: Record<string, Array<Record<string, unknown>>>) {
		this.back = JSONToTouchpointTemplateNodeMapper.map(json.back);
		this.front = JSONToTouchpointTemplateNodeMapper.map(json.front);
	}

	public toJSON(): Record<string, unknown> {
		return {
			back: this.back,
			front: this.front
		};
	}

	public toString(): string {
		return JSON.stringify(this.toJSON());
	}
}
