export default class TouchpointTemplateInputNodeProperties {
	private type: string;
	private value: string;
	private label: string;
	private required: boolean;
	private placeholder: string;
	private defaultValue: string;

	constructor() {
		this.value = '';
		this.type = '';
		this.label = '';
		this.required = false;
		this.placeholder = '';
		this.defaultValue = '';
	}

	public getType(): string {
		return this.type;
	}

	public setType(type: string): void {
		this.type = type;
	}

	public getValue(): string {
		return this.value;
	}

	public setValue(value: string): void {
		this.value = value;
	}

	public getLabel(): string {
		return this.label;
	}

	public setLabel(label: string): void {
		this.label = label;
	}

	public isRequired(): boolean {
		return this.required;
	}

	public setRequired(required: boolean): void {
		this.required = required;
	}

	public getPlaceholder(): string {
		return this.placeholder;
	}

	public setPlaceholder(placeholder: string): void {
		this.placeholder = placeholder;
	}

	public getDefaultValue(): string {
		return this.defaultValue;
	}

	public setDefaultValue(defaultValue: string): void {
		this.defaultValue = defaultValue;
	}

	public loadFromJSON(json: Record<string, unknown>) {
		this.type = json.type as string;
		this.value = json.value as string;
		this.label = json.label as string;
		this.required = json.required as boolean;
		this.placeholder = json.placeholder as string;
		this.defaultValue = json.defaultValue as string;
	}

	public toJSON(): Record<string, unknown> {
		return JSON.parse(
			JSON.stringify({
				type: this.type,
				value: this.value,
				label: this.label,
				required: this.required,
				placeholder: this.placeholder,
				defaultValue: this.defaultValue
			})
		);
	}
}
