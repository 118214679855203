import Util from '../base-classes/Util';
import FormFieldDto from '../dtos/FormFieldDto';
import RequestMethodEnum from '../enums/RequestMethodEnum';
import JsonToFormFieldDtoJsonMapper from '../mappers/JsonToFormFieldDtoJsonMapper';
import FormFieldDataModel from '../model/FormFieldDataModel';

export default class FormFieldUtil extends Util {
	private form_id: string;
	private portal_id: string;

	constructor(
		portal_id: string,
		form_id: string,
		controller?: AbortController
	) {
		super(controller);

		this.form_id = form_id;
		this.portal_id = portal_id;
	}
	public async getFormFields(): Promise<Array<FormFieldDto>> {
		const apiRequestHandler = this.buildAPIRequestHandler();

		apiRequestHandler.setMethod(RequestMethodEnum.Get);
		apiRequestHandler.setUrl(
			`/api/portals/${this.portal_id}/forms/${this.form_id}/fields`
		);

		return apiRequestHandler
			.execute()
			.then((response) =>
				((response.getData() as Record<string, unknown>).data as Array<Record<string, unknown>>).map(
					JsonToFormFieldDtoJsonMapper.map
				)
			);
	}

	public async getFormField(form_field_id: string): Promise<FormFieldDto> {
		const apiRequestHandler = this.buildAPIRequestHandler();

		apiRequestHandler.setMethod(RequestMethodEnum.Get);
		apiRequestHandler.setUrl(
			`/api/portals/${this.portal_id}/forms/${this.form_id}/fields/${form_field_id}`
		);

		return apiRequestHandler
			.execute()
			.then((response) =>
				JsonToFormFieldDtoJsonMapper.map(
					(response.getData() as Record<string, unknown>).data as Record<string, unknown>
				)
			);
	}

	public async createFormField(
		formFieldDataModel: FormFieldDataModel
	): Promise<FormFieldDto> {
		const apiRequestHandler = this.buildAPIRequestHandler();

		apiRequestHandler.setMethod(RequestMethodEnum.Post);
		apiRequestHandler.setUrl(
			`/api/portals/${this.portal_id}/forms/${this.form_id}/fields`
		);
		apiRequestHandler.setBody(formFieldDataModel.getCreatePayload());

		return apiRequestHandler
			.execute()
			.then((response) =>
				JsonToFormFieldDtoJsonMapper.map(
					(response.getData() as Record<string, unknown>).data as Record<string, unknown>
				)
			);
	}

	public async updateFormField(
		formFieldDataModel: FormFieldDataModel
	): Promise<FormFieldDto> {
		const apiRequestHandler = this.buildAPIRequestHandler();

		apiRequestHandler.setMethod(RequestMethodEnum.Put);
		apiRequestHandler.setUrl(
			`/api/portals/${this.portal_id}/forms/${
				this.form_id
			}/fields/${formFieldDataModel.getId()}`
		);
		apiRequestHandler.setBody(formFieldDataModel.getUpdatePayload());

		return apiRequestHandler
			.execute()
			.then((response) =>
				JsonToFormFieldDtoJsonMapper.map(
					(response.getData() as Record<string, unknown>).data as Record<string, unknown>
				)
			);
	}

	public async updateFormFieldSequences(
		formFieldDataModels: Array<FormFieldDataModel>
	): Promise<Array<FormFieldDto>> {
		const apiRequestHandler = this.buildAPIRequestHandler();

		apiRequestHandler.setMethod(RequestMethodEnum.Put);
		apiRequestHandler.setUrl(
			`/api/portals/${this.portal_id}/forms/${this.form_id}/fields`
		);
		apiRequestHandler.setBody({
			fields: formFieldDataModels.map((item) =>
				item.getUpdateFormFieldSequencePayload()
			)
		});

		return apiRequestHandler
			.execute()
			.then((response) =>
				((response.getData() as Record<string, unknown>).data as Array<Record<string, unknown>>).map(
					JsonToFormFieldDtoJsonMapper.map
				)
			);
	}
	public static getInstance(
		portal_id: string,
		form_id: string,
		controller?: AbortController
	): FormFieldUtil {
		return new FormFieldUtil(portal_id, form_id, controller);
	}
}
