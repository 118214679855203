import { useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';

import Loader from '../../components/loader';
import ContainerCenter from '../../components/ContainerCenter';
import ViewProfilePicker from '../../components/ViewProfilePicker';
import PortalUserProfileTemplateContainer from './PortalUserProfileTemplateContainer';
import PortalAdminProfileTemplateContainer from './PortalAdminProfileTemplateContainer';

import { EventEmitterInstance } from '../../instances';
import APIRequestUtil from '../../utils/APIRequestUtil';
import ProfileTemplateDto from '../../profile-templates/ProfileTemplateDto';
import PortalUserRole from '../../portal-users/PortalUserRole';
import ProfileTemplateUtil from '../../profile-templates/ProfileTemplateUtil';
import EventNamesConstants from '../../constants/EventNamesConstants';
import ProfileTemplateCategory from '../../profile-templates/ProfileTemplateCategory';

import { ProfilePickerLayoutContext } from '../../types/context';

const ProfileLeadGenPage: React.FC = () => {
	const { portal_id } = useParams() as Record<string, string>;

	const [isLoading, setIsLoading] = useState(true);
	const [profileTemplateDto, setProfileTemplateDto] =
		useState<ProfileTemplateDto | null>(null);

	const { portalUserPojo } = useOutletContext<ProfilePickerLayoutContext>();

	useEffect(() => {
		setIsLoading(true);

		ProfileTemplateUtil.getPagedProfileTemplatesWithPortal({
			page: 1,
			perPage: 1,
			portal_id,
			category: ProfileTemplateCategory.LeadGen.getValue()
		})
			.then((records) => {
				setProfileTemplateDto(records[0]);
				setIsLoading(false);
			})
			.catch(APIRequestUtil.handleRequestFailure);

		const subscription = EventEmitterInstance.addListener(
			EventNamesConstants.ProfileTemplates.Data.Update,
			setProfileTemplateDto
		);

		return () => {
			subscription.remove();
		};
	}, [portal_id, portalUserPojo]);

	return (
		<ViewProfilePicker>
			{isLoading ? (
				<ContainerCenter>
					<Loader />
				</ContainerCenter>
			) : portalUserPojo.getRole() === PortalUserRole.Admin ? (
				<PortalAdminProfileTemplateContainer
					profileTemplateDto={profileTemplateDto}
				/>
			) : (
				<PortalUserProfileTemplateContainer
					profileTemplateDto={profileTemplateDto}
				/>
			)}
		</ViewProfilePicker>
	);
};

export default ProfileLeadGenPage;
