import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import Form from '../../components/Form';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import ModalBody from '../../components/ModalBody';
import ModalTitle from '../../components/ModalTitle';
import ModalFooter from '../../components/ModalFooter';
import ModalHeader from '../../components/ModalHeader';
import ModalCloseIcon from '../../components/ModalCloseIcon';
import FormFieldLabel from '../../components/FormFieldLabel';
import FormFieldGroup from '../../components/FormFieldGroup';
import FormFieldSelectInput from '../../components/FormFieldSelectInput';

import useForm from '../../hooks/useForm';
import useModal from '../../hooks/useModal';
import FormUtil from '../../utils/FormUtil';
import FormFieldDto from '../../dtos/FormFieldDto';
import FormTypeEnum from '../../enums/FormTypeEnum';
import FormDataModel from '../../model/FormDataModel';
import FormFieldUtil from '../../utils/FormFieldUtil';
import { EventEmitterInstance } from '../../instances';
import APIRequestUtil from '../../utils/APIRequestUtil';
import ConnectedAppUtil from '../../utils/ConnectedAppUtil';
import FormFieldError from '../../components/FormFieldError';
import FormFieldDataModel from '../../model/FormFieldDataModel';
import LeadStorageTypeEnum from '../../enums/LeadStorageTypeEnum';
import ProfileTemplateUtil from '../../profile-templates/ProfileTemplateUtil';
import ExternalAppField from '../../external-app/ExternalAppField';
import FormFieldConstants from '../../constants/FormFieldConstants';
import ExternalAppModule from '../../external-app/ExternalAppModule';
import EventNamesConstants from '../../constants/EventNamesConstants';
import LeadStorageLocationEnum from '../../enums/LeadStorageLocationEnum';
import ProfileTemplateType from '../../profile-templates/ProfileTemplateType';
import ProfileTemplateDataModel from '../../profile-templates/ProfileTemplateDataModel';
import ProfileTemplateCategory from '../../profile-templates/ProfileTemplateCategory';

import { ConfigureTemplateForm } from '../../types/forms';

const initialValues: ConfigureTemplateForm.FormValues = {
	external_app_module: null,
	external_app_field_last_name: null,
	external_app_field_first_name: null,
	external_app_field_email_address: null
};

const ConfigureTemplateModal: React.FC = () => {
	const { portal_id } = useParams() as Record<string, string>;

	const controller = useRef<AbortController>();

	const { open, openModal, closeModal } = useModal();
	const { values, errors, setValue, setError, clearErrors, setMultipleValues } =
		useForm({
			initialValues
		});

	const [isLoading, setIsLoading] = useState(true);
	const [isProcessing, setIsProcessing] = useState(false);
	const [externalAppModules, setExternalAppModules] = useState<
		Array<ExternalAppModule>
	>([]);

	const [externalAppFields, setExternalAppFields] = useState<
		Array<ExternalAppField>
	>([]);

	const [isExternalAppFieldsLoading, setIsExternalAppFieldsLoading] =
		useState(false);

	useEffect(() => {
		const openModalEventListener = () => {
			openModal();
			setIsLoading(true);
			setMultipleValues(initialValues);

			ConnectedAppUtil.getInstance(portal_id)
				.getConnectedAppModules(
					LeadStorageLocationEnum.ZohoCrm.getSelectOptionValue()
				)
				.then((records) => {
					setIsLoading(false);
					setExternalAppModules(records);
				})
				.catch(APIRequestUtil.handleRequestFailure);
		};
		const subscription = EventEmitterInstance.addListener(
			EventNamesConstants.ProfileTemplates.Modal.Create,
			openModalEventListener
		);

		return () => {
			subscription.remove();

			if (controller.current) {
				controller.current.abort();
			}
		};
	}, [portal_id, openModal, setMultipleValues]);

	const optionsForEmailAddressSelect = useMemo<Array<ExternalAppField>>(() => {
		const firstNameId = values.external_app_field_first_name?.getId();
		const lastNameId = values.external_app_field_last_name?.getId();
		return externalAppFields.filter((obj) => {
			if (firstNameId && lastNameId) {
				return obj.getId() !== firstNameId && obj.getId() !== lastNameId;
			} else if (firstNameId) {
				return obj.getId() !== firstNameId;
			} else if (lastNameId) {
				return obj.getId() !== lastNameId;
			} else {
				return true;
			}
		});
	}, [
		externalAppFields,
		values.external_app_field_last_name,
		values.external_app_field_first_name
	]);

	const optionsForFirstNameSelect = useMemo<Array<ExternalAppField>>(() => {
		const lastNameId = values.external_app_field_last_name?.getId();
		const emaiId = values.external_app_field_email_address?.getId();
		return externalAppFields.filter((obj) => {
			if (lastNameId && emaiId) {
				return obj.getId() !== lastNameId && obj.getId() !== emaiId;
			} else if (lastNameId) {
				return obj.getId() !== lastNameId;
			} else if (emaiId) {
				return obj.getId() !== emaiId;
			} else {
				return true;
			}
		});
	}, [
		externalAppFields,
		values.external_app_field_last_name,
		values.external_app_field_email_address
	]);

	const optionsForLastNameSelect = useMemo<Array<ExternalAppField>>(() => {
		const emailId = values.external_app_field_email_address?.getId();
		const firstNameId = values.external_app_field_first_name?.getId();
		return externalAppFields.filter((obj) => {
			if (firstNameId && emailId) {
				return obj.getId() !== firstNameId && obj.getId() !== emailId;
			} else if (firstNameId) {
				return obj.getId() !== firstNameId;
			} else if (emailId) {
				return obj.getId() !== emailId;
			} else {
				return true;
			}
		});
	}, [
		externalAppFields,
		values.external_app_field_first_name,
		values.external_app_field_email_address
	]);

	const onExternalAppModuleSelectInputChange = useCallback(
		(name: string, value: ExternalAppModule) => {
			setMultipleValues({
				...initialValues,
				[name]: value
			});
			setError(name, '');

			if (controller.current) {
				controller.current.abort();
			}

			controller.current = new AbortController();

			setExternalAppFields([]);
			setIsExternalAppFieldsLoading(true);
			ConnectedAppUtil.getInstance(portal_id, controller.current)
				.getConnectedAppFields(
					LeadStorageLocationEnum.ZohoCrm.getSelectOptionValue(),
					value.getApi_name()
				)
				.then((values) => {
					setExternalAppFields(values);
					setIsExternalAppFieldsLoading(false);
				})
				.catch(APIRequestUtil.handleRequestFailure);
		},
		[portal_id, setError, setMultipleValues]
	);

	const onExternalAppFieldSelectInputChange = useCallback(
		(key: string, value: ExternalAppField) => {
			setValue(key, value);
			setError(key, '');
		},
		[setError, setValue]
	);

	const createTemplate = useCallback(async () => {
		clearErrors();

		if (!values.external_app_module) {
			setError('external_app_module', 'External app module cannot be empty.');
			return;
		}
		if (!values.external_app_field_first_name) {
			setError(
				'external_app_field_first_name',
				'External app field for first name cannot be empty.'
			);
			return;
		}
		if (!values.external_app_field_last_name) {
			setError(
				'external_app_field_last_name',
				'External app field for last name cannot be empty.'
			);
			return;
		}
		if (!values.external_app_field_email_address) {
			setError(
				'external_app_field_email_id',
				'External app field for email address cannot be empty.'
			);
			return;
		}
		try {
			setIsProcessing(true);

			const formDataModel = new FormDataModel();
			formDataModel.setName('Lead Gen Form');
			formDataModel.setType(FormTypeEnum.Profile);
			formDataModel.setStorage_location(LeadStorageLocationEnum.ZohoCrm);
			formDataModel.setStorage_type(
				LeadStorageTypeEnum.ApplicationAndExternalApp
			);
			formDataModel.setExternal_app_module(
				values.external_app_module.getApi_name()
			);

			const leadForm = await FormUtil.getInstance(
				portal_id as string
			).createForm(formDataModel);

			const formFields = await FormFieldUtil.getInstance(
				portal_id as string,
				leadForm.getId()
			).getFormFields();

			const promises: Array<Promise<FormFieldDto>> = [];

			for (const formField of formFields) {
				const formFieldDataModel = new FormFieldDataModel();
				formFieldDataModel.setId(formField.getId());
				formFieldDataModel.setLabel(formField.getLabel());
				formFieldDataModel.setRequired(formField.isRequired());
				formFieldDataModel.setPlaceholder(formField.getPlaceholder());

				if (formField.getName() === FormFieldConstants.SystemFields.FirstName) {
					formFieldDataModel.setExternal_app_field(
						values.external_app_field_first_name.getApi_name()
					);
				} else if (
					formField.getName() === FormFieldConstants.SystemFields.LastName
				) {
					formFieldDataModel.setExternal_app_field(
						values.external_app_field_last_name.getApi_name()
					);
				} else {
					formFieldDataModel.setExternal_app_field(
						values.external_app_field_email_address.getApi_name()
					);
				}

				promises.push(
					FormFieldUtil.getInstance(
						portal_id as string,
						leadForm.getId()
					).updateFormField(formFieldDataModel)
				);
			}

			await Promise.all(promises);

			const profileTemplateDataModel = new ProfileTemplateDataModel();
			profileTemplateDataModel.setName('Lead Gen');
			profileTemplateDataModel.setPortal_id(portal_id);
			profileTemplateDataModel.setForm(leadForm.getName());
			profileTemplateDataModel.setType(ProfileTemplateType.Form);
			profileTemplateDataModel.setCategory(ProfileTemplateCategory.LeadGen);

			const profileTemplateDto =
				await ProfileTemplateUtil.createProfileTemplate({
					profileTemplateDataModel
				});

			EventEmitterInstance.emit(
				EventNamesConstants.ProfileTemplates.Data.Update,
				profileTemplateDto
			);
			setIsProcessing(false);
			closeModal();
		} catch (error) {
			APIRequestUtil.handleRequestFailure(error);
		} finally {
			setIsProcessing(false);
		}
	}, [values, portal_id, setError, closeModal, clearErrors]);

	return (
		<Modal position='top' show={open}>
			<ModalHeader>
				<ModalTitle title='Configure Template' />
				<ModalCloseIcon closeModal={closeModal} />
			</ModalHeader>
			<ModalBody>
				<Form>
					<FormFieldGroup>
						<FormFieldLabel label='Zoho Crm Module' />
						<FormFieldSelectInput<ExternalAppModule>
							isLoading={isLoading}
							id='external_app_module'
							name='external_app_module'
							onChange={onExternalAppModuleSelectInputChange}
							value={values.external_app_module}
							placeholder='Select an external app module'
							options={externalAppModules}
						/>
						<FormFieldError error={errors.external_app_module} />
					</FormFieldGroup>
					<FormFieldGroup>
						<FormFieldLabel
							label='Zoho Crm Field For First Name'
							htmlFor='external_app_field_first_name'
						/>

						<FormFieldSelectInput<ExternalAppField>
							isSearchable={false}
							id='external_app_field_first_name'
							name='external_app_field_first_name'
							isLoading={isExternalAppFieldsLoading}
							onChange={onExternalAppFieldSelectInputChange}
							value={values.external_app_field_first_name}
							placeholder={
								!values.external_app_module
									? 'Select an external app module'
									: 'Select an external app field'
							}
							options={optionsForFirstNameSelect}
						/>
						<FormFieldError error={errors.external_app_field_first_name} />
					</FormFieldGroup>
					<FormFieldGroup>
						<FormFieldLabel
							label='Zoho Crm Field For Last Name'
							htmlFor='external_app_field_last_name'
						/>

						<FormFieldSelectInput
							isSearchable={false}
							id='external_app_field_last_name'
							name='external_app_field_last_name'
							isLoading={isExternalAppFieldsLoading}
							onChange={onExternalAppFieldSelectInputChange}
							value={values.external_app_field_last_name}
							placeholder={
								!values.external_app_module
									? 'Select an external app module'
									: 'Select an external app field'
							}
							options={optionsForLastNameSelect}
						/>
						<FormFieldError error={errors.external_app_field_last_name} />
					</FormFieldGroup>
					<FormFieldGroup>
						<FormFieldLabel
							label='Zoho Crm Field For Email Address'
							htmlFor='external_app_field_email_address'
						/>

						<FormFieldSelectInput
							isSearchable={false}
							id='external_app_field_email_address'
							name='external_app_field_email_address'
							isLoading={isExternalAppFieldsLoading}
							onChange={onExternalAppFieldSelectInputChange}
							value={values.external_app_field_email_address}
							placeholder={
								!values.external_app_module
									? 'Select an external app module'
									: 'Select an external app field'
							}
							options={optionsForEmailAddressSelect}
						/>
						<FormFieldError error={errors.external_app_field_email_address} />
					</FormFieldGroup>
				</Form>
			</ModalBody>
			<ModalFooter>
				<Button variant='outlined' label='Cancel' onClick={closeModal} />
				<Button
					label='Create'
					variant='primary'
					onClick={createTemplate}
					isLoading={isProcessing}
				/>
			</ModalFooter>
		</Modal>
	);
};

export default ConfigureTemplateModal;
