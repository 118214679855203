import ProfileTemplateType from './ProfileTemplateType';
import ProfileTemplateCategory from './ProfileTemplateCategory';

export default class ProfileTemplateDataModel {
	private id: string;
	private name: string;
	private form: string;
	private portal_id: string;
	private type: ProfileTemplateType;
	private category: ProfileTemplateCategory;

	constructor() {
		this.id = '';
		this.name = '';
		this.form = '';
		this.portal_id = '';
		this.type = ProfileTemplateType.Form;
		this.category = ProfileTemplateCategory.Portfolio;
	}

	public getId(): string {
		return this.id;
	}

	public setId(id: string): void {
		this.id = id;
	}

	public getName(): string {
		return this.name;
	}

	public setName(name: string): void {
		this.name = name;
	}

	public getForm(): string {
		return this.form;
	}

	public setForm(form: string): void {
		this.form = form;
	}

	public getPortal_id(): string {
		return this.portal_id;
	}

	public setPortal_id(portal_id: string): void {
		this.portal_id = portal_id;
	}

	public getType(): ProfileTemplateType {
		return this.type;
	}

	public setType(type: ProfileTemplateType): void {
		this.type = type;
	}

	public getCategory(): ProfileTemplateCategory {
		return this.category;
	}

	public setCategory(category: ProfileTemplateCategory): void {
		this.category = category;
	}

	public getCreatePayload(): Record<string, unknown> {
		return {
			name: this.name,
			form: this.form,
			type: this.type.getValue(),
			category: this.category.getValue()
		};
	}
}
