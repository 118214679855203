import React from 'react';
import { UserPojo } from '../pojos';

const GlobalContext = React.createContext<{
	currentUser: UserPojo;
}>({
	currentUser: new UserPojo()
});

export default GlobalContext;
