export default class TouchpointStatus {
	public static readonly Active = new TouchpointStatus('active', 'Active');
	public static readonly Inactive = new TouchpointStatus(
		'inactive',
		'Inactive'
	);

	private value: string;
	private mappedValue: string;

	constructor(value: string, mappedValue: string) {
		this.value = value;
		this.mappedValue = mappedValue;
	}

	public getValue(): string {
		return this.value;
	}

	public getMappedValue(): string {
		return this.mappedValue;
	}

	public toJSON(): string {
		return this.mappedValue;
	}

	public toString(): string {
		return this.mappedValue;
	}

	public static readonly getValue = (value: string): TouchpointStatus => {
		if (value === this.Active.value || value === this.Active.mappedValue) {
			return this.Active;
		} else {
			return this.Inactive;
		}
	};

	public static readonly getAllValues = (): Array<TouchpointStatus> => {
		return [this.Active, this.Inactive];
	};
}
