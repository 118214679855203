import { Buffer } from 'buffer';

import PagedResult from '../common/PagedResult';
import RequestMethodEnum from '../enums/RequestMethodEnum';
import TouchpointTemplateDto from './TouchpointTemplateDto';
import RequestHandler from '../handlers/RequestHandler';
import TouchpointTemplateNodes from './TouchpointTemplateNodes';
import JSONToTouchpointTemplateMapper from './JSONToTouchpointTemplateDtoMapper';

namespace TouchpointTemplateUtilParams {
	type CommonParams = {
		controller?: AbortController;
	};
	export type GetPagedTouchpointTemplates = {} & CommonParams;
	export type GetTouchpointTemplateNodes = {} & CommonParams;
	export type GetTouchpointTemplateAsset = {} & CommonParams;
}

export default class TouchpointTemplateUtil {
	public static async getPagedTouchpointTemplatesWithPortal(
		page: number,
		perPage: number,
		portal_id: string,
		params: TouchpointTemplateUtilParams.GetPagedTouchpointTemplates = {}
	): Promise<PagedResult<TouchpointTemplateDto>> {
		const { controller } = params;

		const apiRequestHandler = new RequestHandler();

		if (controller) {
			apiRequestHandler.setAbortController(controller);
		}

		apiRequestHandler.setMethod(RequestMethodEnum.Get);
		apiRequestHandler.setUrl(`/api/portals/${portal_id}/touchpoint-templates`);
		apiRequestHandler.addParam('page', page);
		apiRequestHandler.addParam('perPage', perPage);

		return apiRequestHandler.execute().then((response) => {
			const { data, page } = response.getData() as Record<string, unknown>;
			const pagedResult = new PagedResult<TouchpointTemplateDto>();

			pagedResult.setData(
				(data as Array<Record<string, unknown>>).map(
					JSONToTouchpointTemplateMapper.map
				)
			);
			pagedResult.getPageInfo().loadFromJson(page as Record<string, unknown>);

			return pagedResult;
		});
	}

	public static async getTouchpointTemplateNodesWithPortal(
		portal_id: string,
		touchpoint_template_id: string,
		params: TouchpointTemplateUtilParams.GetTouchpointTemplateNodes = {}
	): Promise<TouchpointTemplateNodes> {
		const { controller } = params;

		const apiRequestHandler = new RequestHandler();

		if (controller) {
			apiRequestHandler.setAbortController(controller);
		}

		apiRequestHandler.setMethod(RequestMethodEnum.Get);
		apiRequestHandler.setUrl(
			`/api/portals/${portal_id}/touchpoint-templates/${touchpoint_template_id}/nodes`
		);

		return apiRequestHandler.execute().then((response) => {
			const { data } = response.getData() as Record<string, unknown>;

			const touchpointTemplateNodes = new TouchpointTemplateNodes();
			touchpointTemplateNodes.loadFromJSON(
				data as Record<string, Array<Record<string, unknown>>>
			);

			return touchpointTemplateNodes;
		});
	}

	public static async getTouchpointTemplateAssetAsDataURIWithPortal(
		portal_id: string,
		touchpoint_template_id: string,
		touchpoint_template_asset_id: string,
		params: TouchpointTemplateUtilParams.GetTouchpointTemplateAsset = {}
	): Promise<string> {
		const { controller } = params;

		const requestHandler = new RequestHandler();

		if (controller) {
			requestHandler.setAbortController(controller);
		}

		requestHandler.setMethod(RequestMethodEnum.Get);
		requestHandler.setUrl(
			`/files/portals/${portal_id}/touchpoint-templates/${touchpoint_template_id}/assets/${touchpoint_template_asset_id}`
		);
		requestHandler.addConfiguration('responseType', 'arraybuffer');

		return requestHandler.execute().then((response) => {
			const contentType = response.getHeaders()['content-type'] as string;
			return `data:${contentType};base64,${Buffer.from(
				response.getData() as ArrayBuffer
			).toString('base64')}`;
		});
	}
}
