import Pojo from '../abstract-classes/Pojo';
import ForeignKey from '../datatypes/ForeignKey';
import PortalUserRole from '../portal-users/PortalUserRole';
import PortalUserConfirmationStatus from '../portal-users/PortalUserConfirmationStatus';

export default class PortalUserPojo extends Pojo {
	private email_id: string;
	private last_name: string;
	private first_name: string;

	private portal: ForeignKey;

	private role: PortalUserRole;
	private confirmation_status: PortalUserConfirmationStatus;

	constructor() {
		super();

		this.email_id = '';
		this.last_name = '';
		this.first_name = '';

		this.role = PortalUserRole.User;
		this.confirmation_status = PortalUserConfirmationStatus.Pending;

		this.portal = new ForeignKey();
	}

	public getEmail_id(): string {
		return this.email_id;
	}

	public setEmail_id(email_id: string): void {
		this.email_id = email_id;
	}

	public getPortal(): ForeignKey {
		return this.portal;
	}

	public setPortal(portal_id: ForeignKey): void {
		this.portal = portal_id;
	}

	public getLast_name(): string {
		return this.last_name;
	}

	public setLast_name(last_name: string): void {
		this.last_name = last_name;
	}

	public getFirst_name(): string {
		return this.first_name;
	}

	public setFirst_name(first_name: string): void {
		this.first_name = first_name;
	}

	public getFull_name(): string {
		if (this.first_name) {
			return this.first_name + ' ' + this.last_name;
		} else {
			return 'NA';
		}
	}

	public getRole(): PortalUserRole {
		return this.role;
	}

	public setRole(role: PortalUserRole): void {
		this.role = role;
	}

	public getConfirmation_status(): PortalUserConfirmationStatus {
		return this.confirmation_status;
	}

	public setConfirmation_status(
		confirmation_status: PortalUserConfirmationStatus
	): void {
		this.confirmation_status = confirmation_status;
	}

	public loadFromJson(json: Record<string, unknown>) {
		this.id = json.id as string;
		this.email_id = json.email_id as string;
		this.last_name = json.last_name as string;
		this.first_name = json.first_name as string;

		this.role = PortalUserRole.getValue(json.role as string);
		this.confirmation_status = PortalUserConfirmationStatus.getValue(
			json.confirmation_status as string
		);

		this.created_time.setValue(json.created_time as string);
		this.portal.loadFromJSON(json.portal as Record<string, unknown>);
	}
}
