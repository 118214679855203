import { MappedValue } from '../datatypes';

export default class ExternalAppConstants {
	static readonly Apps = {
		ZohoCrm: new MappedValue('zoho-crm', 'Zoho Crm'),
		Application: new MappedValue('application', 'Application'),
	};

    static readonly getAppByValue = (value: string): MappedValue => {
		for (const app of Object.values(this.Apps)) {
			if (app.getValue() === value) {
				return app;
			}
		}
		return this.Apps.ZohoCrm;
	};

	static readonly getAppByMappedValue = (value: string): MappedValue => {
		for (const app of Object.values(this.Apps)) {
			if (app.getMappedValue() === value) {
				return app;
			}
		}
		return this.Apps.ZohoCrm;
	};

	public static readonly getAllApps = (): Array<MappedValue> => {
		return Object.values(this.Apps);
	};
}
