import classNames from 'classnames';
import { useContext, useEffect, useRef, useState } from 'react';
import { NavLink, Outlet, useNavigate, useParams } from 'react-router-dom';

import ProfileLogo from '../../images/profile.png';
import SettingsLogo from '../../images/setting.png';
import DirectoryLogo from '../../images/directory.png';

import Loader from '../../components/loader';

import { PreviewIcon } from '../../icons/cards';

import PortalPojo from '../../pojos/PortalPojo';
import PortalTypeEnum from '../../enums/PortalTypeEnum';
import APIRequestUtil from '../../utils/APIRequestUtil';
import PortalUserPojo from '../../pojos/PortalUserPojo';
import GlobalContext from '../../context/GlobalContext';
import RequestMethodEnum from '../../enums/RequestMethodEnum';
import PortalUserRole from '../../portal-users/PortalUserRole';
import RequestHandler from '../../handlers/RequestHandler';
import JsonToPortalPojoMapper from '../../mappers/JsonToPortalPojoMapper';
import JsonToPortalUserPojoMapper from '../../mappers/JsonToPortalUserPojoMapper';
import PortalUserConfirmationStatus from '../../portal-users/PortalUserConfirmationStatus';

import { PortalLayoutContext } from '../../types/context';

const PortalLayout: React.FunctionComponent = () => {
	const { portal_id } = useParams();

	const navigate = useRef(useNavigate());

	const { currentUser } = useContext(GlobalContext);

	const [portalPojo, setPortalPojo] = useState(new PortalPojo());
	const [portalUserPojo, setPortalUserPojo] = useState(new PortalUserPojo());

	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		setIsLoading(true);

		const handleRequestFailure = (err: any) => {
			APIRequestUtil.handleRequestFailure(err).then(() => {
				setTimeout(() => {
					navigate.current('/portals', {
						replace: true
					});
				}, 4000);
			});
		};

		const apiRequestHandler = new RequestHandler();
		apiRequestHandler.setMethod(RequestMethodEnum.Get);
		apiRequestHandler.setUrl(`/api/portals/${portal_id}`);

		apiRequestHandler
			.execute()
			.then((portalResponse) => {
				setPortalPojo(
					JsonToPortalPojoMapper.map(
						(portalResponse.getData() as Record<string, unknown>)
							.data as Record<string, unknown>
					)
				);

				apiRequestHandler.setMethod(RequestMethodEnum.Get);
				apiRequestHandler.setUrl(
					`/api/portals/${portal_id}/users/${currentUser.getId()}`
				);

				apiRequestHandler
					.execute()
					.then((portalUserResponse) => {
						const portalUserPojo = JsonToPortalUserPojoMapper.map(
							(portalUserResponse.getData() as Record<string, unknown>)
								.data as Record<string, unknown>
						);
						setPortalUserPojo(portalUserPojo);

						if (
							portalUserPojo.getConfirmation_status() ===
							PortalUserConfirmationStatus.Pending
						) {
							apiRequestHandler.setMethod(RequestMethodEnum.Patch);
							apiRequestHandler.setUrl(`/api/portals/${portal_id}/join`);
							apiRequestHandler
								.execute()
								.then(() => setIsLoading(false))
								.catch(handleRequestFailure);
						} else {
							setIsLoading(false);
						}
					})
					.catch(handleRequestFailure);
			})
			.catch(handleRequestFailure);
	}, [currentUser, portal_id]);

	if (isLoading) {
		return (
			<div className='container--center'>
				<Loader />
			</div>
		);
	} else {
		return (
			<div className='container__portal'>
				<div className='sidebar--primary'>
					<NavLink
						to='profile'
						className={({ isActive }) =>
							classNames(
								'navlink navlink--primary',
								isActive && 'navlink--primary--active'
							)
						}>
						<img
							src={ProfileLogo}
							alt='Profile'
							className='icon__navlink--primary'
						/>
						<p className='title__navlink--primary'>My Profile</p>
					</NavLink>
					<NavLink
						to='directory'
						className={({ isActive }) =>
							classNames(
								'navlink navlink--primary',
								isActive && 'navlink--primary--active'
							)
						}>
						<img
							src={DirectoryLogo}
							alt='Profile'
							className='icon__navlink--primary'
						/>
						<p className='title__navlink--primary'>Directory</p>
					</NavLink>

					<NavLink
						to='touchpoints'
						className={({ isActive }) =>
							classNames(
								'navlink navlink--primary',
								isActive && 'navlink--primary--active'
							)
						}>
						<PreviewIcon className='icon__navlink--primary' />
						<p className='title__navlink--primary'>Touchpoints</p>
					</NavLink>

					{portalPojo.getType() === PortalTypeEnum.Team &&
						portalUserPojo.getRole() === PortalUserRole.Admin && (
							<NavLink
								to='users'
								className={({ isActive }) =>
									classNames(
										'navlink navlink--primary',
										isActive && 'navlink--primary--active'
									)
								}>
								<img
									src={ProfileLogo}
									alt='Profile'
									className='icon__navlink--primary'
								/>
								<p className='title__navlink--primary'>Users</p>
							</NavLink>
						)}

					<NavLink
						to='settings'
						className={({ isActive }) =>
							classNames(
								'navlink navlink--primary',
								isActive && 'navlink--primary--active'
							)
						}>
						<img
							src={SettingsLogo}
							alt='Profile'
							className='icon__navlink--primary'
						/>
						<p className='title__navlink--primary'>Settings</p>
					</NavLink>
				</div>
				<div className='container__portal-content'>
					<Outlet
						context={
							{
								portalPojo: portalPojo,
								portalUserPojo: portalUserPojo
							} satisfies PortalLayoutContext
						}
					/>
				</div>
			</div>
		);
	}
};

export default PortalLayout;
