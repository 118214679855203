import { useCallback } from 'react';
import toast, { Toast } from 'react-hot-toast';

import Typography from './Typography';
import CloseIcon from '../icons/CloseIcon';
import InfoToastIcon from '../icons/InfoToastIcon';

const InfoToast = ({ id, message }: Toast) => {
	const dismissToast = useCallback(() => {
		toast.remove(id);
	}, [id]);

	return (
		<div className='toast toast--info'>
			<div className='toast__container'>
				<InfoToastIcon className='toast__icon' />
				<div className='toast__text-container'>
					<Typography variant='h6' fontSize={16} fontWeight={500} text='Info' />
					<Typography
						variant='p'
						fontSize={14}
						fontWeight={400}
						text={message as string}
					/>
				</div>

				<CloseIcon className='toast__close-icon' onClick={dismissToast} />
			</div>
		</div>
	);
};

export default InfoToast;
