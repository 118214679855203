import { useCallback, useEffect, useState } from 'react';

import Form from '../components/Form';
import Modal from '../components/Modal';
import Button from '../components/Button';
import ModalBody from '../components/ModalBody';
import ModalTitle from '../components/ModalTitle';
import ModalHeader from '../components/ModalHeader';
import ModalFooter from '../components/ModalFooter';
import FormFieldError from '../components/FormFieldError';
import FormFieldGroup from '../components/FormFieldGroup';
import ModalCloseIcon from '../components/ModalCloseIcon';
import FormFieldLabel from '../components/FormFieldLabel';
import FormFieldTextInput from '../components/FormFieldTextInput';

import useForm from '../hooks/useForm';
import useModal from '../hooks/useModal';
import TouchpointDto from './TouchpointDto';
import ToastUtil from '../utils/ToastUtil';
import TouchpointUtil from './TouchpointUtil';
import TouchpointStatus from './TouchpointStatus';
import { EventEmitterInstance } from '../instances';
import APIRequestUtil from '../utils/APIRequestUtil';
import TouchpointDataModel from './TouchpointDataModel';
import EventNamesConstants from '../constants/EventNamesConstants';

namespace TouchpointStatusUpdateForm {
	export type FormValues = {
		code: string;
	};
}

const initialValues: TouchpointStatusUpdateForm.FormValues = {
	code: ''
};

export default function TouchpointStatusUpdateModal({
	touchpointDto,
	updateTouchpointDto
}: {
	touchpointDto: TouchpointDto;
	updateTouchpointDto(touchpointDto: TouchpointDto): void;
}) {
	const { open, openModal, closeModal } = useModal();
	const { values, errors, setValue, setError, clearErrors, setMultipleValues } =
		useForm<TouchpointStatusUpdateForm.FormValues>({
			initialValues
		});

	const [isUpdatingTouchpointStatus, setIsUpdatingTouchpointStatus] =
		useState(false);
	const [
		isGeneratingTouchpointStatusUpdateCode,
		setIsGeneratingTouchpointStatusUpdateCode
	] = useState(false);

	useEffect(() => {
		const openModalEventListener = () => {
			setMultipleValues(initialValues);
			openModal();
		};
		const subscription = EventEmitterInstance.addListener(
			EventNamesConstants.Touchpoints.Modal.Status,
			openModalEventListener
		);

		return () => {
			subscription.remove();
		};
	}, [openModal, setMultipleValues]);

	const onTextInputChange = useCallback(
		(key: string, value: string) => {
			setValue(key, value);
			setError(key, '');
		},
		[setError, setValue]
	);

	const generateCode = useCallback(() => {
		setIsGeneratingTouchpointStatusUpdateCode(true);

		TouchpointUtil.generateCodeWithPortal({
			touchpoint_id: touchpointDto.getId(),
			portal_id: touchpointDto.getPortal().getId()
		})
			.then((message) => ToastUtil.makeInfoToast(message))
			.catch((err) => APIRequestUtil.handleRequestFailure(err))
			.finally(() => {
				setIsGeneratingTouchpointStatusUpdateCode(false);
			});
	}, [touchpointDto]);

	const updateTouchpointStatus = useCallback(() => {
		clearErrors();

		if (!values.code) {
			setError('code', 'Code cannot be empty.');
			return;
		}

		setIsUpdatingTouchpointStatus(true);

		const touchpointDataModel = new TouchpointDataModel();

        touchpointDataModel.setCode(values.code)
		touchpointDataModel.setId(touchpointDto.getId());
		touchpointDataModel.setPortal_id(touchpointDto.getPortal().getId());

		if (
			touchpointDto.getStatus().getValue() ===
			TouchpointStatus.Active.getValue()
		) {
			touchpointDataModel.setStatus(TouchpointStatus.Inactive);
		} else {
			touchpointDataModel.setStatus(TouchpointStatus.Active);
		}
		TouchpointUtil.updateTouchpointStatusWithPortal({
			touchpointDataModel
		})
			.then((touchpointDto) => {
				updateTouchpointDto(touchpointDto);
				closeModal();
			})
			.catch((err) => APIRequestUtil.handleRequestFailure(err))
			.finally(() => {
				setIsUpdatingTouchpointStatus(false);
			});
	}, [
		values,
		setError,
		closeModal,
		clearErrors,
		touchpointDto,
		updateTouchpointDto
	]);

	return (
		<Modal show={open} position='top'>
			<ModalHeader>
				<ModalTitle
					title={
						touchpointDto.getStatus().getValue() ===
						TouchpointStatus.Active.getValue()
							? 'De-activate Touchpoint'
							: 'Activate Touchpoint'
					}
				/>
				<ModalCloseIcon closeModal={closeModal} />
			</ModalHeader>
			<ModalBody>
				<Form>
					<FormFieldGroup>
						<FormFieldLabel label='Email Address' />
						<FormFieldTextInput
							disabled
							name='email'
							value={touchpointDto.getUser().getEmail_id()}
						/>
					</FormFieldGroup>
					<FormFieldGroup>
						<FormFieldLabel label='Code' />
						<FormFieldTextInput
							name='code'
							value={values.code}
							error={errors.code}
							placeholder='Enter the code'
							onChange={onTextInputChange}
						/>
						<FormFieldError error={errors.code} />
					</FormFieldGroup>
				</Form>
			</ModalBody>
			<ModalFooter>
				<Button variant='outlined' label='Cancel' onClick={closeModal} />
				<Button
					variant='outlined'
					label='Generate Code'
					onClick={generateCode}
					disabled={isUpdatingTouchpointStatus}
					isLoading={isGeneratingTouchpointStatusUpdateCode}
				/>
				<Button
					variant='primary'
					onClick={updateTouchpointStatus}
					isLoading={isUpdatingTouchpointStatus}
					label={
						touchpointDto.getStatus().getValue() ===
						TouchpointStatus.Active.getValue()
							? 'De-activate'
							: 'Activate'
					}
					disabled={isGeneratingTouchpointStatusUpdateCode}
				/>
			</ModalFooter>
		</Modal>
	);
}
