import classNames from 'classnames';
import { ComponentProps as ContainerProfileCategoryHeaderProps } from '../types/components';

const ContainerProfileCategoryHeader: React.FC<
	ContainerProfileCategoryHeaderProps
> = ({ children, className }) => {
	return (
		<div
			className={classNames('container--profile__category__header', className)}>
			{children}
		</div>
	);
};

export default ContainerProfileCategoryHeader;
