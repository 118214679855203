import { toast } from 'react-hot-toast';

import InfoToast from '../components/InfoToast';
import ToastTypeEnum from '../enums/ToastTypeEnum';
import SuccessToast from '../components/SuccessToast';
import FailureToast from '../components/FailureToast';

class ToastService {
	public static makeToast(type: ToastTypeEnum, message: string): void {
		toast.remove();
		if (type === ToastTypeEnum.Success) {
			toast.custom((toast) => <SuccessToast {...toast} message={message} />);
		} else if (type === ToastTypeEnum.Failure) {
			toast.custom((toast) => <FailureToast {...toast} message={message} />);
		} else {
			toast.custom((toast) => <InfoToast {...toast} message={message} />);
		}
	}
}

export default ToastService;
