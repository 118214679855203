import { MappedValue } from "../datatypes";

export default class CardConstants{
    static readonly Status = {
		Active: new MappedValue('active', 'Active'),
		Inactive: new MappedValue('inactive', 'In-active')
	};
	static readonly DesignAuditStatus = {
		Pending: new MappedValue('pending', 'Pending'),
		Started: new MappedValue('started', 'Started'),
		Accepted: new MappedValue('accepted', 'Accepted')
	};

	static readonly getStatusByValue = (value: string): MappedValue => {
		for (const status of Object.values(this.Status)) {
			if (status.getValue() === value) {
				return status;
			}
		}
		return this.Status.Inactive;
	};

	static readonly getStatusByMappedValue = (value: string): MappedValue => {
		for (const status of Object.values(this.Status)) {
			if (status.getMappedValue() === value) {
				return status;
			}
		}
		return this.Status.Inactive;
	};

	static readonly getDesignAuditStatusByValue = (
		value: string
	): MappedValue => {
		for (const status of Object.values(this.DesignAuditStatus)) {
			if (status.getValue() === value) {
				return status;
			}
		}
		return this.DesignAuditStatus.Pending;
	};

	static readonly getDesignAuditStatusByMappedValue = (
		value: string
	): MappedValue => {
		for (const status of Object.values(this.DesignAuditStatus)) {
			if (status.getMappedValue() === value) {
				return status;
			}
		}
		return this.DesignAuditStatus.Pending;
	};

	static readonly getAllMappedValuesOfDesignAuditStatus = (): Array<string> => {
		return Object.values(this.DesignAuditStatus).map((item) =>
			item.getMappedValue()
		);
	};

	static readonly getAllMappedValuesOfStatus = (): Array<string> => {
		return Object.values(this.Status).map((item) => item.getMappedValue());
	};
}