import Enum from '../base-classes/Enum';

export default class ProfileTemplateNodeCategoryEnum extends Enum {
	public static readonly Static = new ProfileTemplateNodeCategoryEnum(
		'static',
		'Static'
	);
	public static readonly Dynamic = new ProfileTemplateNodeCategoryEnum(
		'dynamic',
		'Dynamic'
	);

	public static getValue(value: string): ProfileTemplateNodeCategoryEnum {
		if (
			value === this.Dynamic.getSelectOptionValue() ||
			value === this.Dynamic.getMappedValue()
		) {
			return this.Dynamic;
		} else {
			return this.Static;
		}
	}

	public static getAllValues(): Array<ProfileTemplateNodeCategoryEnum> {
		return [this.Static, this.Dynamic];
	}
}
