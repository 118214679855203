import FormTypeEnum from '../enums/FormTypeEnum';
import DataModel from '../abstract-classes/DataModel';
import LeadStorageTypeEnum from '../enums/LeadStorageTypeEnum';
import LeadStorageLocationEnum from '../enums/LeadStorageLocationEnum';

export default class FormDataModel extends DataModel {
	private name: string;
	private external_app_module: string;

	private type: FormTypeEnum;
	private storage_type: LeadStorageTypeEnum;
	private storage_location: LeadStorageLocationEnum;


	constructor() {
		super();

		this.name = '';
		this.external_app_module = '';

		this.type = FormTypeEnum.Profile;
		this.storage_type = LeadStorageTypeEnum.Application;
		this.storage_location = LeadStorageLocationEnum.Application;

	}

	public getName(): string {
		return this.name;
	}

	public setName(name: string): void {
		this.name = name;
	}

	public getType(): FormTypeEnum {
		return this.type;
	}

	public setType(type: FormTypeEnum): void {
		this.type = type;
	}

	public getStorage_type(): LeadStorageTypeEnum {
		return this.storage_type;
	}

	public setStorage_type(storage_type: LeadStorageTypeEnum): void {
		this.storage_type = storage_type;
	}

	public getStorage_location(): LeadStorageLocationEnum {
		return this.storage_location;
	}

	public setStorage_location(storage_location: LeadStorageLocationEnum): void {
		this.storage_location = storage_location;
	}

	public getExternal_app_module(): string {
		return this.external_app_module;
	}

	public setExternal_app_module(external_app_module: string): void {
		this.external_app_module = external_app_module;
	}

	public getCreatePayload(): Record<string, unknown> {
		return {
			name: this.name,
			type: this.type.getSelectOptionValue(),
			storage_type: this.storage_type.getSelectOptionValue(),
			storage_location: this.storage_location.getSelectOptionValue(),
			...(this.external_app_module
				? { external_app_module: this.external_app_module }
				: {})
		};
	}

	public getUpdatePayload(): Record<string, unknown> {
		return {};
	}
}
