import  CommonUtil  from '../utils/CommonUtil';;

export default class String {
	private value: string;

	constructor(value: string) {
		this.value = value;
	}

	public getValue = (): string => {
		return this.value;
	};

	public toPascalCase = (): string => {
		return CommonUtil.toTitleCase(this.value);
	};

	public toString = (): string => {
		return this.value;
	};

	static getInstance = (value: string): String => {
		return new String(value);
	};
}
