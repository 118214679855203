import classNames from 'classnames';
import { useCallback, useRef } from 'react';

import { FormFieldImageInput as FormFieldImageInputProps } from '../types/components';

const FormFieldImageInput: React.FunctionComponent<
	FormFieldImageInputProps
> = ({ id, name, value, error, placholder, accept, onBlur, onChange }) => {
	const ref = useRef<HTMLInputElement>(null);

	const chooseFile = useCallback(() => {
		ref.current?.click();
	}, []);

	const __onChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const { name, files } = event.target;
			if (files?.length) {
				onChange(name, files[0]);
			}
		},
		[onChange]
	);

	return (
		<div
			className={classNames(
				'form__field-input--image__container',
				error && 'form__field-input--image__container--error'
			)}>
			<button
				type='button'
				onClick={chooseFile}
				className='form__field-input--image__btn'>
				Choose File
			</button>
			<p
				className={classNames(
					'form__field-input--image__text',
					!value && 'form__field-input--image__placeholder'
				)}>
				{value ? value.name : placholder}
			</p>
			<input
				id={id}
				ref={ref}
				name={name}
				type='file'
				onChange={__onChange}
				className='form__field-input--image__input'
				accept={accept ? accept.join(',') : '*'}
			/>
		</div>
	);
};

export default FormFieldImageInput;
