export default class PartialPortalUserDto {
	private id: string;
	private email_id: string;
	private last_name: string;
	private first_name: string;

	constructor() {
		this.id = '';
		this.email_id = '';
		this.last_name = '';
		this.first_name = '';
	}

	public getId(): string {
		return this.id;
	}

	public getEmail_id(): string {
		return this.email_id;
	}

	public getLast_name(): string {
		return this.last_name;
	}

	public getFirst_name(): string {
		return this.first_name;
	}

	public getFull_name(): string {
		return (this.first_name + ' ' + this.last_name).trim();
	}

	public loadFromJSON(json: Record<string, unknown>): void {
		this.id = json.id as string;
		this.email_id = json.email_id as string;
		this.last_name = json.last_name as string;
		this.first_name = json.first_name as string;
	}
}
