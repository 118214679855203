import TouchpointStyles from './touchpoint.module.css';
import TouchpointTemplateSelectionStyles from './touchpoint-template-selection.module.css';

import classNames from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useRef, useState } from 'react';

import Box from '../components/Box';
import Button from '../components/Button';
import Loader from '../components/loader';
import Typography from '../components/Typography';
import ArrowLeftIcon from '../icons/ArrowLeftIcon';
import ContainerCenter from '../components/ContainerCenter';

import ToastUtil from '../utils/ToastUtil';
import TouchpointUtil from './TouchpointUtil';
import APIRequestUtil from '../utils/APIRequestUtil';
import TouchpointDataModel from './TouchpointDataModel';
import TouchpointTemplateDto from './TouchpointTemplateDto';
import TouchpointTemplateUtil from './TouchpointTemplateUtil';

function TouchpointTemplatePreviewCard({
	touchpointTemplateDto
}: {
	touchpointTemplateDto: TouchpointTemplateDto;
}) {
	const [backFace, setBackFace] = useState('');
	const [frontFace, setFrontFace] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [showFrontFace, setShowFrontFace] = useState(true);

	const toggleShowFrontView = useCallback(() => {
		setShowFrontFace((c) => !c);
	}, []);

	useEffect(() => {
		setIsLoading(true);
		setShowFrontFace(true);

		const controller = new AbortController();

		TouchpointTemplateUtil.getTouchpointTemplateAssetAsDataURIWithPortal(
			touchpointTemplateDto.getPortal().getId(),
			touchpointTemplateDto.getId(),
			touchpointTemplateDto.getPreview().getFront().getId(),
			{
				controller
			}
		)
			.then((frontView) => {
				setFrontFace(frontView);

				TouchpointTemplateUtil.getTouchpointTemplateAssetAsDataURIWithPortal(
					touchpointTemplateDto.getPortal().getId(),
					touchpointTemplateDto.getId(),
					touchpointTemplateDto.getPreview().getBack().getId(),
					{
						controller
					}
				)
					.then((backView) => {
						setBackFace(backView);
						setIsLoading(false);
					})
					.catch((err) => APIRequestUtil.handleRequestFailure(err));
			})
			.catch((err) => APIRequestUtil.handleRequestFailure(err));

		return () => {
			controller.abort();
		};
	}, [touchpointTemplateDto]);

	if (isLoading) {
		return (
			<ContainerCenter>
				<Loader />
			</ContainerCenter>
		);
	} else {
		return (
			<>
				<ul className={TouchpointStyles['nav-links']}>
					<li
						className={classNames(
							TouchpointStyles['nav-link'],
							showFrontFace && TouchpointStyles['nav-link--active']
						)}
						onClick={toggleShowFrontView}>
						Front
					</li>
					<li
						className={classNames(
							TouchpointStyles['nav-link'],
							!showFrontFace && TouchpointStyles['nav-link--active']
						)}
						onClick={toggleShowFrontView}>
						Back
					</li>
				</ul>

				<img
					src={showFrontFace ? frontFace : backFace}
					alt='Preview'
					className={TouchpointStyles['preview-card']}
				/>
			</>
		);
	}
}

function TouchpointTemplateFrontPreview({
	isActive,
	touchpointTemplateDto,
	selectTouchpointTemplateDto
}: {
	isActive: boolean;
	touchpointTemplateDto: TouchpointTemplateDto;
	selectTouchpointTemplateDto(
		touchpointTemplateDto: TouchpointTemplateDto
	): void;
}) {
	const onClick = useCallback(() => {
		if (!isActive) {
			selectTouchpointTemplateDto(touchpointTemplateDto);
		}
	}, [isActive, selectTouchpointTemplateDto, touchpointTemplateDto]);

	return (
		<img
			alt='Preview'
			src={touchpointTemplateDto.getPreview().getFront().getDownloadUrl()}
			className={classNames(
				TouchpointTemplateSelectionStyles['template'],
				isActive && TouchpointTemplateSelectionStyles['template--active']
			)}
			onClick={onClick}
		/>
	);
}

export default function TouchpointTemplateSelectionView() {
	const navigate = useRef(useNavigate());
	const { portal_id } = useParams() as Record<string, string>;

	const [isLoading, setIsLoading] = useState(true);
	const [isProcessing, setIsProcessing] = useState(false);
	const [touchpointTemplates, setTouchpointTemplates] = useState<
		Array<TouchpointTemplateDto>
	>([]);
	const [selectedTouchpointTemplateDto, setSelectedTouchpointTemplateDto] =
		useState(new TouchpointTemplateDto());

	useEffect(() => {
		setIsLoading(true);
		TouchpointTemplateUtil.getPagedTouchpointTemplatesWithPortal(
			1,
			100,
			portal_id
		)
			.then((response) => {
				const data = response.getData();

				if (data.length === 0) {
					ToastUtil.makeInfoToast(
						'Touchpoint templates are currently being populated. Please try again later'
					);
					return;
				}

				setTouchpointTemplates(data);
				setSelectedTouchpointTemplateDto(response.getData()[0]);

				setIsLoading(false);
			})
			.catch((err) => APIRequestUtil.handleRequestFailure(err));
	}, [portal_id]);

	const goToTouchpoints = useCallback(() => {
		navigate.current(`/portals/${portal_id}/touchpoints`);
	}, [portal_id]);

	const selectTouchpointTemplateDto = useCallback(
		(touchpointTemplateDto: TouchpointTemplateDto) => {
			setSelectedTouchpointTemplateDto(touchpointTemplateDto);
		},
		[]
	);

	const createTouchpoint = useCallback(() => {
		setIsProcessing(true);
		const touchpointDataModel = new TouchpointDataModel();

		touchpointDataModel.setTemplate(selectedTouchpointTemplateDto.getName());

		TouchpointUtil.createTouchpointWithPortal({
			touchpointDataModel,
			portal_id: selectedTouchpointTemplateDto.getPortal().getId()
		})
			.then((touchpointDto) => {
				navigate.current(
					`/portals/${selectedTouchpointTemplateDto
						.getPortal()
						.getId()}/touchpoints/${touchpointDto.getId()}`
				);
			})
			.catch((err) => APIRequestUtil.handleRequestFailure(err))
			.finally(() => {
				setIsProcessing(false);
			});
	}, [selectedTouchpointTemplateDto]);

	if (isLoading) {
		return (
			<ContainerCenter>
				<Loader />
			</ContainerCenter>
		);
	} else {
		return (
			<>
				<Box className={TouchpointStyles['view']}>
					<Box className={TouchpointStyles['view-header']}>
						<ArrowLeftIcon
							className={TouchpointStyles['view-header__back-btn']}
							onClick={goToTouchpoints}
						/>
						<Typography
							variant='h5'
							text='Select Touchpoint Template'
							className={TouchpointStyles['view-header__title']}
						/>
					</Box>
					<Box className={TouchpointStyles['view-body']}>
						<Box
							className={
								TouchpointTemplateSelectionStyles['templates-container']
							}>
							<Box
								className={
									TouchpointTemplateSelectionStyles[
										'templates-container__header'
									]
								}>
								<Typography
									text='Templates'
									variant='h6'
									className={
										TouchpointTemplateSelectionStyles[
											'templates-container__title'
										]
									}
								/>
							</Box>
							<Box
								className={
									TouchpointTemplateSelectionStyles['templates-container__body']
								}>
								{touchpointTemplates.map((template) => (
									<TouchpointTemplateFrontPreview
										key={template.getId()}
										touchpointTemplateDto={template}
										selectTouchpointTemplateDto={selectTouchpointTemplateDto}
										isActive={
											template.getId() === selectedTouchpointTemplateDto.getId()
										}
									/>
								))}
							</Box>
						</Box>
						<Box
							className={
								TouchpointTemplateSelectionStyles['preview-container']
							}>
							<Box
								className={
									TouchpointTemplateSelectionStyles['preview-container__header']
								}>
								<Typography
									text='Preview'
									variant='h6'
									className={
										TouchpointTemplateSelectionStyles[
											'preview-container__title'
										]
									}
								/>
							</Box>
							<Box
								className={
									TouchpointTemplateSelectionStyles['preview-container__body']
								}>
								<TouchpointTemplatePreviewCard
									touchpointTemplateDto={selectedTouchpointTemplateDto}
								/>
							</Box>
							<Box
								className={
									TouchpointTemplateSelectionStyles['preview-container__footer']
								}>
								<Button
									label='Create'
									variant='primary'
									isLoading={isProcessing}
									onClick={createTouchpoint}
								/>
							</Box>
						</Box>
					</Box>
				</Box>
			</>
		);
	}
}
