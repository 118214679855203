import axios from 'axios';

const AxiosApiInstance = axios.create({
	baseURL: '/server/v1/api'
});
const AxiosFileInstance = axios.create({
	baseURL: '/server/v1/files'
});

const instances = { API: AxiosApiInstance, Files: AxiosFileInstance };

export default instances;
