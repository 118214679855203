import classNames from 'classnames';

import { Form as FormProps } from '../types/components';

const Form: React.FunctionComponent<FormProps> = ({ children, className }) => {
	return (
		<form className={classNames('form', className)} autoComplete='off'>
			{children}
		</form>
	);
};

export default Form;
