import classNames from 'classnames';

import { ModalHeader as ModalHeaderProps } from '../types/components';

const ModalHeader: React.FunctionComponent<ModalHeaderProps> = ({
	children,
	className
}) => {
	return (
		<div className={classNames('modal__header', className)}>
			{children}
		</div>
	);
};

export default ModalHeader;
