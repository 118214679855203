export default class TouchpointTemplateNodeType {
	public static readonly QR = new TouchpointTemplateNodeType('qr');
	public static readonly TEXT = new TouchpointTemplateNodeType('text');
	public static readonly INPUT = new TouchpointTemplateNodeType('input');
	public static readonly IMAGE = new TouchpointTemplateNodeType('image');
	public static readonly CONTAINER = new TouchpointTemplateNodeType(
		'container'
	);

	private value: string;
	
	constructor(value: string) {
		this.value = value;
	}

	public getValue(): string {
		return this.value;
	}

	public toString(): string {
		return this.value;
	}

	public static getValue(value: string): TouchpointTemplateNodeType {
		if (value === this.CONTAINER.value) {
			return this.CONTAINER;
		} else if (value === this.IMAGE.value) {
			return this.IMAGE;
		} else if (value === this.INPUT.value) {
			return this.INPUT;
		} else if (value === this.TEXT.value) {
			return this.TEXT;
		} else {
			return this.QR;
		}
	}

	public static getAllValues(): Array<TouchpointTemplateNodeType> {
		return [this.QR, this.TEXT, this.INPUT, this.IMAGE, this.CONTAINER];
	}
}
