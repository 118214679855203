export default class FileMeta {
	private id: string;
	private name: string;
	private mimetype: string;
	private downloadPath: string;

	constructor() {
		this.id = '';
		this.name = '';
		this.mimetype = '';
		this.downloadPath = '';
	}

	public getId(): string {
		return this.id;
	}

	public setId(id: string): void {
		this.id = id;
	}

	public getName(): string {
		return this.name;
	}

	public setName(name: string): void {
		this.name = name;
	}

	public getMimetype(): string {
		return this.mimetype;
	}

	public setMimetype(mimetype: string): void {
		this.mimetype = mimetype;
	}

	public getDownloadUrl(): string {
		return `/server/v1/files` + this.downloadPath + '/' + this.id;
	}

	public getDownloadPath(): string {
		return this.downloadPath;
	}

	public setDownloadPath(downloadPath: string): void {
		this.downloadPath = downloadPath;
	}

	public loadFromJSON(json: Record<string, unknown>) {
		this.id = json.id as string;
		this.name = json.name as string;
		this.mimetype = json.mimetype as string;
	}

	public toJSON(): Record<string, unknown> {
		return {
			id: this.id,
			name: this.name,
			mimetype: this.mimetype
		};
	}

	public toString(): string {
		return JSON.stringify(this.toJSON());
	}
}
