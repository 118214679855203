import { useCallback, useMemo } from 'react';

import { EventEmitterInstance } from '../instances';
import ProfileTemplateNode from './ProfileTemplateNode';
import UserInformationDto from '../dtos/UserInformationDto';
import EventNamesConstants from '../constants/EventNamesConstants';
import ProfileTemplateNodeButtonOnclickActionEnum from '../enums/ProfileTemplateNodeButtonOnclickActionEnum';

export type ProfileTemplateButtonNodeProps = {
	disabled?: boolean;
	userInformationPojo: UserInformationDto;
	profileTemplateNode: ProfileTemplateNode;
};

const ProfileTemplateButtonNode: React.FC<ProfileTemplateButtonNodeProps> = ({
	disabled,
	profileTemplateNode
}) => {
	const label = useMemo<string>(
		() => profileTemplateNode.getProperties().getLabel() as string,
		[profileTemplateNode]
	);
	const style = useMemo<React.CSSProperties>(
		() => profileTemplateNode.getStyles().toJSON(),
		[profileTemplateNode]
	);

	const onClick = useCallback(() => {
		const onClickAction = profileTemplateNode
			.getProperties()
			.getOnClickAction() as string;

		if (
			onClickAction ===
			ProfileTemplateNodeButtonOnclickActionEnum.DisplayLeadForm
		) {
			EventEmitterInstance.emit(
				EventNamesConstants.ProfileTemplateNode.Button.Action.DisplayLeadForm
			);
		}
	}, [profileTemplateNode]);

	return (
		<button
			style={style}
			disabled={disabled}
			onClick={onClick}
			className='profile-template__node--button'>
			{label}
		</button>
	);
};

export default ProfileTemplateButtonNode;
