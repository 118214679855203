import axios from 'axios';

import ResponsePojo from '../pojos/ResponsePojo';
import RequestMethodEnum from '../enums/RequestMethodEnum';

export default class RequestHandler {
	private url: string;
	private body: unknown;
	private baseUrl: string;
	private headers: Record<string, string>;
	private params: Record<string, unknown>;
	private configurations: Record<string, unknown>;

	private method: RequestMethodEnum;

	constructor() {
		this.url = '';
		this.body = {};
		this.params = {};
		this.headers = {};
		this.configurations = {};
		this.baseUrl = '/server/v1';

		this.method = RequestMethodEnum.Get;
	}

	public setUrl(url: string): void {
		this.url = url;
	}

	public setBody(body: unknown): void {
		this.body = body;
	}

	public setMethod(method: RequestMethodEnum): void {
		this.method = method;
	}

	public addHeader(key: string, value: string): void {
		this.headers[key] = value;
	}

	public addHeaders(headers: Record<string, string>): void {
		this.headers = {
			...this.headers,
			...headers
		};
	}

	public addParam(key: string, value: string | number): void {
		this.params[key] = value;
	}

	public addParams(params: Record<string, string | number>): void {
		this.params = {
			...this.params,
			...params
		};
	}

	public addConfiguration(key: string, value: any): void {
		this.configurations[key] = value;
	}

	public addConfigurations(configurations: Record<string, string>): void {
		this.configurations = {
			...this.configurations,
			...configurations
		};
	}

	public setAbortController(controller: AbortController) {
		this.configurations['signal'] = controller.signal;
	}

	public async execute(): Promise<ResponsePojo> {
		return axios({
			url: this.url,
			data: this.body,
			params: this.params,
			method: this.method,
			baseURL: this.baseUrl,
			headers: this.headers,
			...this.configurations
		}).then((response) => {
			const responsePojo = new ResponsePojo();
			responsePojo.setData(response.data);
			responsePojo.setHeaders(response.headers as Record<string, string>);
			return responsePojo;
		});
	}
}
