import { useCallback } from 'react';
import classNames from 'classnames';

import { FormFieldTextInput as FormFieldTextInputProps } from '../types/components';

const FormFieldTextInput: React.FunctionComponent<FormFieldTextInputProps> = ({
	id,
	name,
	error,
	value,
	onBlur,
	disabled,
	onChange,
	readonly,
	placeholder: placholder
}) => {
	const __onChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const { name, value } = event.target;
			if (onChange) {
				onChange(name, value);
			}
		},
		[onChange]
	);
	return (
		<input
			id={id}
			name={name}
			type='text'
			value={value}
			onBlur={onBlur}
			readOnly={readonly}
			disabled={disabled}
			onChange={__onChange}
			placeholder={placholder}
			className={classNames(
				'form__field-input',
				error && 'form__field-input--error'
			)}
		/>
	);
};

export default FormFieldTextInput;
