import { useCallback } from 'react';
import { FormFieldCheckbox as FormFieldCheckboxProps } from '../types/components';

const FormFieldCheckboxInput = ({
	id,
	name,
	label,
	value,
	onBlur,
	onChange
}: FormFieldCheckboxProps) => {
	const __onChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const { name, checked } = event.target;
			onChange(name, checked);
		},
		[onChange]
	);
	return (
		<div className='form__field-input--checkbox__container'>
			<label>
				<input
					id={id}
					name={name}
					className='form__field-input--checkbox'
					type='checkbox'
					checked={value}
					onChange={__onChange}
				/>
				<span className='form__field-input--checkbox__icon' />
			</label>
			<p className='form__field-input--checkbox__label'>{label}</p>
		</div>
	);
};

export default FormFieldCheckboxInput;
