import RequestMethodEnum from '../enums/RequestMethodEnum';
import RequestHandler from '../handlers/RequestHandler';
import LeadDataModel from './LeadDataModel';

namespace LeadUtilParams {
	type CommonParams = {
		controller?: AbortController;
	};
	export type CreateLead = {
		leadDataModel: LeadDataModel;
	} & CommonParams;
}
export default class LeadUtil {
	public static async createLead(
		params: LeadUtilParams.CreateLead
	): Promise<void> {
		const { controller, leadDataModel } = params;

		const requestHandler = new RequestHandler();

		if (controller) {
			requestHandler.setAbortController(controller);
		}

		requestHandler.setMethod(RequestMethodEnum.Post);
		requestHandler.setUrl(
			`/api/portals/${leadDataModel.getPortal_id()}/users/${leadDataModel.getUser_id()}/leads`
		);
		requestHandler.setBody(leadDataModel.getInsertPayload());
		await requestHandler.execute();
	}
}
