import Enum from '../base-classes/Enum';

export default class FormTypeEnum extends Enum {
	public static readonly Card = new FormTypeEnum('card', 'card');
	public static readonly Profile = new FormTypeEnum('profile', 'profile');

	public static getValue(value: string): FormTypeEnum {
		if (value === this.Card.value || value === this.Card.mappedValue) {
			return this.Card;
		} else {
			return this.Profile;
		}
	}

	public static getAllValues(): Array<FormTypeEnum> {
		return [this.Profile, this.Card];
	}
}
