import { Toaster } from 'react-hot-toast';
import { useEffect, useRef, useState } from 'react';
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';

import { GlobalContext } from './context';
import { withAuthentication } from './hoc';
import { LoginView } from './views';

import { AxiosInstance, GlobalInstance } from './instances';

import { AuthorizedLayout, SettingsLayout } from './layouts';

import View from './components/View';
import UsersPage from './views/users';
import Loader from './components/loader';
import PortalsPage from './views/portals';
import PortalLayout from './layouts/portal';
import DirectoryPage from './views/directory';
import ExternalAppsView from './views/external-apps';
import ContainerCenter from './components/ContainerCenter';
import TouchpointView from './touchpoint/TouchpointView';
import ProfileLeadGenPage from './views/profile-lead-gen';
import ProfileTemplatePickerLayout from './profile-templates/ProfileTemplatePickerLayout';
import TouchpointsView from './touchpoint/TouchpointsView';
import ProfilePortfolioPage from './views/profile-portfolio';
import TouchpointsLayout from './touchpoint/TouchpointsLayout';
import UpdateUserInformationPage from './views/update-user-information';
import ProfileTemplateUserView from './profile-templates/ProfileTemplateUserView';
import ProfileTemplateUserForm from './profile-templates/ProfileTemplateUserForm';
import ProfileTemplateUserLayout from './profile-templates/ProfileTemplateUserLayout';
import TouchpointTemplateSelectionView from './touchpoint/TouchpointTemplateSelectionView';

import UserPojo from './pojos/UserPojo';

const WrappedLoginView = withAuthentication({
	component: <LoginView />,
	fallback: <Navigate to='/portals' replace />,
	expectedState: 'unauthenticated'
});

const WrappedAuthorizedLayout = withAuthentication({
	component: <AuthorizedLayout />,
	fallback: <Navigate to='/login' replace />,
	expectedState: 'authenticated'
});

function App() {
	const currentUserPojo = useRef(new UserPojo());
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		setIsLoading(true);

		const currentUser = currentUserPojo.current;

		AxiosInstance.API.get('/users/current-user')
			.then((response) => {
				GlobalInstance.getInstance().setAuth_state('authenticated');
				currentUser.loadFromJson(response.data.data);
			})
			.catch(() => {
				GlobalInstance.getInstance().setAuth_state('unauthenticated');
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);
	return (
		<View className='view--app'>
			<GlobalContext.Provider value={{ currentUser: currentUserPojo.current }}>
				{isLoading ? (
					<ContainerCenter>
						<Loader />
					</ContainerCenter>
				) : (
					<HashRouter>
						<Routes>
							<Route path='portals' element={<WrappedAuthorizedLayout />}>
								<Route path='' element={<PortalsPage />} />
								<Route path=':portal_id' element={<PortalLayout />}>
									<Route path='users' element={<UsersPage />} />

									<Route path='touchpoints' element={<TouchpointsLayout />}>
										<Route path='' element={<TouchpointsView />} />
										<Route
											path='create'
											element={<TouchpointTemplateSelectionView />}
										/>
										<Route path=':touchpoint_id' element={<TouchpointView />} />

										<Route path='*' element={<Navigate to='' replace />} />
									</Route>

									<Route path='settings' element={<SettingsLayout />}>
										<Route
											path='my-information'
											element={<UpdateUserInformationPage />}
										/>
										<Route
											path='external-apps'
											element={<ExternalAppsView />}
										/>
										<Route path='' element={<Navigate to='my-information' />} />
									</Route>
									<Route path='directory' element={<DirectoryPage />} />
									<Route path='profile' element={<ProfileTemplatePickerLayout />}>
										<Route
											path='portfolio'
											element={<ProfilePortfolioPage />}
										/>
										<Route path='lead-gen' element={<ProfileLeadGenPage />} />
										<Route
											path='*'
											element={<Navigate to='portfolio' replace />}
										/>
										<Route
											path=''
											element={<Navigate to='portfolio' replace />}
										/>
									</Route>
									<Route path='' element={<Navigate to='profile' replace />} />
								</Route>
							</Route>

							<Route path='' element={<Navigate to='portals' replace />} />
							<Route path='*' element={<Navigate to='portals' replace />} />
							<Route path='login' element={<WrappedLoginView />} />
							<Route
								path=':touchpoint_mapped_value'
								element={<ProfileTemplateUserLayout />}>
								<Route path='' element={<ProfileTemplateUserView />} />
								<Route path='form' element={<ProfileTemplateUserForm />} />
								<Route path='*' element={<Navigate to='' replace />} />
							</Route>
						</Routes>
					</HashRouter>
				)}
				<Toaster position='top-center' toastOptions={{ duration: 3000 }} />
			</GlobalContext.Provider>
		</View>
	);
}

export default App;
