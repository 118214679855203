import { Buffer } from 'buffer';
import { AxiosError, CanceledError } from 'axios';

import { FormFieldErrorMapType } from '../types/Common';

class CommonService {
	getNameAndDisplayMessage = (
		message: string,
		map: FormFieldErrorMapType<any>
	): null | {
		name: string;
		displayMessage: string;
	} => {
		for (const [name, errorMap] of Object.entries(map)) {
			for (const { displayMessage, mappedMessages } of errorMap) {
				if (mappedMessages.includes(message)) {
					return {
						name,
						displayMessage
					};
				}
			}
		}

		return null as any;
	};

	processError = (err: any) => {
		if (err instanceof AxiosError) {
			if (err instanceof CanceledError) {
				return '';
			}
			if (err.response && err.response.data) {
				const data = err.response.data;
				const responseType = err.response.config.responseType;

				if (responseType === 'arraybuffer') {
					const { message } = JSON.parse(Buffer.from(data).toString());
					return message;
				}
				const { message } = data;
				return message;
			} else {
				console.log(err);
				return "We're unable to process your request. Kindly try again after some time.";
			}
		}
		console.log(err);
		return "We're unable to process your request. Kindly try again after some time.";
	};

	static getInstance = () => {
		return new CommonService();
	};
}

export default CommonService;
