import { useCallback, useEffect, useState } from 'react';

import Form from '../../components/Form';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import ModalBody from '../../components/ModalBody';
import ModalTitle from '../../components/ModalTitle';
import ModalHeader from '../../components/ModalHeader';
import ModalFooter from '../../components/ModalFooter';
import FormFieldError from '../../components/FormFieldError';
import FormFieldLabel from '../../components/FormFieldLabel';
import FormFieldGroup from '../../components/FormFieldGroup';
import ModalCloseIcon from '../../components/ModalCloseIcon';
import FormFieldTextInput from '../../components/FormFieldTextInput';

import useForm from '../../hooks/useForm';
import useModal from '../../hooks/useModal';
import FormFieldDto from '../../dtos/FormFieldDto';
import FormFieldUtil from '../../utils/FormFieldUtil';
import LoaderModal from '../../components/LoaderModal';
import { EventEmitterInstance } from '../../instances';
import APIRequestUtil from '../../utils/APIRequestUtil';
import ProfileTemplateDto from '../../profile-templates/ProfileTemplateDto';
import FormFieldDataModel from '../../model/FormFieldDataModel';
import EventNamesConstants from '../../constants/EventNamesConstants';

import { UpdateFormFieldForm } from '../../types/forms';
import FormFieldCheckboxInput from '../../components/FormFieldCheckboxInput';

const initialValues: UpdateFormFieldForm.FormValues = {
	label: '',
	placeholder: '',
	required: false
};
const UpdateFormFieldModal = ({
	profileTemplateDto
}: {
	profileTemplateDto: ProfileTemplateDto;
}) => {
	const [formField, setFormField] = useState(new FormFieldDto());
	const { open, openModal, closeModal } = useModal();

	const { values, errors, setError, setValue, clearErrors, setMultipleValues } =
		useForm<UpdateFormFieldForm.FormValues>({
			initialValues
		});
	const [isLoading, setIsLoading] = useState(true);
	const [isProcessing, setIsProcessing] = useState(false);

	const onCheckboxInputChange = useCallback(
		(name: string, value: boolean) => {
			setValue(name, value);
			setError(name, '');
		},
		[setValue, setError]
	);

	const onTextInputChange = useCallback(
		(name: string, value: string) => {
			setValue(name, value);
			setError(name, '');
		},
		[setValue, setError]
	);
	useEffect(() => {
		const openModalEventListener = (formField: FormFieldDto) => {
			openModal();
			setIsLoading(true);

			FormFieldUtil.getInstance(
				profileTemplateDto.getPortal().getId(),
				profileTemplateDto.getForm().getId()
			)
				.getFormField(formField.getId())
				.then((formField) => {
					setFormField(formField);
					setMultipleValues({
						label: formField.getLabel(),
						required: formField.isRequired(),
						placeholder: formField.getPlaceholder()
					});
					setIsLoading(false);
				})
				.catch(APIRequestUtil.handleRequestFailure);
		};
		const subscription = EventEmitterInstance.addListener(
			EventNamesConstants.FormFields.Modal.Update,
			openModalEventListener
		);

		return () => {
			subscription.remove();
		};
	}, [openModal, setMultipleValues, profileTemplateDto]);
	const createField = useCallback(() => {
		clearErrors();

		if (!values.label) {
			setError('label', 'Label cannot be empty.');
			return;
		}
		setIsProcessing(true);

		const formFieldDataModel = new FormFieldDataModel();
		formFieldDataModel.setLabel(values.label);
		formFieldDataModel.setId(formField.getId());
		formFieldDataModel.setRequired(values.required);
		formFieldDataModel.setPlaceholder(values.placeholder);

		FormFieldUtil.getInstance(
			profileTemplateDto.getPortal().getId(),
			profileTemplateDto.getForm().getId()
		)
			.updateFormField(formFieldDataModel)
			.then((formFieldDto) => {
				EventEmitterInstance.emit(
					EventNamesConstants.FormFields.Data.Update,
					formFieldDto
				);

				closeModal();
			})
			.catch(APIRequestUtil.handleRequestFailure)
			.finally(() => {
				setIsProcessing(false);
			});
	}, [
		values,
		setError,
		closeModal,
		clearErrors,
		formField,
		profileTemplateDto
	]);

	return (
		<Modal show={open} position='top'>
			<ModalHeader>
				<ModalTitle title='Update a Field' />
				<ModalCloseIcon closeModal={closeModal} />
			</ModalHeader>
			<ModalBody>
				{isLoading ? (
					<LoaderModal />
				) : (
					<Form>
						<FormFieldGroup>
							<FormFieldLabel label='Name' htmlFor='name' />
							<FormFieldTextInput
								id='name'
								name='name'
								disabled={true}
								placeholder='Enter the name'
								onChange={onTextInputChange}
								value={formField.getName()}
							/>
						</FormFieldGroup>
						<FormFieldGroup>
							<FormFieldLabel label='Type' htmlFor='type' />
							<FormFieldTextInput
								id='type'
								name='type'
								disabled={true}
								error={errors.type}
								value={formField.getType().getMappedValue()}
								onChange={onTextInputChange}
							/>
						</FormFieldGroup>
						<FormFieldGroup>
							<FormFieldLabel label='Label' htmlFor='label' />
							<FormFieldTextInput
								id='label'
								name='label'
								value={values.label}
								error={errors.label}
								placeholder='Enter the label'
								onChange={onTextInputChange}
							/>
							<FormFieldError error={errors.label} />
						</FormFieldGroup>
						<FormFieldGroup>
							<FormFieldLabel label='Placeholder' htmlFor='placeholder' />
							<FormFieldTextInput
								id='placeholder'
								name='placeholder'
								value={values.placeholder}
								error={errors.placeholder}
								placeholder='Enter the placeholder'
								onChange={onTextInputChange}
							/>
							<FormFieldError error={errors.placeholder} />
						</FormFieldGroup>
						<FormFieldGroup>
							<FormFieldLabel
								label='External App Field'
								htmlFor='external_app_field'
							/>
							<FormFieldTextInput
								disabled={true}
								id='external_app_field'
								name='external_app_field'
								onChange={onTextInputChange}
								error={errors.external_app_field}
								value={formField.getExternal_app_field().getSelectOptionLabel()}
							/>
						</FormFieldGroup>
						<FormFieldGroup>
							<FormFieldCheckboxInput
								name='required'
								value={values.required}
								label='Is this field required ?'
								onChange={onCheckboxInputChange}
							/>
						</FormFieldGroup>
					</Form>
				)}
			</ModalBody>
			<ModalFooter>
				<Button variant='outlined' label='Cancel' onClick={closeModal} />
				<Button
					label='Update'
					variant='primary'
					disabled={isLoading}
					onClick={createField}
					isLoading={isProcessing}
				/>
			</ModalFooter>
		</Modal>
	);
};

export default UpdateFormFieldModal;
