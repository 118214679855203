import Util from '../base-classes/Util';
import RequestHandler from '../handlers/RequestHandler';
import UserInformationDto from '../dtos/UserInformationDto';
import RequestMethodEnum from '../enums/RequestMethodEnum';
import UserInformationDataModel from '../model/UserInformationDataModel';
import JsonToUserInformationDtoMapper from '../mappers/JsonToUserInformationDtoMapper';

namespace UserInformationUtilParams {
	type CommomParams = {
		controller?: AbortController;
	};
	export type UpdateUserInformation = {
		userInformationDataModel: UserInformationDataModel;
	} & CommomParams;

	export type GetUserInformationWithPortal = {
		portal_id: string;
		user_id: string;
	} & CommomParams;
}

export default class UserInformationUtil extends Util {
	public static async updateUserInformation(
		params: UserInformationUtilParams.UpdateUserInformation
	): Promise<UserInformationDto> {
		const { controller, userInformationDataModel } = params;

		const requestHandler = new RequestHandler();

		if (controller) {
			requestHandler.setAbortController(controller);
		}

		requestHandler.setUrl(
			`/api/portals/${userInformationDataModel.getPortal_id()}/user-informations/${userInformationDataModel.getUser_id()}`
		);
		requestHandler.setMethod(RequestMethodEnum.Put);
		requestHandler.setBody(userInformationDataModel.getUpdatePayload());

		return requestHandler
			.execute()
			.then((response) =>
				JsonToUserInformationDtoMapper.map(
					(response.getData() as Record<string, unknown>).data as Record<
						string,
						unknown
					>
				)
			);
	}

	public static async getUserInformationWithPortal(
		params: UserInformationUtilParams.GetUserInformationWithPortal
	): Promise<UserInformationDto> {
		const { portal_id, user_id, controller } = params;

		const requestHandler = new RequestHandler();

		if (controller) {
			requestHandler.setAbortController(controller);
		}

		requestHandler.setUrl(
			`/api/portals/${portal_id}/user-informations/${user_id}`
		);
		requestHandler.setMethod(RequestMethodEnum.Get);

		return requestHandler
			.execute()
			.then((response) =>
				JsonToUserInformationDtoMapper.map(
					(response.getData() as Record<string, unknown>).data as Record<
						string,
						unknown
					>
				)
			);
	}
}
