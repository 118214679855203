export default class ProfileTemplateNodeStyles {
	private color?: string;
	private width?: string;
	private height?: string;
	private rowGap?: string;
	private display?: string;
	private fontSize?: string;
	private textAlign?: string;
	private marginTop?: string;
	private columnGap?: string;
	private paddingTop?: string;
	private marginLeft?: string;
	private alignItems?: string;
	private fontWeight?: string;
	private paddingLeft?: string;
	private marginRight?: string;
	private paddingRight?: string;
	private borderRadius?: string;
	private marginBottom?: string;
	private paddingBottom?: string;
	private justifyContent?: string;
	private backgroundColor?: string;

	public getColor(): string | undefined {
		return this.color;
	}

	public setColor(color: string | undefined) {
		this.color = color;
	}

	public getWidth(): string | undefined {
		return this.width;
	}

	public setWidth(width: string | undefined) {
		this.width = width;
	}

	public getHeight(): string | undefined {
		return this.height;
	}

	public setHeight(height: string | undefined) {
		this.height = height;
	}

	public getRowGap(): string | undefined {
		return this.rowGap;
	}

	public setRowGap(rowGap: string | undefined) {
		this.rowGap = rowGap;
	}

	public getDisplay(): string | undefined {
		return this.display;
	}

	public setDisplay(display: string | undefined) {
		this.display = display;
	}

	public getFontSize(): string | undefined {
		return this.fontSize;
	}

	public setFontSize(fontSize: string | undefined) {
		this.fontSize = fontSize;
	}

	public getTextAlign(): string | undefined {
		return this.textAlign;
	}

	public setTextAlign(textAlign: string | undefined): void {
		this.textAlign = textAlign;
	}

	public getMarginTop(): string | undefined {
		return this.marginTop;
	}

	public setMarginTop(marginTop: string | undefined) {
		this.marginTop = marginTop;
	}

	public getColumnGap(): string | undefined {
		return this.columnGap;
	}

	public setColumnGap(columnGap: string | undefined) {
		this.columnGap = columnGap;
	}

	public getPaddingTop(): string | undefined {
		return this.paddingTop;
	}

	public setPaddingTop(paddingTop: string | undefined) {
		this.paddingTop = paddingTop;
	}

	public getMarginLeft(): string | undefined {
		return this.marginLeft;
	}

	public setMarginLeft(marginLeft: string | undefined) {
		this.marginLeft = marginLeft;
	}

	public getAlignItems(): string | undefined {
		return this.alignItems;
	}

	public setAlignItems(alignItems: string | undefined) {
		this.alignItems = alignItems;
	}

	public getFontWeight(): string | undefined {
		return this.fontWeight;
	}

	public setFontWeight(fontWeight: string | undefined) {
		this.fontWeight = fontWeight;
	}

	public getPaddingLeft(): string | undefined {
		return this.paddingLeft;
	}

	public setPaddingLeft(paddingLeft: string | undefined) {
		this.paddingLeft = paddingLeft;
	}

	public getMarginRight(): string | undefined {
		return this.marginRight;
	}

	public setMarginRight(marginRight: string | undefined) {
		this.marginRight = marginRight;
	}

	public getPaddingRight(): string | undefined {
		return this.paddingRight;
	}

	public setPaddingRight(paddingRight: string | undefined) {
		this.paddingRight = paddingRight;
	}

	public getBorderRadius(): string | undefined {
		return this.borderRadius;
	}

	public setBorderRadius(borderRadius: string | undefined) {
		this.borderRadius = borderRadius;
	}

	public getMarginBottom(): string | undefined {
		return this.marginBottom;
	}

	public setMarginBottom(marginBottom: string | undefined) {
		this.marginBottom = marginBottom;
	}

	public getPaddingBottom(): string | undefined {
		return this.paddingBottom;
	}

	public setPaddingBottom(paddingBottom: string | undefined) {
		this.paddingBottom = paddingBottom;
	}

	public getJustifyContent(): string | undefined {
		return this.justifyContent;
	}

	public setJustifyContent(justifyContent: string | undefined) {
		this.justifyContent = justifyContent;
	}

	public getBackgroundColor(): string | undefined {
		return this.backgroundColor;
	}

	public setBackgroundColor(backgroundColor: string | undefined) {
		this.backgroundColor = backgroundColor;
	}

	public loadFromJson(json: Record<string, unknown>) {
		this.color = json.color as string;
		this.width = json.width as string;
		this.height = json.height as string;
		this.rowGap = json.rowGap as string;
		this.display = json.display as string;
		this.fontSize = json.fontSize as string;
		this.textAlign = json.textAlign as string;
		this.marginTop = json.marginTop as string;
		this.columnGap = json.columnGap as string;
		this.paddingTop = json.paddingTop as string;
		this.marginLeft = json.marginLeft as string;
		this.alignItems = json.alignItems as string;
		this.fontWeight = json.fontWeight as string;
		this.paddingLeft = json.paddingLeft as string;
		this.marginRight = json.marginRight as string;
		this.paddingRight = json.paddingRight as string;
		this.borderRadius = json.borderRadius as string;
		this.marginBottom = json.marginBottom as string;
		this.paddingBottom = json.paddingBottom as string;
		this.justifyContent = json.justifyContent as string;
		this.backgroundColor = json.backgroundColor as string;
	}

	public toJSON(): Record<string, unknown> {
		return JSON.parse(
			JSON.stringify({
				color: this.color,
				width: this.width,
				height: this.height,
				rowGap: this.rowGap,
				display: this.display,
				fontSize: this.fontSize,
				textAlign:this.textAlign,
				marginTop: this.marginTop,
				columnGap: this.columnGap,
				paddingTop: this.paddingTop,
				marginLeft: this.marginLeft,
				alignItems: this.alignItems,
				fontWeight: this.fontWeight,
				paddingLeft: this.paddingLeft,
				marginRight: this.marginRight,
				paddingRight: this.paddingRight,
				borderRadius: this.borderRadius,
				marginBottom: this.marginBottom,
				paddingBottom: this.paddingBottom,
				justifyContent: this.justifyContent,
				backgroundColor: this.backgroundColor
			})
		);
	}

	public toString(): string {
		return JSON.stringify(this.toJSON());
	}
}
