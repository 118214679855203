import React from 'react';
import classNames from 'classnames';

import CloseIcon from '../icons/CloseIcon';

import { ModalCloseIcon as ModalCloseIconProps } from '../types/components';

const ModalCloseIcon: React.FunctionComponent<ModalCloseIconProps> = ({
	className,
	closeModal
}) => {
	return (
		<CloseIcon
			onClick={closeModal}
			className={classNames('icon modal__close-icon', className)}
		/>
	);
};

export default ModalCloseIcon;
