import classNames from 'classnames';

import { FormFieldSubGroup as FormFieldSubGroupProps } from '../types/components';

const FormInputSubGroup: React.FunctionComponent<FormFieldSubGroupProps> = ({
	children,
	className
}) => {
	return (
		<div className={classNames('form__field-sub-group', className)}>
			{children}
		</div>
	);
};

export default FormInputSubGroup;
