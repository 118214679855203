import Dto from '../abstract-classes/Dto';
import ForeignKey from '../datatypes/ForeignKey';
import PartialPortalUserPojo from '../pojos/PartialPortalUserPojo';
import ProfileTemplateType from './ProfileTemplateType';
import ProfileTemplateCategory from './ProfileTemplateCategory';

export default class ProfileTemplateDto extends Dto {
	private name: string;
	private is_default: boolean;

	private form: ForeignKey;
	private portal: ForeignKey;
	private creator: PartialPortalUserPojo;

	private type: ProfileTemplateType;
	private category: ProfileTemplateCategory;

	constructor() {
		super();

		this.name = '';

		this.is_default = false;

		this.type = ProfileTemplateType.Form;
		this.category = ProfileTemplateCategory.Portfolio;

		this.form = new ForeignKey();
		this.portal = new ForeignKey();
		this.creator = new PartialPortalUserPojo();
	}

	public getName(): string {
		return this.name;
	}

	public setName(name: string): void {
		this.name = name;
	}

	public isIs_default(): boolean {
		return this.is_default;
	}

	public setIs_default(is_default: boolean): void {
		this.is_default = is_default;
	}

	public getForm(): ForeignKey {
		return this.form;
	}

	public setForm(form: ForeignKey): void {
		this.form = form;
	}

	public getPortal(): ForeignKey {
		return this.portal;
	}

	public setPortal(portal: ForeignKey): void {
		this.portal = portal;
	}

	public getCategory(): ProfileTemplateCategory {
		return this.category;
	}

	public setCategory(template_group: ProfileTemplateCategory): void {
		this.category = template_group;
	}

	public getCreator(): PartialPortalUserPojo {
		return this.creator;
	}

	public setCreator(creator: PartialPortalUserPojo): void {
		this.creator = creator;
	}

	public getType(): ProfileTemplateType {
		return this.type;
	}

	public setType(type: ProfileTemplateType): void {
		this.type = type;
	}

	public loadFromJSON(json: Record<string, unknown>): void {
		this.id = json.id as string;
		this.name = json.name as string;
		this.is_default = json.is_default as boolean;

		this.type = ProfileTemplateType.getValue(json.type as string);
		this.category = ProfileTemplateCategory.getValue(
			json.category as string
		);

		this.form.loadFromJSON(json.form as any);
		this.portal.loadFromJSON(json.portal as any);
		this.creator.loadFromJson(json.creator as any);
		this.created_time.setValue(json.created_time as string);
	}
}
