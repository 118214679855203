export default class EventNamesConstants {
	static readonly Portals = {
		Modal: {
			Create: 'portals.modal.create'
		}
	};

	public static readonly PortalUsers = {
		Modal: {
			Invite: 'portals-users.modal.invite'
		}
	};
	static readonly Touchpoints = {
		Modal: {
			Create: 'touchpoint.modal.create',
			Update: 'touchpoint.modal.update',
			Delete: 'touchpoint.modal.delete',
			Status: 'touchpoint.modal.status'
		},
		Data: {
			Create: 'touchpoint.data.create',
			Update: 'touchpoint.data.update',
			Delete: 'touchpoint.data.delete'
		}
	};

	static readonly Forms = {
		Modal: {
			Create: 'forms.modal.create'
		},
		Data: {
			Create: 'forms.data.create'
		}
	};

	static readonly FormFields = {
		Modal: {
			Create: 'formfields.modal.create',
			Update: 'formfields.modal.update'
		},
		Data: {
			Create: 'formfields.data.create',
			Update: 'formfields.data.update',
			Reorder: 'formfields.data.reorder'
		}
	};

	static readonly Templates = {
		Modal: {
			Create: 'templates.modal.create'
		},
		Data: {
			Create: 'templates.data.create'
		}
	};

	static readonly ProfileTemplates = {
		Data: {
			Update: 'profile-templates.data.update',
			Activate: 'profile-templates.data.activate'
		},
		Modal: {
			Create: 'profile-templates.modal.create'
		}
	};

	static readonly ProfileTemplateNode = {
		Button: {
			Action: {
				DisplayLeadForm: 'profile-template-node.action.display-lead-form'
			}
		}
	};

	static readonly TouchpointTemplateNode = {
		Data: {
			Update: 'touchpoint-template-node.data.update'
		}
	};
}
