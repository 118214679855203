import { forwardRef } from 'react';
import { Box as BoxProps } from '../types/components';

const Box = forwardRef<HTMLDivElement, BoxProps>(
	({ onClick, onScroll, children, ...others }, ref) => {
		return (
			<div {...others} ref={ref} onClick={onClick} onScroll={onScroll}>
				{children}
			</div>
		);
	}
);

export default Box;
