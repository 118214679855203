import classNames from 'classnames';

import { ModalFooter as ModalFooterProps } from '../types/components';

const ModalFooter: React.FunctionComponent<ModalFooterProps> = ({
	children,
	className
}) => {
	return (
		<div className={classNames('modal__footer', className)}>{children}</div>
	);
};

export default ModalFooter;
