import DataModel from '../abstract-classes/DataModel';

export default class FormFieldDataModel extends DataModel {
	private name: string;
	private type: string;
	private label: string;
	private sequence: number;
	private required: boolean;
	private placeholder: string;
	private external_app_field: string;

	constructor() {
		super();

		this.type = '';
		this.name = '';
		this.label = '';
		this.sequence = 1;
		this.required = false;
		this.placeholder = '';
		this.external_app_field = '';
	}

	public getName(): string {
		return this.name;
	}

	public setName(name: string): void {
		this.name = name;
	}

	public getLabel(): string {
		return this.label;
	}

	public setLabel(label: string): void {
		this.label = label;
	}

	public getSequence(): number {
		return this.sequence;
	}

	public setSequence(sequence: number): void {
		this.sequence = sequence;
	}

	public isRequired(): boolean {
		return this.required;
	}

	public setRequired(required: boolean): void {
		this.required = required;
	}

	public getPlaceholder(): string {
		return this.placeholder;
	}

	public setPlaceholder(placeholder: string): void {
		this.placeholder = placeholder;
	}

	public getExternal_app_field(): string {
		return this.external_app_field;
	}

	public setExternal_app_field(external_app_field: string): void {
		this.external_app_field = external_app_field;
	}

	public getType(): string {
		return this.type;
	}

	public setType(type: string): void {
		this.type = type;
	}

	public getCreatePayload(): Record<string, unknown> {
		return {
			name: this.name,
			type: this.type,
			label: this.label,
			required: this.required,
			placeholder: this.placeholder,
			...(this.external_app_field
				? { external_app_field: this.external_app_field }
				: {})
		};
	}

	public getUpdatePayload(): Record<string, unknown> {
		return {
			label: this.label,
			required: this.required,
			placeholder: this.placeholder,
			...(this.external_app_field
				? { external_app_field: this.external_app_field }
				: {})
		};
	}

	public getUpdateFormFieldSequencePayload(): Record<string, unknown> {
		return {
			id: this.id,
			sequence: this.sequence
		};
	}
}
