export default class ProfileTemplateNodeProperties {
	private as?: string;
	private name?: string;
	private type?: string;
	private label?: string;
	private value?: string;
	private variant?: string;
	private defaultValue?: string;
	private onClickAction?: string;

	public getAs(): string | undefined {
		return this.as;
	}

	public setAs(as: string): void {
		this.as = as;
	}

	public getName(): string | undefined {
		return this.name;
	}

	public setName(name: string): void {
		this.name = name;
	}

	public getType(): string | undefined {
		return this.type;
	}

	public setType(type: string): void {
		this.type = type;
	}

	public getValue(): string | undefined {
		return this.value;
	}

	public setValue(value: string | undefined): void {
		this.value = value;
	}

	public getLabel(): string | undefined {
		return this.label;
	}

	public setLabel(label: string | undefined): void {
		this.label = label;
	}

	public getVariant(): string | undefined {
		return this.variant;
	}

	public setVariant(variant: string): void {
		this.variant = variant;
	}

	public getDefaultValue(): string | undefined {
		return this.defaultValue;
	}

	public setDefaultValue(defaultValue: string): void {
		this.defaultValue = defaultValue;
	}

	public getOnClickAction(): string | undefined {
		return this.onClickAction;
	}

	public setOnClickAction(onClick: string | undefined): void {
		this.onClickAction = onClick;
	}

	public loadFromJson(json: Record<string, unknown>): void {
		this.as = json.as as string;
		this.name = json.name as string;
		this.type = json.type as string;
		this.label = json.label as string;
		this.value = json.value as string;
		this.variant = json.variant as string;
		this.defaultValue = json.defaultValue as string;
		this.onClickAction = json.onClickAction as string;
	}

	public toJSON(): Record<string, unknown> {
		return JSON.parse(
			JSON.stringify({
				as: this.as,
				name: this.name,
				type: this.type,
				label: this.label,
				value: this.value,
				variant: this.variant,
				defaultValue: this.defaultValue,
				onClickAction: this.onClickAction,
			})
		);
	}

	public toString(): string {
		return JSON.stringify(this.toJSON());
	}
}
