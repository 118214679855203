import { useParams } from 'react-router-dom';

import View from '../../components/View';
import Table from '../../components/Table';
import Loader from '../../components/loader';
import TableRow from '../../components/TableRow';
import TableHead from '../../components/TableHead';
import ViewTitle from '../../components/ViewTitle';
import TableBody from '../../components/TableBody';
import ViewHeader from '../../components/ViewHeader';
import TableHeadCell from '../../components/TableHeadCell';
import TableDataCell from '../../components/TableDataCell';
import TableContainer from '../../components/TableContainer';
import ContainerCenter from '../../components/ContainerCenter';
import LoaderLoadMoreRecords from '../../components/LoaderLoadMoreRecords';

import LeadPojo from '../../pojos/LeadPojo';
import useOnScroll from '../../hooks/useOnScroll';
import LeadTypeEnum from '../../enums/LeadTypeEnum';
import useCRUDMapper from '../../hooks/useCRUDMapper';
import JsonToLeadPojoMapper from '../../mappers/JsonToLeadPojoMapper';

const LeadRow: React.FunctionComponent<{ leadPojo: LeadPojo }> = ({
	leadPojo
}) => {
	return (
		<TableRow>
			<TableDataCell text={leadPojo.getFirst_name()} />
			<TableDataCell text={leadPojo.getLast_name()} />
			<TableDataCell text={leadPojo.getEmail_id()} />
			<TableDataCell text={leadPojo.getOwner().getFull_name()} />
			<TableDataCell text={leadPojo.getCreated_time().toDatetimeString()} />
			<TableDataCell
				text={
					leadPojo.getType() === LeadTypeEnum.External
						? leadPojo.isSynchronized()
							? 'Yes'
							: 'No'
						: 'NA'
				}
			/>
		</TableRow>
	);
};

const DirectoryPage: React.FC = () => {
	const { portal_id } = useParams();
	const { data, totalRecords, isLoading, loadMoreRecords, isLoadingNextPage } =
		useCRUDMapper<LeadPojo>({
			mapper: JsonToLeadPojoMapper.map,
			url: `/portals/${portal_id}/leads`
		});

	const { onScrollToBottom } = useOnScroll({
		cb: loadMoreRecords
	});

	if (isLoading) {
		return (
			<ContainerCenter>
				<Loader />
			</ContainerCenter>
		);
	} else {
		return (
			<View>
				<ViewHeader>
					<ViewTitle text='Directory' />
				</ViewHeader>
				<TableContainer onScroll={onScrollToBottom}>
					<Table>
						<TableHead>
							<TableRow>
								<TableHeadCell text='First Name' width='15%' />
								<TableHeadCell text='Last Name' width='15%' />
								<TableHeadCell text='Email Address' width='25%' />
								<TableHeadCell text='Owner' width='15%' />
								<TableHeadCell text='Created On' width='15%' />
								<TableHeadCell text='Synchronized' width='15%' />
							</TableRow>
						</TableHead>
						<TableBody>
							{totalRecords ? (
								data.map((item) => (
									<LeadRow leadPojo={item} key={item.getId()} />
								))
							) : (
								<TableRow>
									<TableDataCell colSpan={6} text='No entries in the table.' />
								</TableRow>
							)}
						</TableBody>
					</Table>
					{isLoadingNextPage && <LoaderLoadMoreRecords />}
				</TableContainer>
			</View>
		);
	}
};

export default DirectoryPage;
