import Dto from '../abstract-classes/Dto';
import FileMeta from '../datatypes/FileMeta';
import ForeignKey from '../datatypes/ForeignKey';
import SocialUrls from '../datatypes/SocialUrls';
import PhoneNumber from '../datatypes/PhoneNumber';
import PartialPortalUserDto from './PartialPortalUserDto';
import UserInformationStatusEnum from '../enums/UserInformationStatusEnum';

export default class UserInformationDto extends Dto {
	private role: string;
	private about: string;
	private company: string;
	private email_id: string;
	private last_name: string;
	private first_name: string;

	private image: FileMeta;
	private portal: ForeignKey;
	private template: ForeignKey;
	private social_urls: SocialUrls;
	private phone_number: PhoneNumber;
	private user: PartialPortalUserDto;

	private status: UserInformationStatusEnum;

	constructor() {
		super();

		this.id = '';
		this.role = '';
		this.about = '';
		this.company = '';
		this.email_id = '';
		this.last_name = '';
		this.first_name = '';

		this.image = new FileMeta();
		this.portal = new ForeignKey();
		this.template = new ForeignKey();
		this.social_urls = new SocialUrls();
		this.phone_number = new PhoneNumber();
		this.user = new PartialPortalUserDto();

		this.status = UserInformationStatusEnum.Inactive;
	}

	public getRole(): string {
		return this.role;
	}

	public setRole(role: string): void {
		this.role = role;
	}

	public getAbout(): string {
		return this.about;
	}

	public setAbout(about: string): void {
		this.about = about;
	}

	public getCompany(): string {
		return this.company;
	}

	public setCompany(company: string): void {
		this.company = company;
	}

	public getEmail_id(): string {
		return this.email_id;
	}

	public setEmail_id(email_id: string): void {
		this.email_id = email_id;
	}

	public getLast_name(): string {
		return this.last_name;
	}

	public setLast_name(last_name: string): void {
		this.last_name = last_name;
	}

	public getFirst_name(): string {
		return this.first_name;
	}

	public setFirst_name(first_name: string): void {
		this.first_name = first_name;
	}

	public getImage(): FileMeta {
		return this.image;
	}

	public setImage(image: FileMeta): void {
		this.image = image;
	}

	public getPortal(): ForeignKey {
		return this.portal;
	}

	public setPortal(portal: ForeignKey): void {
		this.portal = portal;
	}

	public getTemplate(): ForeignKey {
		return this.template;
	}

	public setTemplate(template: ForeignKey): void {
		this.template = template;
	}

	public getSocial_urls(): SocialUrls {
		return this.social_urls;
	}

	public setSocial_urls(social_urls: SocialUrls): void {
		this.social_urls = social_urls;
	}

	public getPhone_number(): PhoneNumber {
		return this.phone_number;
	}

	public setPhone_number(phone_number: PhoneNumber): void {
		this.phone_number = phone_number;
	}

	public getUser(): PartialPortalUserDto {
		return this.user;
	}

	public setUser(user: PartialPortalUserDto): void {
		this.user = user;
	}

	public getStatus(): UserInformationStatusEnum {
		return this.status;
	}

	public setStatus(status: UserInformationStatusEnum): void {
		this.status = status;
	}

	public loadFromJSON(json: Record<string, unknown>): void {
		this.id = json.id as string;
		this.role = json.role as string;
		this.about = json.about as string;
		this.company = json.company as string;
		this.email_id = json.email_id as string;
		this.last_name = json.last_name as string;
		this.first_name = json.first_name as string;

		this.image.loadFromJSON(json.image as Record<string, unknown>);
		this.image.setDownloadPath('/user-informations/');

		this.user.loadFromJSON(json.user as any);
		this.portal.loadFromJSON(json.portal as any);
		this.template.loadFromJSON(json.template as any);
		this.social_urls.loadFromJson(json.social_urls as any);
		this.created_time.setValue(json.created_time as string);
		this.phone_number.loadFromJson(json.phone_number as any);

		this.status = UserInformationStatusEnum.getValue(json.status as string);
	}
}
