import classNames from 'classnames';

import { View as ViewProps } from '../types/components';

const View: React.FunctionComponent<ViewProps> = ({
	onScroll,
	children,
	className
}) => {
	return (
		<div onScroll={onScroll} className={classNames('view', className)}>
			{children}
		</div>
	);
};

export default View;
