import classNames from 'classnames';

type ViewActionsProps = {
	className?: string;
	children?: React.ReactNode;
};

const ViewActions = ({ children, className }: ViewActionsProps) => {
	return (
		<div className={classNames('view--actions', className)}>{children}</div>
	);
};

export default ViewActions;
