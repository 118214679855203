import moment from 'moment';

export default class Datetime {
	private value: string;

	constructor() {
		this.value = '';
	}
	public setValue(value: string): void {
		this.value = value;
	}
	public toDateString(): string {
		return moment(this.value, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY');
	}
	public toDatetimeString(): string {
		return moment(this.value, 'YYYY-MM-DD HH:mm:ss').format(
			'DD MMM YYYY, HH:mm'
		);
	}
}
