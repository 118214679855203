import { useMemo } from 'react';

import ProfileTemplateNode from './ProfileTemplateNode';
import UserInformationDto from '../dtos/UserInformationDto';

export type ProfileTemplateImageNodeProps = {
	disabled?: boolean;
	userInformationPojo: UserInformationDto;
	profileTemplateNode: ProfileTemplateNode;
};

const ProfileTemplateContainerNode: React.FC<ProfileTemplateImageNodeProps> = ({
	children,
	profileTemplateNode
}) => {
	const style = useMemo<React.CSSProperties>(
		() => profileTemplateNode.getStyles().toJSON(),
		[profileTemplateNode]
	);

	return <div style={style}>{children}</div>;
};

export default ProfileTemplateContainerNode;
