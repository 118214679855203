import Dto from '../abstract-classes/Dto';
import ForeignKey from '../datatypes/ForeignKey';
import FormFieldType from '../enums/FormFieldType';
import ExternalAppField from '../external-app/ExternalAppField';
import FormFieldCategoryEnum from '../enums/FormFieldCategoryEnum';

export default class FormFieldDto extends Dto {
	private name: string;
	private label: string;
	private sequence: number;
	private required: boolean;
	private placeholder: string;

	private type: FormFieldType;
	private category: FormFieldCategoryEnum;

	private form: ForeignKey;
	private external_app_field: ExternalAppField;

	constructor() {
		super();

		this.name = '';
		this.label = '';
		this.sequence = 0;
		this.placeholder = '';
		this.required = false;

		this.type = FormFieldType.Text;
		this.category = FormFieldCategoryEnum.System;

		this.form = new ForeignKey();
		this.external_app_field = new ExternalAppField();
	}

	public getName(): string {
		return this.name;
	}

	public setName(name: string): void {
		this.name = name;
	}

	public getLabel(): string {
		return this.label;
	}

	public setLabel(label: string): void {
		this.label = label;
	}

	public getSequence(): number {
		return this.sequence;
	}

	public setSequence(sequence: number): void {
		this.sequence = sequence;
	}

	public isRequired(): boolean {
		return this.required;
	}

	public setRequired(required: boolean): void {
		this.required = required;
	}

	public getPlaceholder(): string {
		return this.placeholder;
	}

	public setPlaceholder(placeholder: string): void {
		this.placeholder = placeholder;
	}

	public getType(): FormFieldType {
		return this.type;
	}

	public setType(type: FormFieldType): void {
		this.type = type;
	}

	public getCategory(): FormFieldCategoryEnum {
		return this.category;
	}

	public setCategory(category: FormFieldCategoryEnum): void {
		this.category = category;
	}

	public getForm(): ForeignKey {
		return this.form;
	}

	public setForm(form: ForeignKey): void {
		this.form = form;
	}

	public getExternal_app_field(): ExternalAppField {
		return this.external_app_field;
	}

	public setExternal_app_field(external_app_field: ExternalAppField): void {
		this.external_app_field = external_app_field;
	}

	public loadFromJSON(json: Record<string, unknown>): void {
		this.id = json.id as string;
		this.name = json.name as string;
		this.label = json.label as string;
		this.sequence = json.sequence as number;
		this.required = json.required as boolean;
		this.placeholder = json.placeholder as string;

		this.type = FormFieldType.getValue(json.type as string);
		this.category = FormFieldCategoryEnum.getValue(json.category as string);

		this.form.loadFromJSON(json.form as Record<string, unknown>);
		this.created_time.setValue(json.created_time as string);
		this.external_app_field.loadFromJson(
			json.external_app_field as Record<string, unknown>
		);
	}
}
