import TouchpointTemplateNode from './TouchpointTemplateNode';

export default class JSONToTouchpointTemplateNodeMapper {
	public static map(
		nodesJson: Array<Record<string, unknown>>
	): Array<TouchpointTemplateNode> {
		const nodes: Array<TouchpointTemplateNode> = [];
		for (const nodeJson of nodesJson) {
			const touchpointTemplateNode = new TouchpointTemplateNode();
			touchpointTemplateNode.loadFromJSON(nodeJson);

			if (nodeJson.nodes && Array.isArray(nodeJson.nodes)) {
				touchpointTemplateNode.setNodes(this.map(nodeJson.nodes));
			}

			nodes.push(touchpointTemplateNode);
		}

		return nodes;
	}
}
