import Enum from '../base-classes/Enum';

export default class ProfileTemplateNodeTypeEnum extends Enum {
	public static Text = new ProfileTemplateNodeTypeEnum('text', 'Text');
	public static Link = new ProfileTemplateNodeTypeEnum('link', 'Link');
	public static Icon = new ProfileTemplateNodeTypeEnum('icon', 'Icon');
	public static Image = new ProfileTemplateNodeTypeEnum('image', 'Image');
	public static Button = new ProfileTemplateNodeTypeEnum('button', 'Button');
	public static Container = new ProfileTemplateNodeTypeEnum(
		'container',
		'Container'
	);

	public static getValue(value: string): ProfileTemplateNodeTypeEnum {
		if (
			this.Container.getSelectOptionValue() === value ||
			this.Container.getMappedValue() === value
		) {
			return this.Container;
		} else if (
			this.Button.getSelectOptionValue() === value ||
			this.Button.getMappedValue() === value
		) {
			return this.Button;
		} else if (
			this.Image.getSelectOptionValue() === value ||
			this.Image.getMappedValue() === value
		) {
			return this.Image;
		} else if (
			this.Icon.getSelectOptionValue() === value ||
			this.Icon.getMappedValue() === value
		) {
			return this.Icon;
		} else if (
			this.Link.getSelectOptionValue() === value ||
			this.Link.getMappedValue() === value
		) {
			return this.Link;
		} else {
			return this.Text;
		}
	}

	public static getAllValues(): Array<ProfileTemplateNodeTypeEnum> {
		return [
			this.Text,
			this.Link,
			this.Icon,
			this.Image,
			this.Button,
			this.Container
		];
	}
}
