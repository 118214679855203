
export default class FormFieldType {
	public static readonly Text = new FormFieldType('text', 'Text');
	public static readonly File = new FormFieldType('file', 'File');
	public static readonly Email = new FormFieldType('email', 'Email');
	public static readonly Textarea = new FormFieldType('textarea', 'Textarea');

	private value: string;
	private mappedValue: string;

	constructor(value: string, mappedValue: string) {
		this.value = value;
		this.mappedValue = mappedValue;
	}

	public getValue(): string {
		return this.value;
	}

	public getMappedValue(): string {
		return this.mappedValue;
	}

	public toJSON(): string {
		return this.mappedValue;
	}

	public toString(): string {
		return this.mappedValue;
	}

	public static getValue(value: string): FormFieldType {
		if (value === this.Textarea.value || value === this.Textarea.mappedValue) {
			return this.Textarea;
		} else if (value === this.Email.value || value === this.Email.mappedValue) {
			return this.Email;
		} else if (value === this.File.value || value === this.File.mappedValue) {
			return this.File;
		} else {
			return this.Text;
		}
	}

	public static getAllValues(): Array<FormFieldType> {
		return [this.Text, this.File, this.Email, this.Textarea];
	}
}
