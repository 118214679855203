import { set, cloneDeep } from 'lodash';
import { useCallback, useState } from 'react';
import { useFormParamType } from '../types/hooks';

const useForm = <FormValuesType extends Object>({
	initialValues
}: useFormParamType<FormValuesType>) => {
	const [errors, __setErrors] = useState<{ [x: string]: any }>({});
	const [isProcessing, setIsProcessing] = useState<boolean>(false);
	const [values, setValues] = useState<FormValuesType>({ ...initialValues });

	const updateProcessing = useCallback((value: boolean) => {
		setIsProcessing(value);
	}, []);

	const onInputChange = useCallback(
		(
			event: React.ChangeEvent<
				HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
			>
		) => {
			const { name, value } = event.target;
			if (event.target instanceof HTMLInputElement) {
				const { type, files } = event.target;

				if (type === 'file') {
					if (files && files.length) {
						setValues((prev) => {
							const temp = cloneDeep(prev);

							return set(temp, name, value);
						});
					} else {
						setValues((prev) => {
							const temp = cloneDeep(prev);

							return set(temp, name, value);
						});
					}
				} else {
					setValues((prev) => {
						const temp = cloneDeep(prev);

						return set(temp, name, value);
					});
				}
			} else if (event.target instanceof HTMLTextAreaElement) {
				setValues((prev) => {
					const temp = cloneDeep(prev);

					return set(temp, name, value);
				});
			} else if (event.target instanceof HTMLSelectElement) {
				setValues((prev) => {
					const temp = cloneDeep(prev);

					return set(temp, name, value);
				});
			}

			__setErrors((prev) => {
				const temp = cloneDeep(prev);

				return set(temp, name, '');
			});
		},
		[]
	);

	const setMultipleValues = useCallback((values: FormValuesType) => {
		setValues(values);
	}, []);

	const setValue = useCallback((key: string, value: any) => {
		setValues((prev) => {
			const temp = cloneDeep(prev);
			return set(temp, key, value);
		});
	}, []);

	const setError = useCallback((key: string, value: string) => {
		__setErrors((prev) => {
			const temp = cloneDeep(prev);

			return set(temp, key, value);
		});
	}, []);

	const setErrors = useCallback((errors: Record<string, any>) => {
		__setErrors(errors);
	}, []);

	const clearErrors = useCallback(() => {
		__setErrors({});
	}, []);
	return {
		values,
		errors,
		setValue,
		setError,
		setErrors,
		clearErrors,
		isProcessing,
		onInputChange,
		updateProcessing,
		setMultipleValues
	};
};

export default useForm;
