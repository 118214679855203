export default class ProfileTemplateType {
	public static readonly Form = new ProfileTemplateType('form', 'Form');
	public static readonly FormWithView = new ProfileTemplateType(
		'form and view',
		'Form & View'
	);

	private value: string;
	private mappedValue: string;

	constructor(value: string, mappedValue: string) {
		this.value = value;
		this.mappedValue = mappedValue;
	}

	public getValue(): string {
		return this.value;
	}

	public getMappedValue(): string {
		return this.mappedValue;
	}

	public toJSON(): string {
		return this.mappedValue;
	}

	public toString(): string {
		return this.mappedValue;
	}

	public static getValue(value:  string): ProfileTemplateType {
		if (
			value === this.FormWithView.value ||
			value === this.FormWithView.mappedValue
		) {
			return this.FormWithView;
		} else {
			return this.Form;
		}
	}

	public static getAllValues(): Array<ProfileTemplateType> {
		return [this.Form, this.FormWithView];
	}
}
