import classNames from 'classnames';
import { ComponentProps as ContainerProfileCategoryBodyProps } from '../types/components';

const ContainerProfileCategoryBody: React.FC<
	ContainerProfileCategoryBodyProps
> = ({ children, className }) => {
	return (
		<div
			className={classNames('container--profile__category__body', className)}>
			{children}
		</div>
	);
};

export default ContainerProfileCategoryBody;
