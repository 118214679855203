import { forwardRef } from 'react';
import classNames from 'classnames';

import { Card as CardProps } from '../types/components';

const Card = forwardRef<HTMLDivElement, CardProps>(
	({ onClick, children, className }, ref) => {
		return (
			<div
				onClick={onClick}
				className={classNames('card', className)}
				ref={ref}>
				{children}
			</div>
		);
	}
);

export default Card;
