import { useMemo } from 'react';

import TouchpointTemplateNode from './TouchpointTemplateNode';
import TouchpointTemplateTextNodeProperties from './TouchpointTemplateTextNodeProperties';

type TouchpointTemplateContainerNodeProps = {
	touchpointTemplateNode: TouchpointTemplateNode;
};

export default function TouchpointTemplateTextNode({
	touchpointTemplateNode
}: TouchpointTemplateContainerNodeProps) {
	const properties = useMemo(() => {
		const properties = new TouchpointTemplateTextNodeProperties();
		properties.loadFromJSON(
			touchpointTemplateNode.getProperties() as Record<string, string>
		);
		return properties;
	}, [touchpointTemplateNode]);
	return (
		<p style={touchpointTemplateNode.getStyles().toJSON()}>
			{properties.getValue()}
		</p>
	);
}
