import classNames from 'classnames';

import { FormFieldError as FormFieldErrorProps } from '../types/components';

const FormFieldError: React.FunctionComponent<FormFieldErrorProps> = ({
	error,

	className
}) => {
	return <p className={classNames('form__field-error', className)}>{error}</p>;
};

export default FormFieldError;
