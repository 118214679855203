import { MappedValue } from '../datatypes';

export default class UserConstants {
	static readonly Roles = {
		User: new MappedValue('user', 'User'),
		Admin: new MappedValue('admin', 'Admin')
	};


	static readonly getRoleByValue = (value: string): MappedValue => {
		for (const role of Object.values(this.Roles)) {
			if (role.getValue() === value) {
				return role;
			}
		}
		return this.Roles.User;
	};
}
