import Util from '../base-classes/Util';
import ConnectedAppPojo from '../pojos/ConnectedAppPojo';
import RequestMethodEnum from '../enums/RequestMethodEnum';
import ExternalAppModule from '../external-app/ExternalAppModule';
import ExternalAppField from '../external-app/ExternalAppField';
import LeadStorageLocationEnum from '../enums/LeadStorageLocationEnum';
import JsonToConnectedAppPojoMapper from '../mappers/JsonToConnectedAppPojoMapper';
import JsonToExternalAppFieldPojoMapper from '../mappers/JsonToExternalAppFieldPojoMapper';
import JsonToExternalAppModulePojoMapper from '../mappers/JsonToExternalAppModulePojoMapper';

import { ConnectedAppUtilParams } from '../types/params';

export default class ConnectedAppUtil extends Util {
	private portal_id: string;

	constructor(portal_id: string, controller?: AbortController) {
		super(controller);

		this.portal_id = portal_id;
	}

	public async getPagedConnectedApps(
		params: ConnectedAppUtilParams.GetPagedConnectedApps
	): Promise<Array<ConnectedAppPojo>> {
		const { page, perPage } = params;

		const apiRequestHandler = this.buildAPIRequestHandler();
		apiRequestHandler.setMethod(RequestMethodEnum.Get);
		apiRequestHandler.setUrl(`/api/portals/${this.portal_id}/connected-apps`);
		apiRequestHandler.addParam('page', page);
		apiRequestHandler.addParam('perPage', perPage);

		if (params.name) {
			const name = LeadStorageLocationEnum.getValue(
				params.name
			).getSelectOptionValue();

			apiRequestHandler.addParam('name', name);
		}

		return apiRequestHandler
			.execute()
			.then((response) =>
				(
					(response.getData() as Record<string, unknown>).data as Array<
						Record<string, unknown>
					>
				).map(JsonToConnectedAppPojoMapper.map)
			);
	}
	public async getConnectedApp(appName: string): Promise<ConnectedAppPojo> {
		const apiRequestHandler = this.buildAPIRequestHandler();
		apiRequestHandler.setMethod(RequestMethodEnum.Get);
		apiRequestHandler.setUrl(
			`/api/portals/${
				this.portal_id
			}/connected-apps/${LeadStorageLocationEnum.getValue(
				appName
			).getSelectOptionValue()}`
		);

		return apiRequestHandler
			.execute()
			.then((response) =>
				JsonToConnectedAppPojoMapper.map(
					(response.getData() as Record<string, unknown>).data as Record<
						string,
						unknown
					>
				)
			);
	}

	public async getConnectedAppModules(
		appName: string
	): Promise<Array<ExternalAppModule>> {
		const apiRequestHandler = this.buildAPIRequestHandler();
		apiRequestHandler.setMethod(RequestMethodEnum.Get);
		apiRequestHandler.setUrl(
			`/api/portals/${
				this.portal_id
			}/connected-apps/${LeadStorageLocationEnum.getValue(
				appName
			).getSelectOptionValue()}/modules`
		);

		return apiRequestHandler
			.execute()
			.then((response) =>
				(
					(response.getData() as Record<string, unknown>).data as Array<
						Record<string, unknown>
					>
				).map(JsonToExternalAppModulePojoMapper.map)
			);
	}

	public async getConnectedAppFields(
		appName: string,
		moduleApiName: string
	): Promise<Array<ExternalAppField>> {
		const apiRequestHandler = this.buildAPIRequestHandler();
		apiRequestHandler.setMethod(RequestMethodEnum.Get);
		apiRequestHandler.setUrl(
			`/api/portals/${
				this.portal_id
			}/connected-apps/${LeadStorageLocationEnum.getValue(
				appName
			).getSelectOptionValue()}/modules/${moduleApiName}/fields`
		);

		return apiRequestHandler
			.execute()
			.then((response) =>
				(
					(response.getData() as Record<string, unknown>).data as Array<
						Record<string, unknown>
					>
				).map(JsonToExternalAppFieldPojoMapper.map)
			);
	}

	public static getInstance(
		portal_id: string,
		controller?: AbortController
	): ConnectedAppUtil {
		return new ConnectedAppUtil(portal_id, controller);
	}
}
