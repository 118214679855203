import { useOutletContext } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

import Loader from '../../components/loader';
import Button from '../../components/Button';
import Typography from '../../components/Typography';
import FormFieldGroup from '../../components/FormFieldGroup';
import FormFieldLabel from '../../components/FormFieldLabel';
import ContainerCenter from '../../components/ContainerCenter';
import FormFieldTextInput from '../../components/FormFieldTextInput';
import CardProfileTemplate from '../../components/CardProfileTemplate';
import FormFieldTextareaInput from '../../components/FormFieldTextareaInput';
import CardProfileTemplateMessage from '../../components/CardProfileTemplateMessage';
import ContainerProfileCategoryBody from '../../components/ContainerProfileCategoryBody';
import ContainerProfileCategoryHeader from '../../components/ContainerProfileCategoryHeader';

import ToastUtil from '../../utils/ToastUtil';
import FormFieldDto from '../../dtos/FormFieldDto';
import FormFieldUtil from '../../utils/FormFieldUtil';
import { EventEmitterInstance } from '../../instances';
import APIRequestUtil from '../../utils/APIRequestUtil';
import FormFieldType from '../../enums/FormFieldType';
import ProfileTemplateDto from '../../profile-templates/ProfileTemplateDto';
import UserInformationUtil from '../../utils/UserInformationUtil';
import EventNamesConstants from '../../constants/EventNamesConstants';
import UserInformationDataModel from '../../model/UserInformationDataModel';

import { ProfilePickerLayoutContext } from '../../types/context';

const ProfileTemplateFormCard = ({
	profileTemplateDto
}: {
	profileTemplateDto: ProfileTemplateDto;
}) => {
	const { portalUserPojo, currentProfileTemplatePojo } =
		useOutletContext<ProfilePickerLayoutContext>();

	const [isLoading, setIsLoading] = useState(true);
	const [isTemplateActivating, setIsTemplateActivating] = useState(false);

	const [formFields, setFormFields] = useState<Array<FormFieldDto>>([]);

	useEffect(() => {
		setIsLoading(true);

		FormFieldUtil.getInstance(
			profileTemplateDto.getPortal().getId(),
			profileTemplateDto.getForm().getId()
		)
			.getFormFields()
			.then((formFields) => {
				setFormFields(formFields);
				setIsLoading(false);
			})
			.catch(APIRequestUtil.handleRequestFailure);
	}, [profileTemplateDto]);

	const dummyFormFieldOnChange = useCallback((name: string, value: string) => {
		// This is a dummy function
	}, []);

	const dummyButtonClick = useCallback(() => {
		// This is a dummy function
	}, []);

	const activateProfileTemplate = useCallback(() => {
		setIsTemplateActivating(true);

		const userInformationDataModel = new UserInformationDataModel();
		userInformationDataModel.setUser_id(portalUserPojo.getId());
		userInformationDataModel.setProfile_template(profileTemplateDto.getName());
		userInformationDataModel.setPortal_id(
			profileTemplateDto.getPortal().getId()
		);

		UserInformationUtil.updateUserInformation({ userInformationDataModel })
			.then(() => {
				EventEmitterInstance.emit(
					EventNamesConstants.ProfileTemplates.Data.Activate,
					profileTemplateDto
				);
				ToastUtil.makeSuccessToast('Template has been activated successfully.');
			})
			.catch(APIRequestUtil.handleRequestFailure)
			.finally(() => {
				setIsTemplateActivating(false);
			});
	}, [portalUserPojo, profileTemplateDto]);

	if (isLoading) {
		return (
			<ContainerCenter>
				<Loader />
			</ContainerCenter>
		);
	} else {
		return (
			<>
				<ContainerProfileCategoryHeader>
					<Typography
						variant='h6'
						fontSize={22}
						text='Lead Gen'
						fontWeight={600}
						marginRight='auto'
					/>

					<Button
						label='Activate'
						variant='primary'
						isLoading={isTemplateActivating}
						onClick={activateProfileTemplate}
						disabled={
							currentProfileTemplatePojo.getId() === profileTemplateDto.getId()
						}
					/>
				</ContainerProfileCategoryHeader>
				<ContainerProfileCategoryBody>
					<CardProfileTemplate>
						<Typography
							variant='h6'
							fontSize={18}
							fontWeight={600}
							marginBottom='15px'
							text='Share your details'
						/>
						{formFields.map((item) => (
							<FormFieldGroup key={item.getId()}>
								<FormFieldLabel
									label={item.getLabel() + (item.isRequired() ? '*' : '')}
									htmlFor={item.getName()}
								/>
								{item.getType() === FormFieldType.Textarea ? (
									<FormFieldTextareaInput
										value=''
										disabled={true}
										name={item.getName()}
										onChange={dummyFormFieldOnChange}
										placeholder={item.getPlaceholder()}
									/>
								) : (
									<FormFieldTextInput
										value=''
										disabled={true}
										name={item.getName()}
										onChange={dummyFormFieldOnChange}
										placeholder={item.getPlaceholder()}
									/>
								)}
							</FormFieldGroup>
						))}
						<ContainerCenter>
							<Button
								label='Save Details'
								disabled={true}
								variant='primary'
								onClick={dummyButtonClick}
							/>
						</ContainerCenter>
					</CardProfileTemplate>
				</ContainerProfileCategoryBody>
			</>
		);
	}
};

const PortalUserProfileTemplateContainer: React.FC<{
	profileTemplateDto: ProfileTemplateDto | null;
}> = ({ profileTemplateDto }) => {
	if (profileTemplateDto) {
		return <ProfileTemplateFormCard profileTemplateDto={profileTemplateDto} />;
	} else {
		return (
			<>
				<ContainerProfileCategoryHeader>
					<Typography
						variant='h6'
						fontSize={22}
						text='Lead Gen'
						fontWeight={600}
						marginRight='auto'
					/>
				</ContainerProfileCategoryHeader>
				<ContainerProfileCategoryBody>
					<ContainerCenter>
						<CardProfileTemplateMessage>
							<Typography
								variant='h6'
								fontSize={16}
								fontWeight={500}
								textAlign='center'
								wordBreak='break-word'
								text='Kindly contact your administrator to configure this template.'
							/>
						</CardProfileTemplateMessage>
					</ContainerCenter>
				</ContainerProfileCategoryBody>
			</>
		);
	}
};

export default PortalUserProfileTemplateContainer;
