import React from 'react';
import classNames from 'classnames';

type TableActionsCellProps = {
	colSpan?: number;
	className?: string;
};

const TableActionsCell: React.FC<TableActionsCellProps> = ({
	colSpan,
	children,
	className
}) => {
	return (
		<td colSpan={colSpan} className={classNames('table__cell', className)}>
			{children}
		</td>
	);
};

export default TableActionsCell
