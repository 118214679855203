import { MappedValue } from '../datatypes';
import { UserConstants } from '../constants';

import { ApiResponseJson } from '../types/json';

export default class UserPojo {
	private id: string;
	private email_id: string;
	private last_name: string;
	private first_name: string;
	private created_time: string;

	private role: MappedValue;

	constructor() {
		this.id = '';
		this.email_id = '';
		this.last_name = '';
		this.first_name = '';
		this.created_time = '';
		this.role = UserConstants.Roles.User;
	}

	public getId(): string {
		return this.id;
	}

	public setId(id: string): void {
		this.id = id;
	}

	public getEmail_id(): string {
		return this.email_id;
	}

	public setEmail_id(email_id: string): void {
		this.email_id = email_id;
	}

	public getLast_name(): string {
		return this.last_name;
	}

	public setLast_name(last_name: string): void {
		this.last_name = last_name;
	}

	public getFirst_name(): string {
		return this.first_name;
	}

	public setFirst_name(first_name: string): void {
		this.first_name = first_name;
	}

	public getCreated_time(): string {
		return this.created_time;
	}

	public setCreated_time(created_time: string): void {
		this.created_time = created_time;
	}

	public getRole(): MappedValue {
		return this.role;
	}

	public setRole(role: MappedValue): void {
		this.role = role;
	}

	public loadFromJson = (json: ApiResponseJson.UserJson) => {
		this.id = json.id;
		this.email_id = json.email_id;
		this.last_name = json.last_name;
		this.first_name = json.first_name;
		this.created_time = json.created_time;

		this.role = UserConstants.getRoleByValue(json.role);
	};
}
