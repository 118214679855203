import RequestHandler from '../handlers/RequestHandler';

export default class Util {
	private controller?: AbortController;

	constructor(controller?: AbortController) {
		this.controller = controller;
	}

	protected buildAPIRequestHandler(): RequestHandler {
		const apiRequestHandler = new RequestHandler();

		if (this.controller) {
			apiRequestHandler.setAbortController(this.controller);
		}

		return apiRequestHandler;
	}
}
