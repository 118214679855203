export default class TouchpointTemplateStatus {
	public static readonly Drafted = new TouchpointTemplateStatus(
		'drafted',
		'Drafted'
	);
	public static readonly Published = new TouchpointTemplateStatus(
		'published',
		'published'
	);

	private value: string;
	private mappedValue: string;

	constructor(value: string, mappedValue: string) {
		this.value = value;
		this.mappedValue = mappedValue;
	}

	public getValue(): string {
		return this.value;
	}

	public getMappedValue(): string {
		return this.mappedValue;
	}

	public toJSON(): string {
		return this.mappedValue;
	}

	public toString(): string {
		return this.mappedValue;
	}

	public static getValue(value: string): TouchpointTemplateStatus {
		if (
			value === this.Published.value ||
			value === this.Published.mappedValue
		) {
			return this.Published;
		} else {
			return this.Drafted;
		}
	}

	public static getAllValues(): Array<TouchpointTemplateStatus> {
		return [this.Drafted, this.Published];
	}
}
