import React from 'react';
import { Typography as TypographyProps } from '../types/components';
import classNames from 'classnames';

const Typography: React.FC<TypographyProps> = ({
	text,
	variant,
	fontSize,
	className,
	textAlign,
	wordBreak,
	fontWeight,
	marginRight,
	marginBottom
}) => {
	return React.createElement(
		variant,
		{
			style: {
				...(fontSize ? { fontSize: fontSize + 'px' } : {}),
				...(fontWeight ? { fontWeight } : {}),
				...{ wordBreak, textAlign, marginRight, marginBottom }
			},
			className: classNames('typography', className)
		},
		text
	);
};

export default Typography;
