export default class PageInfo {
	private page: number;
	private perPage: number;
	private hasMore: boolean;
	private totalPages: number;
	private totalRecords: number;

	constructor() {
		this.page = 0;
		this.perPage = 0;
		this.totalPages = 0;
		this.hasMore = false;
		this.totalRecords = 0;
	}

	public getPage(): number {
		return this.page;
	}

	public setPage(page: number): void {
		this.page = page;
	}

	public getPerPage(): number {
		return this.perPage;
	}

	public setPerPage(perPage: number): void {
		this.perPage = perPage;
	}

	public getHasMore(): boolean {
		return this.hasMore;
	}

	public setHasMore(hasMore: boolean): void {
		this.hasMore = hasMore;
	}

	public getTotalPages(): number {
		return this.totalPages;
	}

	public setTotalPages(totalPages: number): void {
		this.totalPages = totalPages;
	}

	public getTotalRecords(): number {
		return this.totalRecords;
	}

	public setTotalRecords(totalRecords: number): void {
		this.totalRecords = totalRecords;
	}

	public loadFromJson(json: Record<string, unknown>): void {
		this.page = json.page as number;
		this.perPage = json.perPage as number;
		this.hasMore = json.hasMore as boolean;
		this.totalPages = json.totalPages as number;
		this.totalRecords = json.totalRecords as number;
	}
}
