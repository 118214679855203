import classNames from 'classnames';

type ViewHeaderProps = {
	className?: string;
	children?: React.ReactNode;
};

const ViewHeader = ({ children, className }: ViewHeaderProps) => {
	return (
		<div className={classNames('view--header', className)}>{children}</div>
	);
};

export default ViewHeader;
