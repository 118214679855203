import { useCallback, useEffect, useState } from 'react';

import Form from '../../components/Form';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import ModalBody from '../../components/ModalBody';
import ModalTitle from '../../components/ModalTitle';
import ModalHeader from '../../components/ModalHeader';
import ModalFooter from '../../components/ModalFooter';
import FormFieldError from '../../components/FormFieldError';
import FormFieldLabel from '../../components/FormFieldLabel';
import FormFieldGroup from '../../components/FormFieldGroup';
import ModalCloseIcon from '../../components/ModalCloseIcon';
import FormFieldType from '../../enums/FormFieldType';
import ExternalAppField from '../../external-app/ExternalAppField';
import FormFieldTextInput from '../../components/FormFieldTextInput';

import useForm from '../../hooks/useForm';
import useModal from '../../hooks/useModal';
import FormFieldUtil from '../../utils/FormFieldUtil';
import { EventEmitterInstance } from '../../instances';
import ProfileTemplateDto from '../../profile-templates/ProfileTemplateDto';
import FormFieldDataModel from '../../model/FormFieldDataModel';
import EventNamesConstants from '../../constants/EventNamesConstants';

import { CreateFormFieldForm } from '../../types/forms';
import APIRequestUtil from '../../utils/APIRequestUtil';
import FormFieldCheckboxInput from '../../components/FormFieldCheckboxInput';

const initialValues: CreateFormFieldForm.FormValues = {
	label: '',
	name: '',
	placeholder: '',
	required: false,
	type: FormFieldType.Text,
	external_app_field: new ExternalAppField()
};
const CreateFormFieldModal = ({
	profileTemplateDto
}: {
	profileTemplateDto: ProfileTemplateDto;
}) => {
	const { open, openModal, closeModal } = useModal();

	const { values, errors, setError, setValue, clearErrors, setMultipleValues } =
		useForm<CreateFormFieldForm.FormValues>({
			initialValues
		});
	const [isProcessing, setIsProcessing] = useState(false);

	const onTextInputChange = useCallback(
		(name: string, value: string) => {
			setValue(name, value);
			setError(name, '');
		},
		[setValue, setError]
	);

	const onCheckboxInputChange = useCallback(
		(name: string, value: boolean) => {
			setValue(name, value);
			setError(name, '');
		},
		[setValue, setError]
	);

	useEffect(() => {
		const openModalEventListener = (zohoCrmField: ExternalAppField) => {
			openModal();

			setMultipleValues({
				...initialValues,
				name: zohoCrmField.getSelectOptionLabel(),
				label: zohoCrmField.getSelectOptionLabel(),
				external_app_field: zohoCrmField,
				type: FormFieldType.getValue(zohoCrmField.getType())
			});
		};
		const subscription = EventEmitterInstance.addListener(
			EventNamesConstants.FormFields.Modal.Create,
			openModalEventListener
		);

		return () => {
			subscription.remove();
		};
	}, [openModal, setMultipleValues]);

	const createField = useCallback(() => {
		clearErrors();
		if (!values.name) {
			setError('name', 'Name cannot be empty.');
			return;
		}
		if (!values.label) {
			setError('label', 'Label cannot be empty.');
			return;
		}
		setIsProcessing(true);

		const formFieldDataModel = new FormFieldDataModel();
		formFieldDataModel.setName(values.name);
		formFieldDataModel.setLabel(values.label);
		formFieldDataModel.setRequired(values.required);
		formFieldDataModel.setType(values.type.getValue());
		formFieldDataModel.setPlaceholder(values.placeholder);
		formFieldDataModel.setExternal_app_field(
			values.external_app_field.getApi_name()
		);
		FormFieldUtil.getInstance(
			profileTemplateDto.getPortal().getId(),
			profileTemplateDto.getForm().getId()
		)
			.createFormField(formFieldDataModel)
			.then((formFieldDto) => {
				EventEmitterInstance.emit(
					EventNamesConstants.FormFields.Data.Create,
					formFieldDto
				);

				closeModal();
			})
			.catch(APIRequestUtil.handleRequestFailure)
			.finally(() => {
				setIsProcessing(false);
			});
	}, [values, setError, closeModal, clearErrors, profileTemplateDto]);

	return (
		<Modal show={open} position='top'>
			<ModalHeader>
				<ModalTitle title='Create a Field' />
				<ModalCloseIcon closeModal={closeModal} />
			</ModalHeader>
			<ModalBody>
				<Form>
					<FormFieldGroup>
						<FormFieldLabel label='Name' htmlFor='name' />
						<FormFieldTextInput
							id='name'
							name='name'
							value={values.name}
							error={errors.name}
							placeholder='Enter the name'
							onChange={onTextInputChange}
						/>
						<FormFieldError error={errors.name} />
					</FormFieldGroup>
					<FormFieldGroup>
						<FormFieldLabel label='Type' htmlFor='type' />
						<FormFieldTextInput
							id='type'
							name='type'
							disabled={true}
							error={errors.type}
							value={values.type.getMappedValue()}
							onChange={onTextInputChange}
						/>
					</FormFieldGroup>
					<FormFieldGroup>
						<FormFieldLabel label='Label' htmlFor='label' />
						<FormFieldTextInput
							id='label'
							name='label'
							value={values.label}
							error={errors.label}
							placeholder='Enter the label'
							onChange={onTextInputChange}
						/>
						<FormFieldError error={errors.label} />
					</FormFieldGroup>
					<FormFieldGroup>
						<FormFieldLabel label='Placeholder' htmlFor='placeholder' />
						<FormFieldTextInput
							id='placeholder'
							name='placeholder'
							value={values.placeholder}
							error={errors.placeholder}
							placeholder='Enter the placeholder'
							onChange={onTextInputChange}
						/>
						<FormFieldError error={errors.placeholder} />
					</FormFieldGroup>
					<FormFieldGroup>
						<FormFieldLabel
							label='External App Field'
							htmlFor='external_app_field'
						/>
						<FormFieldTextInput
							disabled={true}
							id='external_app_field'
							name='external_app_field'
							error={errors.external_app_field}
							value={values.external_app_field.getSelectOptionLabel()}
							onChange={onTextInputChange}
						/>
					</FormFieldGroup>
					<FormFieldGroup>
						<FormFieldCheckboxInput
							name='required'
							value={values.required}
							label='Is this field required ?'
							onChange={onCheckboxInputChange}
						/>
					</FormFieldGroup>
				</Form>
			</ModalBody>
			<ModalFooter>
				<Button variant='outlined' label='Cancel' onClick={closeModal} />
				<Button
					label='Create'
					variant='primary'
					onClick={createField}
					isLoading={isProcessing}
				/>
			</ModalFooter>
		</Modal>
	);
};

export default CreateFormFieldModal;
