import Util from '../base-classes/Util';
import RequestMethodEnum from '../enums/RequestMethodEnum';
import LeadStorageLocationEnum from '../enums/LeadStorageLocationEnum';

export default class ExternalAppUtil extends Util {
	private portal_id: string;
	private name: LeadStorageLocationEnum;

	constructor(portal_id: string, name: string, controller?: AbortController) {
		super(controller);

		this.portal_id = portal_id;
		this.name = LeadStorageLocationEnum.getValue(name);
	}

	public async connect(redirect_url: string): Promise<string> {
		const apiRequestHandler = this.buildAPIRequestHandler();

		apiRequestHandler.setMethod(RequestMethodEnum.Post);
		apiRequestHandler.setUrl(
			`/api/portals/${
				this.portal_id
			}/external-apps/${this.name.getSelectOptionValue()}/connect`
		);
		apiRequestHandler.setBody({
			redirect_url
		});

		return apiRequestHandler
			.execute()
			.then(
				(response) =>
					(
						(response.getData() as Record<string, unknown>).data as Record<
							string,
							string
						>
					).url
			);
	}

	public static getInstance(
		portal_id: string,
		name: string,
		controller?: AbortController
	): ExternalAppUtil {
		return new ExternalAppUtil(portal_id, name, controller);
	}
}
