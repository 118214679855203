export default class ProfileTemplateCategory {
	public static readonly Portfolio = new ProfileTemplateCategory(
		'portfolio',
		'Portfolio'
	);
	public static readonly LeadGen = new ProfileTemplateCategory(
		'lead-gen',
		'Lead Gen'
	);

	private value: string;
	private mappedValue: string;

	constructor(value: string, mappedValue: string) {
		this.value = value;
		this.mappedValue = mappedValue;
	}

	public getValue(): string {
		return this.value;
	}

	public getMappedValue(): string {
		return this.mappedValue;
	}

	public toJSON(): string {
		return this.mappedValue;
	}

	public toString(): string {
		return this.mappedValue;
	}

	public static getValue(value: string): ProfileTemplateCategory {
		if (value === this.LeadGen.value || value === this.LeadGen.mappedValue) {
			return this.LeadGen;
		} else {
			return this.Portfolio;
		}
	}

	public static getAllValues(): Array<ProfileTemplateCategory> {
		return [this.Portfolio, this.LeadGen];
	}
}
