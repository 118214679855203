import classNames from 'classnames';

import CircularLoaderIcon from '../icons/CircularLoaderIcon';

import { Button as ButtonProps } from '../types/components';

const Button: React.FunctionComponent<ButtonProps> = ({
	type,
	size,
	label,
	variant,
	onClick,
	disabled,
	isLoading,
	className
}): React.ReactElement => {
	return (
		<button
			type={type}
			className={classNames(
				'btn',
				'btn--' + variant,
				size && 'btn--' + size,
				className
			)}
			onClick={onClick}
			disabled={isLoading || disabled}>
			<span className='btn__text'>{label}</span>
			{isLoading && (
				<CircularLoaderIcon
					className={classNames(
						'loader--btn',
						'loader--btn--' + variant,
						size && 'loader--btn--' + size
					)}
				/>
			)}
		</button>
	);
};

export default Button;
