import TouchpointStyles from './touchpoint.module.css';

import cloneDeep from 'lodash/cloneDeep';
import { useOutletContext } from 'react-router-dom';
import { useCallback, useMemo, useState } from 'react';

import Box from '../components/Box';
import Form from '../components/Form';
import Button from '../components/Button';
import Typography from '../components/Typography';
import FormFieldError from '../components/FormFieldError';
import FormFieldGroup from '../components/FormFieldGroup';
import FormFieldLabel from '../components/FormFieldLabel';
import FormFieldTextInput from '../components/FormFieldTextInput';
import TouchpointStatusUpdateModal from './TouchpointStatusUpdateModal';

import useForm from '../hooks/useForm';
import TouchpointDto from './TouchpointDto';
import TouchpointUtil from './TouchpointUtil';
import TouchpointStatus from './TouchpointStatus';
import { EventEmitterInstance } from '../instances';
import APIRequestUtil from '../utils/APIRequestUtil';
import TouchpointDataModel from './TouchpointDataModel';
import TouchpointAuditStatus from './TouchpointAuditStatus';
import PortalUserRole from '../portal-users/PortalUserRole';
import TouchpointTemplateNodes from './TouchpointTemplateNodes';
import EventNamesConstants from '../constants/EventNamesConstants';
import TouchpointTemplateNodeType from './TouchpointTemplateNodeType';
import TouchpointTemplateInputNodeProperties from './TouchpointTemplateInputNodeProperties';

import { PortalLayoutContext } from '../types/context';

export default function TouchpointDetails({
	touchpointDto,
	touchpointNodes,
	updateTouchpointDto
}: {
	touchpointDto: TouchpointDto;
	touchpointNodes: TouchpointTemplateNodes;
	updateTouchpointDto(touchpointDto: TouchpointDto): void;
}) {
	const [isUpdatingTouchpointDetails, setIsUpdatingTouchpointDetails] =
		useState(false);
	const [isTouchpointDetailsUpdated, setIsTouchpointDetailsUpdated] =
		useState(false);

	const [updatingTouchpointAuditStatus, setUpdatingTouchpointAuditStatus] =
		useState('');

	const { portalUserPojo } = useOutletContext<PortalLayoutContext>();

	const { initialValues, inputTouchpointNodes } = useMemo(() => {
		const inputTouchpointNodes = TouchpointUtil.filterTouchpointNodeByType(
			touchpointNodes.getFront().concat(touchpointNodes.getBack()),
			[TouchpointTemplateNodeType.INPUT]
		);

		const initialValues = inputTouchpointNodes.reduce((prev, curr) => {
			const properties = new TouchpointTemplateInputNodeProperties();
			properties.loadFromJSON(curr.getProperties());

			return {
				...prev,
				[curr.getId()]: properties.getValue() || properties.getDefaultValue()
			};
		}, {});

		return {
			initialValues,
			inputTouchpointNodes
		};
	}, [touchpointNodes]);

	const { values, errors, setError, setValue, clearErrors } = useForm<
		Record<string, string>
	>({
		initialValues
	});

	const isMyTouchpoint = useMemo<boolean>(() => {
		if (portalUserPojo.getId() === touchpointDto.getUser().getId()) {
			return true;
		}
		return false;
	}, [portalUserPojo, touchpointDto]);

	const onInputChange = useCallback(
		(key: string, value: string) => {
			setError(key, '');
			setValue(key, value);
			setIsTouchpointDetailsUpdated(true);
			const touchpointNode = TouchpointUtil.findTouchpointNodeById(
				inputTouchpointNodes,
				key
			);

			if (touchpointNode) {
				const properties = new TouchpointTemplateInputNodeProperties();
				properties.loadFromJSON(touchpointNode.getProperties());
				properties.setValue(value);

				touchpointNode.setProperties(properties.toJSON());
				EventEmitterInstance.emit(
					EventNamesConstants.TouchpointTemplateNode.Data.Update,
					cloneDeep(touchpointNode)
				);
			}
		},
		[setError, setValue, inputTouchpointNodes]
	);

	const updateTouchpointDetails = useCallback(() => {
		clearErrors();
		for (const node of inputTouchpointNodes) {
			const properties = new TouchpointTemplateInputNodeProperties();
			properties.loadFromJSON(node.getProperties());

			if (properties.isRequired() && !values[node.getId()]) {
				setError(node.getId(), `${properties.getLabel()} cannot be empty.`);
				return;
			}
		}

		setIsUpdatingTouchpointDetails(true);
		TouchpointUtil.updateTouchpointNodesWithPortal({
			portal_id: touchpointDto.getPortal().getId(),
			touchpoint_id: touchpointDto.getId(),
			touchpointNodes: touchpointNodes
		})
			.then(() => {
				setIsTouchpointDetailsUpdated(false);
			})
			.catch((err) => {
				APIRequestUtil.handleRequestFailure(err);
			})
			.finally(() => {
				setIsUpdatingTouchpointDetails(false);
			});
	}, [
		values,
		setError,
		clearErrors,
		touchpointDto,
		touchpointNodes,
		inputTouchpointNodes
	]);

	const updateTouchpointAuditStatus = useCallback(
		(audit_status: TouchpointAuditStatus) => {
			return function update() {
				setUpdatingTouchpointAuditStatus(audit_status.getValue());

				const touchpointDataModel = new TouchpointDataModel();
				touchpointDataModel.setAudit_status(audit_status);
				touchpointDataModel.setId(touchpointDto.getId());
				touchpointDataModel.setPortal_id(touchpointDto.getPortal().getId());

				TouchpointUtil.updateTouchpointAuditStatusWithPortal({
					touchpointDataModel
				})
					.then((touchpointDto) => {
						updateTouchpointDto(touchpointDto);
					})
					.catch((err) => APIRequestUtil.handleRequestFailure(err))
					.finally(() => {
						setUpdatingTouchpointAuditStatus('');
					});
			};
		},
		[touchpointDto, updateTouchpointDto]
	);

	const updateTouchpointStatus = useCallback(() => {
		EventEmitterInstance.emit(
			EventNamesConstants.Touchpoints.Modal.Status,
			touchpointDto
		);
	}, [touchpointDto]);
	return (
		<>
			<Box className={TouchpointStyles['form-container']}>
				<Box className={TouchpointStyles['form-container__header']}>
					<Typography
						text='Touchpoint Details'
						variant='h6'
						className={TouchpointStyles['form-container__title']}
					/>
				</Box>
				<Box className={TouchpointStyles['form-container__body']}>
					<Form>
						{inputTouchpointNodes.map((node) => {
							const properties = new TouchpointTemplateInputNodeProperties();
							properties.loadFromJSON(node.getProperties());

							return (
								<FormFieldGroup key={'input-' + node.getId()}>
									<FormFieldLabel
										htmlFor={node.getId()}
										label={properties.getLabel()}
									/>
									<FormFieldTextInput
										name={node.getId()}
										onChange={onInputChange}
										value={values[node.getId()]}
										error={errors[node.getId()]}
										disabled={
											!isMyTouchpoint ||
											touchpointDto.getAudit_status().getValue() !==
												TouchpointAuditStatus.Pending.getValue()
										}
										placeholder={properties.getPlaceholder()}
									/>
									<FormFieldError error={errors[node.getId()]} />
								</FormFieldGroup>
							);
						})}
					</Form>
				</Box>
				<Box className={TouchpointStyles['form-container__footer']}>
					{touchpointDto.getAudit_status().getValue() ===
					TouchpointAuditStatus.Approved.getValue() ? (
						<Button
							variant='primary'
							onClick={updateTouchpointStatus}
							label={
								touchpointDto.getStatus().getValue() ===
								TouchpointStatus.Active.getValue()
									? 'De-activate'
									: 'Activate'
							}
						/>
					) : touchpointDto.getAudit_status().getValue() ===
					  TouchpointAuditStatus.Evaluating.getValue() ? (
						portalUserPojo.getRole().getValue() ===
						PortalUserRole.Admin.getValue() ? (
							isMyTouchpoint ? (
								<Button
									variant='primary'
									label='Mark Design As Approved'
									onClick={updateTouchpointAuditStatus(
										TouchpointAuditStatus.Approved
									)}
									disabled={isTouchpointDetailsUpdated}
									isLoading={
										updatingTouchpointAuditStatus ===
										TouchpointAuditStatus.Approved.getValue()
									}
								/>
							) : (
								<>
									<Button
										variant='outlined'
										label='Reject'
										onClick={updateTouchpointAuditStatus(
											TouchpointAuditStatus.Pending
										)}
										disabled={isTouchpointDetailsUpdated}
										isLoading={
											updatingTouchpointAuditStatus ===
											TouchpointAuditStatus.Pending.getValue()
										}
									/>

									<Button
										variant='primary'
										label='Approve'
										onClick={updateTouchpointAuditStatus(
											TouchpointAuditStatus.Approved
										)}
										disabled={isTouchpointDetailsUpdated}
										isLoading={
											updatingTouchpointAuditStatus ===
											TouchpointAuditStatus.Approved.getValue()
										}
									/>
								</>
							)
						) : (
							<Typography
								text='Your touchpoint design is currently being evaluated.'
								variant='p'
								fontSize={14}
							/>
						)
					) : isMyTouchpoint ? (
						<>
							<Button
								label='Save'
								variant='outlined'
								onClick={updateTouchpointDetails}
								disabled={!isTouchpointDetailsUpdated}
								isLoading={isUpdatingTouchpointDetails}
							/>

							{portalUserPojo.getRole().getValue() ===
							PortalUserRole.Admin.getValue() ? (
								<Button
									variant='primary'
									label='Mark Design As Approved'
									onClick={updateTouchpointAuditStatus(
										TouchpointAuditStatus.Approved
									)}
									disabled={isTouchpointDetailsUpdated}
									isLoading={
										updatingTouchpointAuditStatus ===
										TouchpointAuditStatus.Approved.getValue()
									}
								/>
							) : (
								<Button
									label='Submit for approval'
									variant='primary'
									onClick={updateTouchpointAuditStatus(
										TouchpointAuditStatus.Evaluating
									)}
									disabled={isTouchpointDetailsUpdated}
									isLoading={
										updatingTouchpointAuditStatus ===
										TouchpointAuditStatus.Evaluating.getValue()
									}
								/>
							)}
						</>
					) : (
						<Typography
							text='User has not yet submitted for approval.'
							variant='p'
							fontSize={14}
						/>
					)}
				</Box>
			</Box>
			<TouchpointStatusUpdateModal
				touchpointDto={touchpointDto}
				updateTouchpointDto={updateTouchpointDto}
			/>
		</>
	);
}
