import FormDto from '../dtos/FormDto';
import Util from '../base-classes/Util';
import FormTypeEnum from '../enums/FormTypeEnum';
import FormDataModel from '../model/FormDataModel';
import RequestMethodEnum from '../enums/RequestMethodEnum';
import RequestHandler from '../handlers/RequestHandler';
import JsonToFormDtoMapper from '../mappers/JsonToFormDtoMapper';

import { FormUtilParams } from '../types/params';

export default class FormUtil extends Util {
	private portal_id: string;

	constructor(portal_id: string, controller?: AbortController) {
		super(controller);

		this.portal_id = portal_id;
	}

	public async getPagedForms(
		params: FormUtilParams.GetPagedForms
	): Promise<Array<FormDto>> {
		const { name, type, page, perPage, portal_id } = params;

		const apiRequestHandler = new RequestHandler();
		apiRequestHandler.setUrl(`/api/portals/${portal_id}/forms`);
		apiRequestHandler.setMethod(RequestMethodEnum.Get);
		apiRequestHandler.addParam('page', page);
		apiRequestHandler.addParam('perPage', perPage);

		if (name) {
			apiRequestHandler.addParam('name', name);
		}
		if (type) {
			apiRequestHandler.addParam(
				'type',
				FormTypeEnum.getValue(type).getSelectOptionValue()
			);
		}

		return apiRequestHandler
			.execute()
			.then((response) =>
				((response.getData() as Record<string, unknown>).data as Array<Record<string, unknown>>).map(
					JsonToFormDtoMapper.map
				)
			);
	}

	public async getForm(form_id: string): Promise<FormDto> {
		const apiRequestHandler = this.buildAPIRequestHandler();
		apiRequestHandler.setUrl(`/api/portals/${this.portal_id}/forms/${form_id}`);
		apiRequestHandler.setMethod(RequestMethodEnum.Get);

		return apiRequestHandler
			.execute()
			.then((response) =>
				JsonToFormDtoMapper.map(
					(response.getData() as Record<string, unknown>).data as Record<
						string,
						unknown
					>
				)
			);
	}

	public async createForm(formDataModel: FormDataModel): Promise<FormDto> {
		const apiRequestHandler = this.buildAPIRequestHandler();

		apiRequestHandler.setUrl(`/api/portals/${this.portal_id}/forms`);
		apiRequestHandler.setMethod(RequestMethodEnum.Post);
		apiRequestHandler.setBody(formDataModel.getCreatePayload());

		return apiRequestHandler
			.execute()
			.then((response) =>
				JsonToFormDtoMapper.map(
					(response.getData() as Record<string, unknown>).data as Record<
						string,
						unknown
					>
				)
			);
	}

	public async updateForm(formDataModel: FormDataModel): Promise<FormDto> {
		const apiRequestHandler = this.buildAPIRequestHandler();
		apiRequestHandler.setUrl(
			`/api/portals/${this.portal_id}/forms/${formDataModel.getId()}`
		);
		apiRequestHandler.setBody(formDataModel.getUpdatePayload());

		return apiRequestHandler
			.execute()
			.then((response) =>
				JsonToFormDtoMapper.map(
					(response.getData() as Record<string, unknown>).data as Record<
						string,
						unknown
					>
				)
			);
	}

	public static getInstance(
		portal_id: string,
		controller?: AbortController
	): FormUtil {
		return new FormUtil(portal_id, controller);
	}
}
