import uniqBy from 'lodash/uniqBy';
import { useCallback, useEffect, useRef, useState } from 'react';

import { Pojo } from '../types/data';
import { AxiosInstance } from '../instances';
import APIRequestUtil from '../utils/APIRequestUtil';


const useCRUDMapper = <T extends Pojo>({
	url,
	mapper,
	params = {},
	perPage = 200,
	handleRequestFailure,
	hasPagination = true
}: {
	url: string;
	perPage?: number;
	mapper(json: any): T;
	hasPagination?: boolean;
	handleRequestFailure?(err: any): void;
	params?: Record<string, string | number>;
}) => {
	const isAPICallInitiated = useRef<boolean>(false);

	const [page, setPage] = useState<number>(1);
	const [hasMore, setHasMore] = useState(false);
	const [__params, __setParams] = useState<Record<string, string | number>>({
		...params
	});
	const [isLoading, setIsLoading] = useState(true);
	const [data, setData] = useState<Array<T>>([]);
	const [totalRecords, setTotaRecords] = useState<number>(0);
	const [isLoadingNextPage, setIsLoadingNextPage] = useState<boolean>(false);

	useEffect(() => {
		setPage(1)
		setData([])
		setTotaRecords(0)
		setHasMore(false)
		setIsLoading(true);
		setIsLoadingNextPage(false)
	}, [url]);

	useEffect(() => {
		if (isLoading || isLoadingNextPage) {
			isAPICallInitiated.current = true;
			AxiosInstance.API.get(url, {
				params: {
					...(hasPagination
						? {
								page,
								perPage
						  }
						: {}),
					...__params
				}
			})
				.then((response) => {
					const { data, page: pageDetails } = response.data;
					const mappedDataArray = data.map((json: any) =>
						mapper.apply(null, [json])
					);
					if (page === 1) {
						setData(mappedDataArray);
					} else {
						setData((prev) =>
							uniqBy(prev.concat(mappedDataArray), (item) => item.getId())
						);
					}
					if (pageDetails) {
						setHasMore(pageDetails.hasMore);
						setTotaRecords(pageDetails.totalRecords);
					} else {
						setHasMore(false);
						setTotaRecords(mappedDataArray.length);
					}
					setIsLoading(false);
					setIsLoadingNextPage(false);
				})
				.catch((err) => {
					if (handleRequestFailure) {
						handleRequestFailure(err);
					} else {
						APIRequestUtil.handleRequestFailure(err)
					}
				})

				.finally(() => {
					isAPICallInitiated.current = false;
				});
		}
	}, [
		hasPagination,
		isLoading,
		isLoadingNextPage,
		mapper,
		handleRequestFailure,
		page,
		perPage,
		url,
		__params
	]);

	const appendRecord = useCallback((data: T) => {
		setTotaRecords((c) => c + 1);
		setData((prev) => prev.concat([data]));
	}, []);

	const prependRecord = useCallback((data: T) => {
		setTotaRecords((c) => c + 1);
		setData((prev) => [data].concat(prev));
	}, []);

	const updateRecord = useCallback((data: T) => {
		setData((prev) => {
			const temp = [...prev];
			const index = temp.findIndex((obj) => obj.getId() === data.getId());
			if (index !== -1) {
				temp[index] = data;
			}
			return temp;
		});
	}, []);

	const updateRecords = useCallback((data: Array<T>) => {
		setData(data);
	}, []);

	const addParam = useCallback((key: string, value: string | number) => {
		if (!isAPICallInitiated.current) {
			setPage(1);
			setData([]);
			__setParams((prev) => ({
				...prev,
				[key]: value
			}));
			setIsLoading(true);
		}
	}, []);

	const deleteRecord = useCallback((data: T) => {
		setTotaRecords((c) => c - 1);
		setData((prev) => prev.filter((obj) => obj.getId() !== data.getId()));
	}, []);

	const loadMoreRecords = useCallback(() => {
		if (!isAPICallInitiated.current && hasMore) {
			setPage((c) => c + 1);
			setIsLoadingNextPage(true);
		}
	}, [hasMore]);

	

	return {
		data,
		hasMore,
		addParam,
		isLoading,
		totalRecords,
		appendRecord,
		updateRecord,
		deleteRecord,
		updateRecords,
		prependRecord,
		loadMoreRecords,
		isLoadingNextPage,
		
	};
};

export default useCRUDMapper;
