import { GlobalInstance } from '../instances';
import { WithAuthenticationType } from '../types/hoc';

const withAuthentication = ({
	component,
	fallback,
	expectedState
}: WithAuthenticationType) => {
	return () => {
		const currentAuthState = GlobalInstance.getInstance().getAuth_state();

		if (currentAuthState === expectedState) {
			return component;
		} else {
			return fallback;
		}
	};
};

export default withAuthentication;
