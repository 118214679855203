import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useRef, useState } from 'react';

import Form from '../../components/Form';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import ModalBody from '../../components/ModalBody';
import ModalTitle from '../../components/ModalTitle';
import ModalHeader from '../../components/ModalHeader';
import ModalFooter from '../../components/ModalFooter';
import ModalCloseIcon from '../../components/ModalCloseIcon';
import FormFieldError from '../../components/FormFieldError';
import FormFieldGroup from '../../components/FormFieldGroup';
import FormFieldLabel from '../../components/FormFieldLabel';
import FormFieldTextInput from '../../components/FormFieldTextInput';
import FormFieldSelectInput from '../../components/FormFieldSelectInput';

import useForm from '../../hooks/useForm';
import useModal from '../../hooks/useModal';
import { EventEmitterInstance } from '../../instances';

import APIRequestUtil from '../../utils/APIRequestUtil';
import PortalTypeEnum from '../../enums/PortalTypeEnum';
import RequestMethodEnum from '../../enums/RequestMethodEnum';
import RequestHandler from '../../handlers/RequestHandler';
import EventNamesConstants from '../../constants/EventNamesConstants';
import JsonToPortalPojoMapper from '../../mappers/JsonToPortalPojoMapper';

import { CreatePortalForm } from '../../types/forms';

const initialValues: CreatePortalForm.FormValues = {
	name: '',
	type: PortalTypeEnum.Team
};

const CreatePortalModal: React.FunctionComponent = () => {
	const navigate = useRef(useNavigate());

	const { open, openModal, closeModal } = useModal();

	const { values, errors, setValue, setError, clearErrors, setMultipleValues } =
		useForm<CreatePortalForm.FormValues>({
			initialValues
		});

	const [isProcerssing, setIsProcessing] = useState(false);

	useEffect(() => {
		const openModalEventListener = () => {
			openModal();
			setMultipleValues(initialValues);
		};
		const subscription = EventEmitterInstance.addListener(
			EventNamesConstants.Portals.Modal.Create,
			openModalEventListener
		);

		return () => {
			subscription.remove();
		};
	}, [openModal, setMultipleValues]);

	const onTextInputChange = useCallback(
		(key: string, value: string) => {
			setError(key, '');
			setValue(key, value);
		},
		[setError, setValue]
	);

	const onSelectInputChange = useCallback(
		(key: string, value: PortalTypeEnum) => {
			setError(key, '');
			setValue(key, value);
		},
		[setError, setValue]
	);
	const createPortal = useCallback(() => {
		clearErrors();
		if (!values.name) {
			setError('name', 'Name cannot be empty');
			return;
		}

		setIsProcessing(true);
		const apiRequestHandler = new RequestHandler();
		apiRequestHandler.setMethod(RequestMethodEnum.Post);
		apiRequestHandler.setUrl('/api/portals');
		apiRequestHandler.setBody({
			name: values.name,
			type: values.type.getSelectOptionValue()
		});
		apiRequestHandler
			.execute()
			.then((response) => {
				const portalPojo = JsonToPortalPojoMapper.map(
					(response.getData() as Record<string, unknown>).data as Record<
						string,
						unknown
					>
				);
				navigate.current('/portals/' + portalPojo.getId());
			})
			.catch(APIRequestUtil.handleRequestFailure)
			.finally(() => setIsProcessing(false));
	}, [clearErrors, setError, values]);

	return (
		<Modal show={open} position='top'>
			<ModalHeader>
				<ModalTitle title='Create Portal' />
				<ModalCloseIcon closeModal={closeModal} />
			</ModalHeader>
			<ModalBody>
				<Form>
					<FormFieldGroup>
						<FormFieldLabel label='Name' htmlFor='name' />
						<FormFieldTextInput
							id='name'
							name='name'
							value={values.name}
							error={errors.name}
							placeholder='Enter a name'
							onChange={onTextInputChange}
						/>
						<FormFieldError error={errors.name} />
					</FormFieldGroup>
					<FormFieldGroup>
						<FormFieldLabel label='Type' htmlFor='type' />
						<FormFieldSelectInput
							id='type'
							name='type'
							value={values.type}
							error={errors.type}
							isClearable={false}
							placeholder='Select a type'
							onChange={onSelectInputChange}
							options={PortalTypeEnum.getAllValues()}
						/>
						<FormFieldError error={errors.type} />
					</FormFieldGroup>
				</Form>
			</ModalBody>
			<ModalFooter>
				<Button label='Cancel' onClick={closeModal} variant='outlined' />
				<Button
					label='Create'
					variant='primary'
					onClick={createPortal}
					isLoading={isProcerssing}
				/>
			</ModalFooter>
		</Modal>
	);
};

export default CreatePortalModal;
