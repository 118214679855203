import { AuthenticationStateType } from '../types/hoc';

class GlobalInstance {
	private static auth_state: AuthenticationStateType;

	getAuth_state = (): AuthenticationStateType => {
		return GlobalInstance.auth_state;
	};

	setAuth_state = (auth_state: AuthenticationStateType): void => {
		GlobalInstance.auth_state = auth_state;
	};

	static getInstance = (): GlobalInstance => {
		return new GlobalInstance();
	};
}

export default GlobalInstance;
