import SelectOption from '../abstract-classes/SelectOption';

export default class ExternalAppModule extends SelectOption {
	private id: string;
	private name: string;
	private api_name: string;

	constructor() {
		super();

		this.id = '';
		this.name = '';
		this.api_name = '';
	}

	public getId(): string {
		return this.id;
	}

	public setId(id: string): void {
		this.id = id;
	}

	public getSelectOptionLabel(): string {
		return this.name;
	}

	public setName(name: string): void {
		this.name = name;
	}

	public getSelectOptionValue(): string {
		return this.api_name;
	}

	public getApi_name(): string {
		return this.api_name;
	}

	public setApi_name(api_name: string): void {
		this.api_name = api_name;
	}

	public loadFromJson = (json: Record<string, unknown>): void => {
		this.id = json.id as string;
		this.name = json.name as string;
		this.api_name = json.api_name as string;
	};
}
