import { get, set } from 'lodash';

export default class CacheService {
	private parentKey: string = '';
	private static data: Record<string, any> = {};

	constructor(parentKey: string) {
		this.parentKey = parentKey;
	}

	getValue = (key: string): any => {
		return get(CacheService.data, `${this.parentKey}.${key}`, null);
	};

	putValue = (key: string, value: any): void => {
		set(CacheService.data, `${this.parentKey}.${key}`, value);
	};
	flushAll = (): void => {
		delete CacheService.data[this.parentKey];
	};

	static getInstance = (parentKey: string): CacheService => {
		return new CacheService(parentKey);
	};
}
