import { useCallback } from 'react';
import classNames from 'classnames';

import { FormFieldTextareaInput as FormFieldTextareaInputProps } from '../types/components';

const FormFieldTextareaInput: React.FunctionComponent<
	FormFieldTextareaInputProps
> = ({
	id,
	name,
	error,
	value,
	onBlur,
	rows = 5,
	onChange,
	disabled,
	readonly,
	placeholder
}) => {
	const __onChange = useCallback(
		(event: React.ChangeEvent<HTMLTextAreaElement>) => {
			const { name, value } = event.target;
			onChange(name, value);
		},
		[onChange]
	);
	return (
		<textarea
			id={id}
			name={name}
			rows={rows}
			value={value}
			onBlur={onBlur}
			readOnly={readonly}
			disabled={disabled}
			onChange={__onChange}
			placeholder={placeholder}
			className={classNames(
				'form__field-input',
				error && 'form__field-input--error'
			)}
		/>
	);
};

export default FormFieldTextareaInput;
