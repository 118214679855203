import Styles from './main.module.css';

import { useCallback, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import Box from '../components/Box';
import Form from '../components/Form';
import Loader from '../components/loader';
import Button from '../components/Button';
import Typography from '../components/Typography';
import FormFieldGroup from '../components/FormFieldGroup';
import FormFieldLabel from '../components/FormFieldLabel';
import ContainerCenter from '../components/ContainerCenter';
import FormFieldTextInput from '../components/FormFieldTextInput';
import CardProfileTemplate from '../components/CardProfileTemplate';
import FormFieldTextareaInput from '../components/FormFieldTextareaInput';

import useForm from '../hooks/useForm';
import LeadUtil from '../leads/LeadUtil';
import FormFieldDto from '../dtos/FormFieldDto';
import LeadDataModel from '../leads/LeadDataModel';
import FormFieldUtil from '../utils/FormFieldUtil';
import FormFieldType from '../enums/FormFieldType';
import ValidatorUtil from '../common/ValidatorUtil';
import APIRequestUtil from '../utils/APIRequestUtil';

import { ProfileTemplateUserLayoutContext } from './types';
import ToastUtil from '../utils/ToastUtil';
import FormFieldError from '../components/FormFieldError';
export default function ProfileTemplateUserForm() {
	const { profileTemplateDto, userInformationDto } =
		useOutletContext() as ProfileTemplateUserLayoutContext;

	const [isLoading, setIsLoading] = useState(true);
	const [isProcessing, setIsProcessing] = useState(false);
	const [formFields, setFormFields] = useState<Array<FormFieldDto>>([]);
	const [initialValues, setInitialValues] = useState<Record<string, string>>(
		{}
	);

	const { values, errors, setError, setValue, clearErrors, setMultipleValues } =
		useForm<Record<string, string>>({
			initialValues
		});

	useEffect(() => {
		setIsLoading(true);
		setInitialValues({});

		FormFieldUtil.getInstance(
			profileTemplateDto.getPortal().getId(),
			profileTemplateDto.getForm().getId()
		)
			.getFormFields()
			.then((formFields) => {
				setFormFields(formFields);

				const initialValues = formFields.reduce((prev, curr) => {
					return {
						...prev,
						[curr.getName()]: ''
					};
				}, {});

				setInitialValues(initialValues);
				setMultipleValues(initialValues);
				setIsLoading(false);
			})
			.catch((err) => APIRequestUtil.handleRequestFailure(err));
	}, [profileTemplateDto, setMultipleValues]);

	const onTextInputChange = useCallback(
		(key: string, value: string) => {
			setValue(key, value);
			setError(key, '');
		},
		[setError, setValue]
	);

	const saveDetails = useCallback(() => {
		clearErrors();

		for (const formField of formFields) {
			if (formField.isRequired() && !values[formField.getName()]) {
				setError(
					formField.getName(),
					`${formField.getLabel()} cannot be empty.`
				);
				return;
			}

			if (
				formField.getType().getValue() === FormFieldType.Email.getValue() &&
				values[formField.getName()]
			) {
				if (!ValidatorUtil.validateEmail(values[formField.getName()])) {
					setError(
						formField.getName(),
						`Invalid value for ${formField
							.getLabel()
							.toLowerCase()}. ${formField.getLabel()} should be an email address`
					);
					return;
				}
			}
		}

		setIsProcessing(true);

		const leadDataModel = new LeadDataModel();
		leadDataModel.setPortal_id(userInformationDto.getPortal().getId());
		leadDataModel.setUser_id(userInformationDto.getUser().getId());
		leadDataModel.setValues(values);

		LeadUtil.createLead({
			leadDataModel
		})
			.then(() => {
				setMultipleValues(initialValues);
				ToastUtil.makeSuccessToast(
					'Your information has been recorded successfully.'
				);
			})
			.catch((err) => APIRequestUtil.handleRequestFailure(err))
			.finally(() => setIsProcessing(false));
	}, [
		values,
		setError,
		formFields,
		clearErrors,
		initialValues,
		setMultipleValues,
		userInformationDto
	]);

	if (isLoading) {
		return (
			<ContainerCenter>
				<Loader />
			</ContainerCenter>
		);
	} else {
		return (
			<Box className={Styles['profile-template-view']}>
				<CardProfileTemplate>
					<Typography
						variant='h6'
						text='Share your details'
						fontSize={22}
						fontWeight={500}
						marginBottom='20px'
					/>
					<Form>
						{formFields.map((formField) => (
							<FormFieldGroup key={formField.getId()}>
								<FormFieldLabel
									label={formField.getLabel()}
									htmlFor={formField.getName()}
								/>
								{formField.getType().getValue() ===
								FormFieldType.Textarea.getValue() ? (
									<FormFieldTextareaInput
										id={formField.getName()}
										name={formField.getName()}
										onChange={onTextInputChange}
										value={values[formField.getName()]}
										error={errors[formField.getName()]}
										placeholder={formField.getPlaceholder()}
									/>
								) : (
									<FormFieldTextInput
										id={formField.getName()}
										name={formField.getName()}
										onChange={onTextInputChange}
										value={values[formField.getName()]}
										error={errors[formField.getName()]}
										placeholder={formField.getPlaceholder()}
									/>
								)}
								<FormFieldError error={errors[formField.getName()]} />
							</FormFieldGroup>
						))}
					</Form>
					<ContainerCenter>
						<Button
							variant='primary'
							label='Save Details'
							onClick={saveDetails}
							isLoading={isProcessing}
						/>
					</ContainerCenter>
				</CardProfileTemplate>
			</Box>
		);
	}
}
