import PageInfo from './PageInfo';

export default class PagedResult<T> {
	private data: Array<T>;
	private pageInfo: PageInfo;

	constructor() {
		this.data = [];
		this.pageInfo = new PageInfo();
	}

	public getData(): Array<T> {
		return this.data;
	}

	public setData(data: Array<T>): void {
		this.data = data;
	}

	public getPageInfo(): PageInfo {
		return this.pageInfo;
	}

	public setPageInfo(pageInfo: PageInfo): void {
		this.pageInfo = pageInfo;
	}
}
