export default class PhoneNumber {
	private value: string;
	private format: string;
	private country: string;
	private dial_code: string;

	constructor() {
		this.value = '';
		this.format = '';
		this.country = '';
		this.dial_code = '';
	}

	public getValue(): string {
		return this.value;
	}

	public setValue(value: string): void {
		this.value = value;
	}

	public getFormat(): string {
		return this.format;
	}

	public setFormat(format: string): void {
		this.format = format;
	}

	public getCountry(): string {
		return this.country;
	}

	public setCountry(country: string): void {
		this.country = country;
	}

	public getDial_code(): string {
		return this.dial_code;
	}

	public setDial_code(dial_code: string): void {
		this.dial_code = dial_code;
	}

	public isEmpty(): boolean {
		return !this.value;
	}

	public loadFromJson(json: Record<string, string>): void {
		this.value = json.value;
		this.format = json.format;
		this.country = json.country;
		this.dial_code = json.dial_code;
	}

	public toJSON(): Record<string, string> {
		return {
			value: this.value,
			format: this.format,
			country: this.country,
			dial_code: this.dial_code
		};
	}

	public toString = (): string => {
		return JSON.stringify(this.toJSON());
	};
}
