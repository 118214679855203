const FailureToastIcon: React.FunctionComponent<
	React.HTMLAttributes<HTMLOrSVGElement>
> = (props) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			fill='none'
			strokeWidth={1.5}
			viewBox='0 0 24 24'
			stroke='currentColor'
			{...props}>
			<path
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z'
			/>
		</svg>
	);
};

export default FailureToastIcon;
