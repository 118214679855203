import classNames from 'classnames';
import Styles from './table.module.css';

export interface TableLinkCellProps {
	text: string;
	onClick(): void;
	colSpan?: number;
	className?: string;
}

const TableLinkCell: React.FC<TableLinkCellProps> = ({
	text,
	onClick,
	colSpan,
	className
}) => {
	return (
		<td
			onClick={onClick}
			colSpan={colSpan}
			className={classNames(
				'table__cell',
				Styles['table-cell-link'],
				className
			)}>
			{text}
		</td>
	);
};

export default TableLinkCell;
