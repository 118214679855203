import { useCallback } from 'react';
import toast, { Toast } from 'react-hot-toast';

import Typography from './Typography';
import CloseIcon from '../icons/CloseIcon';
import FailureToastIcon from '../icons/FailureToastIcon';

const FailureToast = ({ id, message }: Toast) => {
	const dismissToast = useCallback(() => {
		toast.remove(id);
	}, [id]);

	return (
		<div className='toast toast--failure'>
			<div className='toast__container'>
				<FailureToastIcon className='toast__icon' />
				<div className='toast__text-container'>
					<Typography
						variant='h6'
						fontSize={16}
						fontWeight={500}
						text='Failure'
					/>

					<Typography
						variant='p'
						fontSize={14}
						fontWeight={400}
						text={message as string}
					/>
				</div>

				<CloseIcon className='toast__close-icon' onClick={dismissToast} />
			</div>
		</div>
	);
};

export default FailureToast;
