import 'react-phone-input-2/lib/style.css';

import classNames from 'classnames';
import React, { useCallback } from 'react';
import PhoneInput, { CountryData } from 'react-phone-input-2';

import { FormFieldPhoneInput as FormFieldPhoneInputProps } from '../types/components';

const FormFieldPhoneInput: React.FunctionComponent<
	FormFieldPhoneInputProps
> = ({
	id,
	name,
	error,
	value,
	country,
	onBlur,
	onChange,
	readonly,
	placholder
}) => {
	const __onChange = useCallback(
		(
			value: string,
			countryData: CountryData,
			event: React.ChangeEvent<HTMLInputElement>
		) => {
			const { name } = event.target;
			onChange(name, value, countryData);
		},
		[onChange]
	);
	return (
		<PhoneInput
			value={value}
			country={country}
			onChange={__onChange}
			onBlur={onBlur}
			placeholder={placholder}
			countryCodeEditable={false}
			inputProps={{
				id,
				name
			}}
			inputClass={classNames('form__field-input--phone')}
			buttonClass='styled-input-phone-button'
		/>
	);
};

export default FormFieldPhoneInput;
