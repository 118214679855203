import { useCallback } from 'react';

import logo from '../../images/logo.png';

import { LogoutIcon } from '../../icons';
import { GlobalInstance } from '../../instances';
import { Link, Outlet } from 'react-router-dom';

const AuthorizedLayout: React.FunctionComponent = () => {
	const signOut = useCallback(() => {
		const auth = window.catalyst.auth;
		GlobalInstance.getInstance().setAuth_state('unauthenticated');
		auth.signOut(window.location.origin + '/app/#/login');
	}, []);

	return (
		<>
			<div className='header'>
				<div className='mr-auto'>
					<Link to='/portals' className='container__title'>
						<img src={logo} alt='zoho_cards' className='icon__app' />
						<h6 className='title__app'>Zoho Touchpoint</h6>
					</Link>
				</div>
				<div className='container__header-actions'>
					<button onClick={signOut} className='btn__icon'>
						<LogoutIcon className='icon__logout' />
					</button>
				</div>
			</div>
			<div className='container__main'>
				<Outlet />
			</div>
		</>
	);
};

export default AuthorizedLayout;
