import FileMeta from '../datatypes/FileMeta';
import TemplateNodeStyles from '../template/TemplateNodeStyles';
import TouchpointTemplateNodeType from './TouchpointTemplateNodeType';

export default class TouchpointTemplateNode {
	private id: string;
	private image: FileMeta;
	private sequence: number;
	private styles: TemplateNodeStyles;
	private type: TouchpointTemplateNodeType;
	private properties: Record<string, unknown>;
	private nodes: Array<TouchpointTemplateNode>;

	constructor() {
		this.id = '';
		this.nodes = [];
		this.sequence = 1;
		this.properties = {};
		this.image = new FileMeta();
		this.type = TouchpointTemplateNodeType.TEXT;
		this.styles = new TemplateNodeStyles();
	}

	public getId(): string {
		return this.id;
	}

	public setId(id: string): void {
		this.id = id;
	}

	public getImage(): FileMeta {
		return this.image;
	}

	public setImage(image: FileMeta): void {
		this.image = image;
	}

	public getSequence(): number {
		return this.sequence;
	}

	public setSequence(sequence: number): void {
		this.sequence = sequence;
	}

	public getType(): TouchpointTemplateNodeType {
		return this.type;
	}

	public setType(type: TouchpointTemplateNodeType): void {
		this.type = type;
	}

	public getStyles(): TemplateNodeStyles {
		return this.styles;
	}

	public setStyles(styles: TemplateNodeStyles): void {
		this.styles = styles;
	}

	public getNodes(): Array<TouchpointTemplateNode> {
		return this.nodes;
	}

	public setNodes(nodes: Array<TouchpointTemplateNode>): void {
		this.nodes = nodes;
	}

	public getProperties(): Record<string, unknown> {
		return this.properties;
	}

	public setProperties(properties: Record<string, unknown>): void {
		this.properties = properties;
	}

	public loadFromJSON(json: Record<string, unknown>): void {
		this.id = json.id as string;
		this.sequence = parseInt(json.sequence as string);
		this.properties = json.properties as Record<string, unknown>;
		this.image.loadFromJSON(json.image as Record<string, unknown>);
		this.styles.loadFromJSON(json.styles as Record<string, string>);
		this.type = TouchpointTemplateNodeType.getValue(json.type as string);
	}

	public toJSON(): Record<string, unknown> {
		return {
			id: this.id,
			nodes: this.nodes,
			sequence: this.sequence,
			type: this.type.getValue(),
			image: this.image.toJSON(),
			properties: this.properties,
			styles: this.styles.toJSON()
		};
	}
}
