import { useCallback } from 'react';

import { UseOnScroll } from '../types/hooks';

export default function useOnScroll({
	cb
}: UseOnScroll.Input): UseOnScroll.Output {
	const onScrollToBottom = useCallback(
		(event: React.UIEvent<HTMLDivElement>) => {
			const { scrollHeight, clientHeight, scrollTop } =
				event.target as HTMLElement;

			if (scrollHeight - clientHeight <= scrollTop * 1.2) {
				cb();
			}
		},
		[cb]
	);

	return {
		onScrollToBottom
	};
}
