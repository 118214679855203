import Styles from './main.module.css';

import { useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

import Box from '../components/Box';
import Loader from '../components/loader';
import ContainerCenter from '../components/ContainerCenter';
import ProfileTemplateBuilder from './ProfileTemplateBuilder';
import CardProfileTemplate from '../components/CardProfileTemplate';

import { EventEmitterInstance } from '../instances';
import APIRequestUtil from '../utils/APIRequestUtil';
import ProfileTemplateNode from './ProfileTemplateNode';
import ProfileTemplateType from './ProfileTemplateType';
import ProfileTemplateUtil from './ProfileTemplateUtil';
import EventNamesConstants from '../constants/EventNamesConstants';

import { ProfileTemplateUserLayoutContext } from './types';

export default function ProfileTemplateUserView() {
	const { touchpoint_mapped_value } = useParams() as Record<string, string>;

	const navigate = useRef(useNavigate());

	const [isLoading, setIsLoading] = useState(true);

	const { profileTemplateDto, userInformationDto } =
		useOutletContext() as ProfileTemplateUserLayoutContext;

	const [profileTemplateNodes, setProfileTemplateNodes] = useState<
		Array<ProfileTemplateNode>
	>([]);

	useEffect(() => {
		setIsLoading(true);

		const redirectToForm = () => {
			navigate.current(`/${touchpoint_mapped_value}/form`);
		};

		if (profileTemplateDto.getType() === ProfileTemplateType.Form) {
			redirectToForm();
			return;
		}

		ProfileTemplateUtil.getProfileTemplateNodesWithPortal({
			profile_template_id: profileTemplateDto.getId(),
			portal_id: profileTemplateDto.getPortal().getId()
		})
			.then((profileTemplateNodes) => {
				setProfileTemplateNodes(profileTemplateNodes);
				setIsLoading(false);
			})
			.catch((err) => APIRequestUtil.handleRequestFailure(err));

		const subscription = EventEmitterInstance.addListener(
			EventNamesConstants.ProfileTemplateNode.Button.Action.DisplayLeadForm,
			redirectToForm
		);

		return () => {
			subscription.remove();
		};
	}, [profileTemplateDto, touchpoint_mapped_value]);

	if (isLoading) {
		return (
			<ContainerCenter>
				<Loader />
			</ContainerCenter>
		);
	} else {
		return (
			<Box className={Styles['profile-template-view']}>
				<CardProfileTemplate>
					<ProfileTemplateBuilder
						profileTemplateNodes={profileTemplateNodes}
						userInformationPojo={userInformationDto}
					/>
				</CardProfileTemplate>
			</Box>
		);
	}
}
