import { nanoid } from 'nanoid';
import { ApiResponseJson } from '../types/json';

export default class MappedValue {
	private id: string;
	private value: string;
	private mappedValue: string;

	constructor(value: string, mapped_value: string) {
		this.id = nanoid();
		this.value = value;
		this.mappedValue = mapped_value;
	}

	public getId(): string {
		return this.id;
	}

	public getValue(): string {
		return this.value;
	}

	public getMappedValue(): string {
		return this.mappedValue;
	}

	public toJson = (): ApiResponseJson.MappedValueDatatypeJson => {
		return {
			id: this.id,
			value: this.value,
			mapped_value: this.mappedValue
		};
	};

    public toSelectInputOptionJson = ():ApiResponseJson.SelectInputOptionJson=>{
        return {
			id: this.id,
			value: this.value,
			label: this.mappedValue
		};
    }
}
