import Enum from '../base-classes/Enum';

export default class FormFieldCategoryEnum extends Enum {
	public static readonly System = new FormFieldCategoryEnum('system', 'System');
	public static readonly Custom = new FormFieldCategoryEnum('custom', 'Custom');

	public static getValue(value: string): FormFieldCategoryEnum {
		if (value === this.Custom.value || value === this.Custom.mappedValue) {
			return this.Custom;
		} else {
			return this.System;
		}
	}

	public static getAllValues(): Array<FormFieldCategoryEnum> {
		return [this.System, this.Custom];
	}
}
