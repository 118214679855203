import classNames from 'classnames';

import { TableHeaderCell as TableHeaderCellProps } from '../types/components';

const TableHeadCell: React.FC<TableHeaderCellProps> = ({
	text,
	width,
	colSpan,
	className
}) => {
	return (
		<th
			colSpan={colSpan}
			style={{ ...(width ? { width } : {}) }}
			className={classNames('table__header', className)}
			>
			{text}
		</th>
	);
};

export default TableHeadCell;
