import Pojo from '../abstract-classes/Pojo';
import ForeignKey from '../datatypes/ForeignKey';
import LeadTypeEnum from '../enums/LeadTypeEnum';
import PartialPortalUserPojo from './PartialPortalUserPojo';

export default class LeadPojo extends Pojo {
	private email_id: string;
	private last_name: string;
	private first_name: string;
	private synchronized: boolean;

	private type: LeadTypeEnum;
	private portal: ForeignKey;
	private owner: PartialPortalUserPojo;

	constructor() {
		super();

		this.id = '';
		this.email_id = '';
		this.last_name = '';
		this.first_name = '';
		this.synchronized = false;

		this.type = LeadTypeEnum.Internal;

		this.portal = new ForeignKey();
		this.owner = new PartialPortalUserPojo();
	}

	public getEmail_id(): string {
		return this.email_id;
	}

	public setEmail_id(email_id: string): void {
		this.email_id = email_id;
	}

	public getLast_name(): string {
		return this.last_name;
	}

	public setLast_name(last_name: string): void {
		this.last_name = last_name;
	}

	public getFirst_name(): string {
		return this.first_name;
	}

	public setFirst_name(first_name: string): void {
		this.first_name = first_name;
	}

	public isSynchronized(): boolean {
		return this.synchronized;
	}

	public setSynchronized(synchronized: boolean): void {
		this.synchronized = synchronized;
	}

	public getType(): LeadTypeEnum {
		return this.type;
	}

	public setType(type: LeadTypeEnum): void {
		this.type = type;
	}

	public getPortal(): ForeignKey {
		return this.portal;
	}

	public setPortal(portal: ForeignKey): void {
		this.portal = portal;
	}

	public getOwner(): PartialPortalUserPojo {
		return this.owner;
	}

	public setOwner(owner: PartialPortalUserPojo): void {
		this.owner = owner;
	}

	public loadFromJson = (json: Record<string, unknown>): void => {
		this.id = json.id as string;
		this.email_id = json.email_id as string;
		this.last_name = json.last_name as string;
		this.first_name = json.first_name as string;
		this.synchronized = json.synchronized as boolean;

		this.type = LeadTypeEnum.getValue(json.type as string);

		this.owner.loadFromJson(json.owner as any);
		this.portal.loadFromJSON(json.portal as any);
		this.created_time.setValue(json.created_time as string);
	};
}
