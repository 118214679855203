import classNames from 'classnames';
import { ContainerFlex as ContainerFlexProps } from '../types/components';

const ContainerFlex: React.FunctionComponent<ContainerFlexProps> = ({
	children,
	className,
	columnGap,
	alignItems,
	justifyContent
}) => {
	return (
		<div
			className={classNames('container--flex', className)}
			style={{
				...(columnGap ? { columnGap: columnGap + 'px' } : {}),
				...(alignItems ? { alignItems } : {}),
				...(justifyContent ? { justifyContent } : {})
			}}>
			{children}
		</div>
	);
};

export default ContainerFlex;
