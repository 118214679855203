import Styles from './table.module.css';

import InfoToastIcon from '../icons/InfoToastIcon';

import { TableCellAction as TableCellActionProps } from '../types/components';

const TableCellActionInfo: React.FunctionComponent<TableCellActionProps> = ({
	onClick
}) => {
	return (
		<InfoToastIcon onClick={onClick} className={Styles['table-cell-icon']} />
	);
};

export default TableCellActionInfo;
