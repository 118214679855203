import { ApiResponseJson } from '../types/json';

export default class PartialUserPojo {
	private id: string;
	private email_id: string;
	private last_name: string;
	private first_name: string;

	constructor() {
		this.id = '';
		this.email_id = '';
		this.last_name = '';
		this.first_name = '';
	}

	public getId(): string {
		return this.id;
	}

	public setId(id: string): void {
		this.id = id;
	}

	public getEmail_id(): string {
		return this.email_id;
	}

	public setEmail_id(email_id: string): void {
		this.email_id = email_id;
	}

	public getLast_name(): string {
		return this.last_name;
	}

	public setLast_name(last_name: string): void {
		this.last_name = last_name;
	}

	public getFirst_name(): string {
		return this.first_name;
	}

	public setFirst_name(first_name: string): void {
		this.first_name = first_name;
	}

	public loadFromJson = (json: ApiResponseJson.PartialUserJson) => {
		this.id = json.id;
		this.email_id = json.email_id;
		this.last_name = json.last_name;
		this.first_name = json.first_name;
	};
}
