import ProfileTemplateTextNode from './ProfileTemplateTextNode';
import ProfileTemplateLinkNode from './ProfileTemplateLinkNode';
import ProfileTemplateIconNode from './ProfileTemplateIconNode';
import ProfileTemplateImageNode from './ProfileTemplateImageNode';
import ProfileTemplateButtonNode from './ProfileTemplateButtonNode';
import ProfileTemplateContainerNode from './ProfileTemplateContainerNode';

import ProfileTemplateNodeTypeEnum from '../enums/ProfileTemplateNodeTypeEnum';

import { ProfileTemplateBuilder as ProfileTemplateBuilderProps } from '../types/components';

const ProfileTemplateBuilder: React.FC<ProfileTemplateBuilderProps> = ({
	disabled,
	userInformationPojo,
	profileTemplateNodes
}) => {
	return (
		<>
			{profileTemplateNodes.map((profileTemplateNode) => {
				if (
					profileTemplateNode.getType() === ProfileTemplateNodeTypeEnum.Text
				) {
					return (
						<ProfileTemplateTextNode
							disabled={disabled}
							userInformationPojo={userInformationPojo}
							key={profileTemplateNode.getDummy_id()}
							profileTemplateNode={profileTemplateNode}
						/>
					);
				} else if (
					profileTemplateNode.getType() ===
					ProfileTemplateNodeTypeEnum.Image
				) {
					return (
						<ProfileTemplateImageNode
							disabled={disabled}
							userInformationPojo={userInformationPojo}
							key={profileTemplateNode.getDummy_id()}
							profileTemplateNode={profileTemplateNode}
						/>
					);
				} else if (
					profileTemplateNode.getType() === ProfileTemplateNodeTypeEnum.Link
				) {
					if (profileTemplateNode.getNodes().length) {
						return (
							<ProfileTemplateLinkNode
								userInformationPojo={userInformationPojo}
								key={profileTemplateNode.getDummy_id()}
								profileTemplateNode={profileTemplateNode}>
								<ProfileTemplateBuilder
									disabled={disabled}
									userInformationPojo={userInformationPojo}
									profileTemplateNodes={profileTemplateNode.getNodes()}
								/>
							</ProfileTemplateLinkNode>
						);
					} else {
						return (
							<ProfileTemplateLinkNode
								userInformationPojo={userInformationPojo}
								key={profileTemplateNode.getDummy_id()}
								profileTemplateNode={profileTemplateNode}
							/>
						);
					}
				}
				if (
					profileTemplateNode.getType() ===
					ProfileTemplateNodeTypeEnum.Container
				) {
					if (profileTemplateNode.getNodes().length) {
						return (
							<ProfileTemplateContainerNode
								userInformationPojo={userInformationPojo}
								key={profileTemplateNode.getDummy_id()}
								profileTemplateNode={profileTemplateNode}>
								<ProfileTemplateBuilder
									disabled={disabled}
									userInformationPojo={userInformationPojo}
									profileTemplateNodes={profileTemplateNode.getNodes()}
								/>
							</ProfileTemplateContainerNode>
						);
					} else {
						return (
							<ProfileTemplateContainerNode
								userInformationPojo={userInformationPojo}
								key={profileTemplateNode.getDummy_id()}
								profileTemplateNode={profileTemplateNode}
							/>
						);
					}
				} else if (
					profileTemplateNode.getType() === ProfileTemplateNodeTypeEnum.Icon
				) {
					return (
						<ProfileTemplateIconNode
							disabled={disabled}
							userInformationPojo={userInformationPojo}
							key={profileTemplateNode.getDummy_id()}
							profileTemplateNode={profileTemplateNode}
						/>
					);
				} else if (
					profileTemplateNode.getType() ===
					ProfileTemplateNodeTypeEnum.Button
				) {
					return (
						<ProfileTemplateButtonNode
							disabled={disabled}
							userInformationPojo={userInformationPojo}
							key={profileTemplateNode.getDummy_id()}
							profileTemplateNode={profileTemplateNode}
						/>
					);
				} else {
					return null;
				}
			})}
		</>
	);
};

export default ProfileTemplateBuilder;
