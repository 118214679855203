export default class AjvConstants {
	static readonly Schema = {
		String: {
			Format: {
				Email: 'string.format.email'
			},
			Common: {
				Identifier: 'string.common.identifier'
			},

			Enum: {
				StorageType: 'string.enum.storage_type',
				StorageLocation: 'string.enum.storage_location'
			}
		}
	};
}
