import classNames from 'classnames';

import { ModalBody as ModalBodyProps } from '../types/components';

const ModalBody: React.FunctionComponent<ModalBodyProps> = ({
	children,
	className
}) => {
	return <div className={classNames('modal__body', className)}>{children}</div>;
};

export default ModalBody;
