import DummyUser from '../images/dummy-user.png';
import UserInformationDto from '../dtos/UserInformationDto';

export default class UserInformationReplacer {
	private userInformationPojo: UserInformationDto;

	constructor(userInformationPojo: UserInformationDto) {
		this.userInformationPojo = userInformationPojo;
	}

	public replace(text: string) {
		const image = this.userInformationPojo.getImage();
		const userInformation: Record<string, string> = {
			'UserInformation.Role': this.userInformationPojo.getRole() || '-',
			'UserInformation.About': this.userInformationPojo.getAbout() || '-',
			'UserInformation.Company': this.userInformationPojo.getCompany() || '-',
			'UserInformation.LastName': this.userInformationPojo.getLast_name(),
			'UserInformation.FirstName': this.userInformationPojo.getFirst_name(),
			'UserInformation.EmailAddress': this.userInformationPojo.getEmail_id(),
			'UserInformation.Image': image.getId()
				? image.getDownloadUrl()
				: DummyUser,
			'UserInformation.SocialUrls.Whatsapp': `https://wa.me/+${this.userInformationPojo
				.getPhone_number()
				.getValue()}`,
			'UserInformation.SocialUrls.Twitter': this.userInformationPojo
				.getSocial_urls()
				.getTwitter_url(),
			'UserInformation.SocialUrls.Facebook': this.userInformationPojo
				.getSocial_urls()
				.getFacebook_url(),
			'UserInformation.SocialUrls.LinkedIn': this.userInformationPojo
				.getSocial_urls()
				.getLinkedIn_url()
		};

		for (const [key, value] of Object.entries(userInformation)) {
			const regex = new RegExp(`{{${key}}}`, 'g');
			text = text.replace(regex, value);
		}

		return text;
	}

	public static getInstance(
		userInformationPojo: UserInformationDto
	): UserInformationReplacer {
		return new UserInformationReplacer(userInformationPojo);
	}
}
