import Enum from '../base-classes/Enum';

export default class LeadStorageTypeEnum extends Enum {
	public static readonly ExternalApp = new LeadStorageTypeEnum(
		'external-app',
		'External App'
	);
	public static readonly Application = new LeadStorageTypeEnum(
		'application',
		'Application'
	);

	public static readonly ApplicationAndExternalApp = new LeadStorageTypeEnum(
		'application and external-app',
		'Application & External App'
	);

	public static getValue(value: string): LeadStorageTypeEnum {
		if (
			value === this.ApplicationAndExternalApp.getSelectOptionValue() ||
			value === this.ApplicationAndExternalApp.mappedValue
		) {
			return this.ApplicationAndExternalApp;
		} else if (
			value === this.ExternalApp.getSelectOptionValue() ||
			value === this.ExternalApp.mappedValue
		) {
			return this.ExternalApp;
		} else {
			return this.Application;
		}
	}

	public static getAllValues(): Array<LeadStorageTypeEnum> {
		return [this.ExternalApp, this.Application, this.ApplicationAndExternalApp];
	}
}
