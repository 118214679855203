import Enum from '../base-classes/Enum';

export default class LeadStorageLocationEnum extends Enum {
	public static readonly ZohoCrm = new LeadStorageLocationEnum('zoho-crm','Zoho Crm');
	public static readonly Application = new LeadStorageLocationEnum(
		'application',
		'Application'
	);

	public static getValue(value: string): LeadStorageLocationEnum {
		if (value === this.ZohoCrm.value || value === this.ZohoCrm.mappedValue) {
			return this.ZohoCrm;
		} else {
			return this.Application;
		}
	}

	public static getAllValues(): Array<LeadStorageLocationEnum> {
		return [this.Application, this.ZohoCrm];
	}
}
