import { Icon as IconProps } from '../types/components';

const PlusIcon: React.FC<IconProps> = ({
	widith,
	height,
	onClick,
	className
}) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 24 24'
			fill='none'
			strokeWidth={1.5}
			stroke='currentColor'
			height={height}
			width={widith}
			onClick={onClick}
			className={className}>
			<path
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M12 4.5v15m7.5-7.5h-15'
			/>
		</svg>
	);
};

export default PlusIcon;
