import { useOutletContext } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';

import Loader from '../../components/loader';
import Typography from '../../components/Typography';
import ContainerCenter from '../../components/ContainerCenter';
import CardProfileTemplate from '../../components/CardProfileTemplate';
import ProfileTemplateBuilder from '../../profile-templates/ProfileTemplateBuilder';
import ContainerProfileCategoryBody from '../../components/ContainerProfileCategoryBody';
import ContainerProfileCategoryHeader from '../../components/ContainerProfileCategoryHeader';

import Button from '../../components/Button';
import ToastUtil from '../../utils/ToastUtil';
import { EventEmitterInstance } from '../../instances';
import APIRequestUtil from '../../utils/APIRequestUtil';
import ProfileTemplateDto from '../../profile-templates/ProfileTemplateDto';
import UserInformationUtil from '../../utils/UserInformationUtil';
import ProfileTemplateNode from '../../profile-templates/ProfileTemplateNode';
import EventNamesConstants from '../../constants/EventNamesConstants';
import UserInformationDataModel from '../../model/UserInformationDataModel';

import { ProfilePickerLayoutContext } from '../../types/context';
import ProfileTemplateUtil from '../../profile-templates/ProfileTemplateUtil';

const ProfileTemplateContainer: React.FC<{
	profileTemplateDto: ProfileTemplateDto;
}> = ({ profileTemplateDto }) => {
	const { portalUserPojo, userInformationPojo, currentProfileTemplatePojo } =
		useOutletContext<ProfilePickerLayoutContext>();

	const [isLoading, setIsLoading] = useState(true);
	const [isTemplateActivating, setIsTemplateActivating] = useState(false);
	const [profileTemplateNodes, setProfileTemplateNodes] = useState<
		Array<ProfileTemplateNode>
	>([]);

	useEffect(() => {
		setIsLoading(true);

		ProfileTemplateUtil.getProfileTemplateNodesWithPortal({
			profile_template_id: profileTemplateDto.getId(),
			portal_id: profileTemplateDto.getPortal().getId()
		})
			.then((nodes) => {
				setIsLoading(false);
				setProfileTemplateNodes(nodes);
			})
			.catch((err) => APIRequestUtil.handleRequestFailure(err));
	}, [profileTemplateDto]);

	const activateProfileTemplate = useCallback(() => {
		setIsTemplateActivating(true);

		const userInformationDataModel = new UserInformationDataModel();
		userInformationDataModel.setUser_id(portalUserPojo.getId());
		userInformationDataModel.setProfile_template(profileTemplateDto.getName());
		userInformationDataModel.setPortal_id(
			profileTemplateDto.getPortal().getId()
		);

		UserInformationUtil.updateUserInformation({ userInformationDataModel })
			.then(() => {
				EventEmitterInstance.emit(
					EventNamesConstants.ProfileTemplates.Data.Activate,
					profileTemplateDto
				);
				ToastUtil.makeSuccessToast('Template has been activated successfully.');
			})
			.catch(APIRequestUtil.handleRequestFailure)
			.finally(() => {
				setIsTemplateActivating(false);
			});
	}, [portalUserPojo, profileTemplateDto]);

	if (isLoading) {
		return (
			<ContainerCenter>
				<Loader />
			</ContainerCenter>
		);
	} else {
		return (
			<>
				<ContainerProfileCategoryHeader>
					<Typography
						variant='h6'
						fontSize={22}
						text='Portfolio'
						fontWeight={600}
						marginRight='auto'
					/>
					<Button
						label='Activate'
						variant='primary'
						isLoading={isTemplateActivating}
						onClick={activateProfileTemplate}
						disabled={
							currentProfileTemplatePojo.getId() === profileTemplateDto.getId()
						}
					/>
				</ContainerProfileCategoryHeader>
				<ContainerProfileCategoryBody>
					<CardProfileTemplate>
						<ProfileTemplateBuilder
							disabled={true}
							userInformationPojo={userInformationPojo}
							profileTemplateNodes={profileTemplateNodes}
						/>
					</CardProfileTemplate>
				</ContainerProfileCategoryBody>
			</>
		);
	}
};

export default ProfileTemplateContainer;
