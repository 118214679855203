export default class TouchpointTemplateTextNodeProperties {
	private value: string;

	constructor() {
		this.value = '';
	}

	public getValue(): string {
		return this.value;
	}

	public setValue(value: string): void {
		this.value = value;
	}

	public loadFromJSON(json: Record<string, string>) {
		this.value = json.text;
	}

	public toJSON(): Record<string, unknown> {
		return JSON.parse(
			JSON.stringify({
				value: this.value
			})
		);
	}
}
