import { TemplateConstants } from '../constants';
import { Datetime, ForeignKey, MappedValue } from '../datatypes';

import { Pojo } from '../types/data';
import { ApiResponseJson } from '../types/json';

import PartialPortalUserPojo from './PartialPortalUserPojo';
export default class TemplatePojo implements Pojo {
	private id: string;
	private type: string;
	private name: string;
	private is_default: boolean;
	private preview_file_id: string;
	private back_view_file_id: string;
	private front_view_file_id: string;

	private form: ForeignKey;
	private portal: ForeignKey;
	private category: MappedValue;
	private created_time: Datetime;
	private template_group: ForeignKey;
	
	private creator: PartialPortalUserPojo;

	constructor() {
		this.id = '';
		this.type = '';
		this.name = '';
		this.is_default = false;
		this.preview_file_id = '';
		this.back_view_file_id = '';
		this.front_view_file_id = '';

		this.form = new ForeignKey();
		this.portal = new ForeignKey();
		this.created_time = new Datetime();
		this.template_group = new ForeignKey();
		this.creator = new PartialPortalUserPojo();

		this.category = TemplateConstants.Category.Form;
	}

	public getId(): string {
		return this.id;
	}

	public setId(id: string): void {
		this.id = id;
	}

	public getType(): string {
		return this.type;
	}

	public setType(type: string): void {
		this.type = type;
	}

	public getName(): string {
		return this.name;
	}

	public setName(name: string): void {
		this.name = name;
	}

	public getForm(): ForeignKey {
		return this.form;
	}

	public setForm_id(form: ForeignKey): void {
		this.form = form;
	}

	public getCategory(): MappedValue {
		return this.category;
	}

	public setCategory(category: MappedValue): void {
		this.category = category;
	}

	public getPortal(): ForeignKey {
		return this.portal;
	}

	public setPortal(portal: ForeignKey): void {
		this.portal = portal;
	}

	public isIs_default(): boolean {
		return this.is_default;
	}

	public setIs_default(is_default: boolean): void {
		this.is_default = is_default;
	}

	public getCreator(): PartialPortalUserPojo {
		return this.creator;
	}

	public setCreator(creator: PartialPortalUserPojo): void {
		this.creator = creator;
	}

	public getCreated_time(): Datetime {
		return this.created_time;
	}

	public setCreated_time(created_time: Datetime): void {
		this.created_time = created_time;
	}

	public getPreview_file_id(): string {
		return this.preview_file_id;
	}

	public setPreview_file_id(preview_file_id: string): void {
		this.preview_file_id = preview_file_id;
	}

	public getBack_view_file_id(): string {
		return this.back_view_file_id;
	}

	public setBack_view_file_id(back_view_file_id: string): void {
		this.back_view_file_id = back_view_file_id;
	}

	public getTemplate_group(): ForeignKey {
		return this.template_group;
	}

	public setTemplate_group(template_group: ForeignKey): void {
		this.template_group = template_group;
	}

	public getFront_view_file_id(): string {
		return this.front_view_file_id;
	}

	public setFront_view_file_id(front_view_file_id: string): void {
		this.front_view_file_id = front_view_file_id;
	}


	public loadFromJson = (json: ApiResponseJson.TemplateJson): void => {
		this.id = json.id;
		this.type = json.type;
		this.name = json.name;

		this.is_default = json.is_default;
		this.preview_file_id = json.preview_file_id;
		this.back_view_file_id = json.back_view_file_id;
		this.front_view_file_id = json.front_view_file_id;

		this.form.loadFromJSON(json.form as unknown as Record<string,unknown>);
		this.portal.loadFromJSON(json.portal as unknown as Record<string,unknown>);
		this.creator.loadFromJson(json.creator as unknown as Record<string,unknown>);
		this.created_time.setValue(json.created_time);
		this.template_group.loadFromJSON(json.template_group as unknown as Record<string,unknown>);

		this.category = TemplateConstants.getCategoryByValue(json.category);
	};

	static getInstance = (): TemplatePojo => {
		return new TemplatePojo();
	};
}
