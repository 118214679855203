import { useCallback, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import View from '../components/View';
import Table from '../components/Table';
import Button from '../components/Button';
import Loader from '../components/loader';
import TableRow from '../components/TableRow';
import ViewTitle from '../components/ViewTitle';
import TableBody from '../components/TableBody';
import TableHead from '../components/TableHead';
import ViewHeader from '../components/ViewHeader';
import ViewActions from '../components/ViewActions';
import TableDataCell from '../components/TableDataCell';
import TableHeadCell from '../components/TableHeadCell';
import TableLinkCell from '../components/TableLinkCell';
import TableContainer from '../components/TableContainer';
import ContainerCenter from '../components/ContainerCenter';
import TableActionsCell from '../components/TableActionsCell';
import TableCellActionInfo from '../components/TableCellActionInfo';
import TableCellActionLink from '../components/TableCellActionLink';
import TableCellActionQRCode from '../components/TableCellActionQRCode';
import LoaderLoadMoreRecords from '../components/LoaderLoadMoreRecords';

import TouchpointDto from './TouchpointDto';
import useOnScroll from '../hooks/useOnScroll';
import QRCodeUtil from '../qr-code/QRCodeUtil';
import useCRUDMapper from '../hooks/useCRUDMapper';
import APIRequestUtil from '../utils/APIRequestUtil';
import JsonToTouchpointDtoMapper from './JsonToTouchpointDtoMapper';
import ToastUtil from '../utils/ToastUtil';
import TableCellActionCopy from '../components/TableCellActionCopy';

function TouchpointRow({ touchpointDto }: { touchpointDto: TouchpointDto }) {
	const navigate = useRef(useNavigate());

	const goToTouchpointInfo = useCallback(() => {
		navigate.current(
			`/portals/${touchpointDto
				.getPortal()
				.getId()}/touchpoints/${touchpointDto.getId()}`
		);
	}, [touchpointDto]);

	const goToTouchpoint = useCallback(() => {
		window.open(
			window.location.origin + '/app/#/' + touchpointDto.getId(),
			'_blank'
		);
	}, [touchpointDto]);

	const downloadQR = useCallback(() => {
		QRCodeUtil.downloadQR({
			value: window.location.origin + '/app/#/' + touchpointDto.getId()
		}).catch((err) => APIRequestUtil.handleRequestFailure(err));
	}, [touchpointDto]);

	const copyTouchpointAccessUrl = useCallback(() => {
		window.navigator.clipboard.writeText(
			window.location.origin + '/app/#/' + touchpointDto.getId()
		);
		ToastUtil.makeInfoToast('Copied touchpoint access url.');
	}, [touchpointDto]);
	return (
		<TableRow>
			<TableLinkCell
				onClick={goToTouchpointInfo}
				text={touchpointDto.getUser().getFull_name()}
			/>
			<TableDataCell text={touchpointDto.getUser().getEmail_id()} />
			<TableDataCell
				text={touchpointDto.getCreated_time().toDatetimeString()}
			/>
			<TableDataCell text={touchpointDto.getStatus().getMappedValue()} />
			<TableDataCell text={touchpointDto.getAudit_status().getMappedValue()} />
			<TableActionsCell>
				<TableCellActionQRCode onClick={downloadQR} />
				<TableCellActionLink onClick={goToTouchpoint} />
				<TableCellActionCopy onClick={copyTouchpointAccessUrl} />
				<TableCellActionInfo onClick={goToTouchpointInfo} />
			</TableActionsCell>
		</TableRow>
	);
}

export default function TouchpointsView() {
	const { portal_id } = useParams();
	const navigate = useRef(useNavigate());

	const { data, totalRecords, isLoading, loadMoreRecords, isLoadingNextPage } =
		useCRUDMapper<TouchpointDto>({
			mapper: JsonToTouchpointDtoMapper.map,
			url: `/portals/${portal_id}/touchpoints`
		});

	const { onScrollToBottom } = useOnScroll({
		cb: loadMoreRecords
	});

	const createTouchpoint = useCallback(() => {
		navigate.current('create');
	}, []);

	if (isLoading) {
		return (
			<ContainerCenter>
				<Loader />
			</ContainerCenter>
		);
	} else {
		return (
			<View>
				<ViewHeader>
					<ViewTitle text='Touchpoints' />
					<ViewActions>
						<Button
							variant='primary'
							label='Create Touchpoint'
							onClick={createTouchpoint}
						/>
					</ViewActions>
				</ViewHeader>
				<TableContainer onScroll={onScrollToBottom}>
					<Table>
						<TableHead>
							<TableRow>
								<TableHeadCell text='Username' width='20%' />
								<TableHeadCell text='Email Address' width='20%' />
								<TableHeadCell text='Created On' width='10%' />
								<TableHeadCell text='Status' width='10%' />
								<TableHeadCell text='Audit Status' width='15%' />
								<TableHeadCell text='Actions' width='10%' />
							</TableRow>
						</TableHead>
						<TableBody>
							{totalRecords ? (
								data.map((item) => (
									<TouchpointRow touchpointDto={item} key={item.getId()} />
								))
							) : (
								<TableRow>
									<TableDataCell colSpan={6} text='No entries in the table.' />
								</TableRow>
							)}
						</TableBody>
					</Table>
					{isLoadingNextPage && <LoaderLoadMoreRecords />}
				</TableContainer>
			</View>
		);
	}
}
