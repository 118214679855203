import React from 'react';
import ReactDOM from 'react-dom';

import './css/app.css';
import './css/link.css';
import './css/loader.css';
import './css/table.css';
import './css/modal.css';
import './css/title.css';
import './css/badge.css';
import './css/portals.css';
import './css/base.css';
import './css/main.css';
import './css/form.css';
import './css/toast.css';
import './css/view.css';
import './css/card.css';
import './css/button.css';
import './css/animation.css';
import './css/container.css';
import './css/template.css';
import './css/helper.css';
import './css/typography.css';
import './css/profile-template.css';

import App from './App';

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);
