import TouchpointStatus from './TouchpointStatus';
import TouchpointAuditStatus from './TouchpointAuditStatus';

export default class TouchpointDataModel {
	private id: string;
	private code: string;
	private template: string;
	private portal_id: string;
	private status: TouchpointStatus;
	private audit_status: TouchpointAuditStatus;

	constructor() {
		this.id = '';
		this.code = '';
		this.template = '';
		this.portal_id = '';
		this.status = TouchpointStatus.Inactive;
		this.audit_status = TouchpointAuditStatus.Pending;
	}

	public getId(): string {
		return this.id;
	}

	public setId(id: string): void {
		this.id = id;
	}

	public getCode(): string {
		return this.code;
	}

	public setCode(code: string): void {
		this.code = code;
	}

	public getTemplate(): string {
		return this.template;
	}

	public setTemplate(template: string): void {
		this.template = template;
	}

	public getPortal_id(): string {
		return this.portal_id;
	}

	public setPortal_id(portal_id: string): void {
		this.portal_id = portal_id;
	}

	public getStatus(): TouchpointStatus {
		return this.status;
	}

	public setStatus(status: TouchpointStatus): void {
		this.status = status;
	}

	public getAudit_status(): TouchpointAuditStatus {
		return this.audit_status;
	}

	public setAudit_status(audit_status: TouchpointAuditStatus): void {
		this.audit_status = audit_status;
	}

	public getInsertPayload(): Record<string, string> {
		return {
			template: this.template
		};
	}

	public getStatusUpdatePayload(): Record<string, string> {
		return {
			code: this.code,
			status: this.status.getValue()
		};
	}

	public getAuditStatusUpdatePayload(): Record<string, string> {
		return {
			audit_status: this.audit_status.getValue()
		};
	}
}
