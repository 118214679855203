import classNames from 'classnames';

import { View as ViewProps } from '../types/components';

const ViewProfilePicker: React.FunctionComponent<ViewProps> = ({
	onScroll,
	children,
	className
}) => {
	return (
		<div
			onScroll={onScroll}
			className={classNames('view view--profile-picker', className)}>
			{children}
		</div>
	);
};

export default ViewProfilePicker;
