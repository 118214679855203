import { CommonService } from '../services';
import { ApiResponseJson } from '../types/json';

const CommonUtil = Object.freeze({
	isJSON: (data: string): boolean => {
		try {
			JSON.parse(data);
		} catch (e) {
			console.log(data, e);
			return false;
		}
		return true;
	},

	isNumber: (data: string): boolean => {
		return new RegExp(/^\d+$/).test(data);
	},
	format: (
		value: string,
		...params: Array<string | number | boolean | Array<string>>
	): string => {
		for (let i = 0; i < params.length; i++) {
			const replacer = params[i];
			const placeholder = new RegExp('\\{' + i + '\\}', 'gi');
			value = value.replace(placeholder, replacer.toString());
		}

		return value;
	},
	wrapWithSingleQuotes: (value: string | number | boolean): string =>
		`'${value.toString()}'`,
	toTitleCase: (value: string): string => {
		return value
			.split(' ')
			.map((item) => item.charAt(0).toUpperCase() + item.slice(1))
			.join(' ');
	},
	getAllKeys: (data: Record<string, any>, prefix = ''): Array<string> => {
		return Object.keys(data).reduce<Array<string>>((result, key) => {
			if (Array.isArray(data[key])) {
				return result;
			} else if (typeof data[key] === 'object' && data[key] !== null) {
				return [
					...result,
					...CommonUtil.getAllKeys(data[key], prefix + key + '.')
				];
			}
			return [...result, prefix + key];
		}, []);
	},
	handleCreationFailure: (
		err: any,
		errorMap: ApiResponseJson.ServerErrorMapJson,
		setValue: (key: string, value: string) => void
	): void => {
		const message = CommonService.getInstance().processError(err);

		const field = errorMap[message];
		if (field) {
			setValue(field.name, field.message);
		} else {
		}
	},
	processError(err: any): string {
		return CommonService.getInstance().processError(err);
	}
});

export default CommonUtil;
