import { useMemo } from 'react';

import ProfileTemplateNode from './ProfileTemplateNode';
import UserInformationDto from '../dtos/UserInformationDto';
import UserInformationReplacer from '../replacers/UserInformationReplacer';
import ProfileTemplateNodeCategoryEnum from '../enums/ProfileTemplateNodeCategoryEnum';

export type ProfileTemplateImageNodeProps = {
	disabled?: boolean;
	userInformationPojo: UserInformationDto;
	profileTemplateNode: ProfileTemplateNode;
};

const ProfileTemplateImageNode: React.FC<ProfileTemplateImageNodeProps> = ({
	userInformationPojo,
	profileTemplateNode
}) => {
	const value = useMemo<string>(() => {
		if (
			profileTemplateNode.getCategory() ===
			ProfileTemplateNodeCategoryEnum.Dynamic
		) {
			return UserInformationReplacer.getInstance(userInformationPojo).replace(
				profileTemplateNode.getProperties().getValue() as string
			);
		} else {
			return profileTemplateNode.getProperties().getValue() as string;
		}
	}, [userInformationPojo, profileTemplateNode]);

	const style = useMemo<React.CSSProperties>(
		() => profileTemplateNode.getStyles().toJSON(),
		[profileTemplateNode]
	);

	return <img src={value} style={style} alt='UserInformation' />;
};

export default ProfileTemplateImageNode;
