import { useCallback, useState } from 'react';

import { UseModal } from '../types/hooks';

export default function useModal(): UseModal.Output {
	const [open, setOpen] = useState(false);

	const openModal = useCallback(() => {
		setOpen(true);
	}, []);

	const closeModal = useCallback(() => {
		setOpen(false);
	}, []);

	return {
		open,
		openModal,
		closeModal
	};
}
