import Datetime from '../datatypes/Datetime';

export default abstract class Dto {
	protected id: string;
	protected created_time: Datetime;

	constructor() {
		this.id = '';
		this.created_time = new Datetime();
	}

	public getId(): string {
		return this.id;
	}

	public setId(id: string): void {
		this.id = id;
	}

	public getCreated_time(): Datetime {
		return this.created_time;
	}

	public setCreated_time(created_time: Datetime): void {
		this.created_time = created_time;
	}

	public abstract loadFromJSON(result: Record<string, unknown>): void;
}
