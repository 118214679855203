import { useEffect } from 'react';

const LoginView: React.FunctionComponent = () => {
	useEffect(() => {
		window.location.href = '/__catalyst/auth/login';
	}, []);
	return null as any;
};

export default LoginView;
