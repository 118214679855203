import { MappedValue } from '../datatypes';

export default class UserInformationConstants {
	static readonly Status = {
		Active: new MappedValue('active', 'Active'),
		Inactive: new MappedValue('inactive', 'In-active')
	};

    static readonly getStatusByValue = (value:string):MappedValue=>{
        for (const status of Object.values(this.Status)) {
			if (status.getValue() === value) {
				return status;
			}
		}
		return this.Status.Inactive;
    }
}
