import { MappedValue } from '../datatypes';

export default class FormConstants {
	static readonly Types = {
		Card: new MappedValue('card', 'Card'),
		Profile: new MappedValue('profile', 'Card')
	};
	
	static readonly StorageType = {
		External: new MappedValue('external-app', 'External App'),
		Application: new MappedValue('application', 'Application'),
		ApplicationWithThirdParty: new MappedValue(
			'application and external-app',
			'Application & External App'
		)
	};

	static readonly getTypeByValue = (value: string): MappedValue => {
		for (const type of Object.values(this.Types)) {
			if (type.getValue() === value) {
				return type;
			}
		}
		return this.Types.Profile;
	};

	static readonly getStorageTypeByValue = (
		value: string
	): MappedValue => {
		for (const type of Object.values(this.StorageType)) {
			if (type.getValue() === value) {
				return type;
			}
		}
		return this.StorageType.Application;
	};

    static readonly getAllValueOfStorageType = (): Array<string> => {
		return Object.values(this.StorageType).map((item) => item.getValue());
	};

	static readonly getAllStorageType = (): Array<MappedValue> => {
		return Object.values(this.StorageType);
	};


}
