import TouchpointDto from './TouchpointDto';
import TouchpointDataModel from './TouchpointDataModel';
import RequestHandler from '../handlers/RequestHandler';
import RequestMethodEnum from '../enums/RequestMethodEnum';
import TouchpointTemplateNodes from './TouchpointTemplateNodes';
import TouchpointTemplateNode from './TouchpointTemplateNode';
import TouchpointTemplateNodeType from './TouchpointTemplateNodeType';

namespace TouchpointUtilParams {
	type CommonParams = {
		controller?: AbortController;
	};

	export type GetTouchpointWithPortal = {
		portal_id: string;
		touchpoint_id: string;
	} & CommonParams;

	export type GetTouchpointNodesWithPortal = {
		portal_id: string;
		touchpoint_id: string;
	} & CommonParams;

	export type CreateTouchpointWithPortal = {
		portal_id: string;
		touchpointDataModel: TouchpointDataModel;
	} & CommonParams;

	export type UpdateTouchpointNodesWithPortal = {
		portal_id: string;
		touchpoint_id: string;
		touchpointNodes: TouchpointTemplateNodes;
	} & CommonParams;

	export type UpdateTouchpointAuditStatusWithPortal = {
		touchpointDataModel: TouchpointDataModel;
	} & CommonParams;

	export type UpdateTouchpointStatusWithPortal =
		UpdateTouchpointAuditStatusWithPortal;

	export type GenerateCodeWithPortal = {
		portal_id: string;
		touchpoint_id: string;
	} & CommonParams;
}

export default class TouchpointUtil {
	public static jsonToTouchpointDtoMapper(
		json: Record<string, unknown>
	): TouchpointDto {
		const touchpointDto = new TouchpointDto();
		touchpointDto.loadFromJSON(json);
		return touchpointDto;
	}

	public static jsonToTouchpointNodesMapper(
		json: Record<string, Array<Record<string, unknown>>>
	): TouchpointTemplateNodes {
		const touchpointTemplateNodes = new TouchpointTemplateNodes();
		touchpointTemplateNodes.loadFromJSON(json);
		return touchpointTemplateNodes;
	}

	public static filterTouchpointNodeByType(
		nodes: Array<TouchpointTemplateNode>,
		types: Array<TouchpointTemplateNodeType>
	): Array<TouchpointTemplateNode> {
		const result: Array<TouchpointTemplateNode> = [];

		for (const node of nodes) {
			const isValidNode = types.some(
				(type) => node.getType().getValue() === type.getValue()
			);

			if (isValidNode) {
				result.push(node);
			}

			if (node.getNodes().length) {
				result.push(...this.filterTouchpointNodeByType(node.getNodes(), types));
			}
		}

		return result;
	}

	public static findTouchpointNodeById(
		nodes: Array<TouchpointTemplateNode>,
		touchpoint_node_id: string
	): TouchpointTemplateNode | null {
		for (const node of nodes) {
			if (node.getId() === touchpoint_node_id) {
				return node;
			}

			if (node.getNodes().length) {
				this.findTouchpointNodeById(node.getNodes(), touchpoint_node_id);
			}
		}

		return null;
	}

	public static async getTouchpointWithPortal(
		params: TouchpointUtilParams.GetTouchpointWithPortal
	): Promise<TouchpointDto> {
		const { portal_id, controller, touchpoint_id } = params;
		const requestHandler = new RequestHandler();

		if (controller) {
			requestHandler.setAbortController(controller);
		}

		requestHandler.setMethod(RequestMethodEnum.Get);
		requestHandler.setUrl(
			`/api/portals/${portal_id}/touchpoints/${touchpoint_id}`
		);

		return requestHandler
			.execute()
			.then((response) =>
				this.jsonToTouchpointDtoMapper(
					(response.getData() as Record<string, Record<string, unknown>>).data
				)
			);
	}

	public static async getTouchpointByMappedValue(
		value: string
	): Promise<TouchpointDto> {
		const requestHandler = new RequestHandler();

		requestHandler.setMethod(RequestMethodEnum.Get);
		requestHandler.setUrl(`/api/touchpoints/${value}`);

		return requestHandler
			.execute()
			.then((response) =>
				this.jsonToTouchpointDtoMapper(
					(response.getData() as Record<string, Record<string, unknown>>).data
				)
			);
	}

	public static async getTouchpointNodesWithPortal(
		params: TouchpointUtilParams.GetTouchpointNodesWithPortal
	): Promise<TouchpointTemplateNodes> {
		const { portal_id, controller, touchpoint_id } = params;
		const requestHandler = new RequestHandler();

		if (controller) {
			requestHandler.setAbortController(controller);
		}

		requestHandler.setMethod(RequestMethodEnum.Get);
		requestHandler.setUrl(
			`/api/portals/${portal_id}/touchpoints/${touchpoint_id}/nodes`
		);

		return requestHandler
			.execute()
			.then((response) =>
				this.jsonToTouchpointNodesMapper(
					(
						response.getData() as Record<
							string,
							Record<string, Array<Record<string, unknown>>>
						>
					).data
				)
			);
	}

	public static async generateCodeWithPortal(
		params: TouchpointUtilParams.GenerateCodeWithPortal
	): Promise<string> {
		const { portal_id, controller, touchpoint_id } = params;
		const requestHandler = new RequestHandler();

		if (controller) {
			requestHandler.setAbortController(controller);
		}

		requestHandler.setMethod(RequestMethodEnum.Get);
		requestHandler.setUrl(
			`/api/portals/${portal_id}/touchpoints/${touchpoint_id}/code`
		);

		return requestHandler
			.execute()
			.then(
				(response) => (response.getData() as Record<string, string>).message
			);
	}

	public static async createTouchpointWithPortal(
		params: TouchpointUtilParams.CreateTouchpointWithPortal
	): Promise<TouchpointDto> {
		const { portal_id, controller, touchpointDataModel } = params;
		const requestHandler = new RequestHandler();

		if (controller) {
			requestHandler.setAbortController(controller);
		}

		requestHandler.setMethod(RequestMethodEnum.Post);
		requestHandler.setUrl(`/api/portals/${portal_id}/touchpoints`);
		requestHandler.setBody(touchpointDataModel.getInsertPayload());

		return requestHandler
			.execute()
			.then((response) =>
				this.jsonToTouchpointDtoMapper(
					(response.getData() as Record<string, Record<string, unknown>>).data
				)
			);
	}

	public static updateTouchpointNode(
		nodes: Array<TouchpointTemplateNode>,
		touchpointNode: TouchpointTemplateNode
	): Array<TouchpointTemplateNode> {
		const result: Array<TouchpointTemplateNode> = [];

		for (const node of nodes) {
			if (node.getId() === touchpointNode.getId()) {
				result.push(touchpointNode);
			} else {
				result.push(node);
			}

			if (node.getNodes().length) {
				node.setNodes(
					this.updateTouchpointNode(node.getNodes(), touchpointNode)
				);
			}
		}
		return result;
	}

	public static async updateTouchpointAuditStatusWithPortal(
		params: TouchpointUtilParams.UpdateTouchpointAuditStatusWithPortal
	): Promise<TouchpointDto> {
		const { controller, touchpointDataModel } = params;
		const requestHandler = new RequestHandler();

		if (controller) {
			requestHandler.setAbortController(controller);
		}

		requestHandler.setMethod(RequestMethodEnum.Put);
		requestHandler.setUrl(
			`/api/portals/${touchpointDataModel.getPortal_id()}/touchpoints/${touchpointDataModel.getId()}/audit-status`
		);
		requestHandler.setBody(touchpointDataModel.getAuditStatusUpdatePayload());

		return requestHandler
			.execute()
			.then((response) =>
				this.jsonToTouchpointDtoMapper(
					(response.getData() as Record<string, Record<string, unknown>>).data
				)
			);
	}

	public static async updateTouchpointStatusWithPortal(
		params: TouchpointUtilParams.UpdateTouchpointAuditStatusWithPortal
	): Promise<TouchpointDto> {
		const { controller, touchpointDataModel } = params;
		const requestHandler = new RequestHandler();

		if (controller) {
			requestHandler.setAbortController(controller);
		}

		requestHandler.setMethod(RequestMethodEnum.Put);
		requestHandler.setUrl(
			`/api/portals/${touchpointDataModel.getPortal_id()}/touchpoints/${touchpointDataModel.getId()}/status`
		);
		requestHandler.setBody(touchpointDataModel.getStatusUpdatePayload());

		return requestHandler
			.execute()
			.then((response) =>
				this.jsonToTouchpointDtoMapper(
					(response.getData() as Record<string, Record<string, unknown>>).data
				)
			);
	}

	public static async updateTouchpointNodesWithPortal(
		params: TouchpointUtilParams.UpdateTouchpointNodesWithPortal
	): Promise<TouchpointTemplateNodes> {
		const { portal_id, controller, touchpoint_id, touchpointNodes } = params;
		const requestHandler = new RequestHandler();

		if (controller) {
			requestHandler.setAbortController(controller);
		}

		requestHandler.setMethod(RequestMethodEnum.Put);
		requestHandler.setUrl(
			`/api/portals/${portal_id}/touchpoints/${touchpoint_id}/nodes`
		);
		requestHandler.setBody(touchpointNodes.toJSON());

		return requestHandler
			.execute()
			.then((response) =>
				this.jsonToTouchpointNodesMapper(
					(
						response.getData() as Record<
							string,
							Record<string, Array<Record<string, unknown>>>
						>
					).data
				)
			);
	}
}
