import classNames from 'classnames';

import { Modal as ModalProps } from '../types/components';

const Modal: React.FunctionComponent<ModalProps> = ({
	show,
	position,
	children
}) => {
	if (!show) {
		return null;
	} else {
		return (
			<>
				<div className='modal__backdrop'></div>
				<div className={classNames('modal', 'modal--' + position)}>
					<div
						className={classNames(
							'modal__container',
							'modal__container--' + position
						)}>
						{children}
					</div>
				</div>
			</>
		);
	}
};

export default Modal;
