import ProfileTemplateNodeTypeEnum from '../enums/ProfileTemplateNodeTypeEnum';
import ProfileTemplateNodeStyles from './ProfileTemplateNodeStyles';
import ProfileTemplateNodeCategoryEnum from '../enums/ProfileTemplateNodeCategoryEnum';
import ProfileTemplateNodeProperties from './ProfileTemplateNodeProperties';


export default class ProfileTemplateNode {
	private dummy_id: string;
	private sequence: number;
	private nodes: Array<ProfileTemplateNode>;

	private styles: ProfileTemplateNodeStyles;
	private properties: ProfileTemplateNodeProperties;

	private type: ProfileTemplateNodeTypeEnum;
	private category: ProfileTemplateNodeCategoryEnum;

	constructor() {
		this.nodes = [];
		this.sequence = 0;
		this.dummy_id = '';

		this.styles = new ProfileTemplateNodeStyles();
		this.properties = new ProfileTemplateNodeProperties();

		this.type = ProfileTemplateNodeTypeEnum.Text;
		this.category = ProfileTemplateNodeCategoryEnum.Static;
	}

	public getDummy_id(): string {
		return this.dummy_id;
	}

	public setDummy_id(dummy_id: string): void {
		this.dummy_id = dummy_id;
	}

	public getSequence(): number {
		return this.sequence;
	}

	public setSequence(sequence: number): void {
		this.sequence = sequence;
	}

	public getNodes(): Array<ProfileTemplateNode> {
		return this.nodes;
	}

	public setNodes(nodes: Array<ProfileTemplateNode>): void {
		this.nodes = nodes;
	}

	public getStyles(): ProfileTemplateNodeStyles {
		return this.styles;
	}

	public setStyles(styles: ProfileTemplateNodeStyles): void {
		this.styles = styles;
	}

	public getType(): ProfileTemplateNodeTypeEnum {
		return this.type;
	}

	public setType(type: ProfileTemplateNodeTypeEnum): void {
		this.type = type;
	}

	public getProperties(): ProfileTemplateNodeProperties {
		return this.properties;
	}

	public setProperties(properties: ProfileTemplateNodeProperties): void {
		this.properties = properties;
	}

	public getCategory(): ProfileTemplateNodeCategoryEnum {
		return this.category;
	}

	public setCategory(category: ProfileTemplateNodeCategoryEnum): void {
		this.category = category;
	}

	public loadFromJson(json: Record<string, unknown>): void {
		this.sequence = parseInt(json.sequence as string);

		this.styles.loadFromJson(json.styles as Record<string, unknown>);
		this.properties.loadFromJson(json.properties as Record<string, unknown>);

		this.type = ProfileTemplateNodeTypeEnum.getValue(json.type as string);
		this.category = ProfileTemplateNodeCategoryEnum.getValue(
			json.category as string
		);
		
	}
}
