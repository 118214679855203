import { Icon as IconProps } from '../types/components';

const CloseIcon: React.FC<IconProps> = ({
	widith,
	height,
	onClick,
	className
}) => {
	return (
		<svg
			fill='none'
			width={widith}
			height={height}
			onClick={onClick}
			strokeWidth={1.5}
			viewBox='0 0 24 24'
			stroke='currentColor'
			className={className}
			xmlns='http://www.w3.org/2000/svg'>
			<path
				strokeLinecap='round'
				strokeLinejoin='round'
				d='M6 18L18 6M6 6l12 12'
			/>
		</svg>
	);
};

export default CloseIcon;
